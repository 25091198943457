import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MaterialModule } from './material.module';
//import { HomePage } from './home/home.page';
import { PreventNavigationService } from './service/prevent-navigation.service';

const routes: Routes = [
  // {
  //   path: 'home',
  //   loadChildren: () => import('./page/find-a-store/find-a-store.module').then( m => m.FindAStorePageModule)

  //   // loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  // },
 

  {
    path: '',
     //redirectTo: 'side-panel-menu',
    redirectTo: 'ordering-system',
    pathMatch: 'full'
  },
  // {
  //   path: 'location',
  //   loadChildren: () => import('./page/location/location.module').then( m => m.LocationPageModule)
  // },

 
  // {
  //   path: 'first-page',
  //   loadChildren: () => import('./page/first-page/first-page.module').then( m => m.FirstPagePageModule)
  // },
  // {
  //   path: 'menu-page',
  //   loadChildren: () => import('./page/menu-page/menu-page.module').then( m => m.MenuPagePageModule)
  // },
  // {
  //   path: 'menu-shown',
  //   loadChildren: () => import('./page/menu-shown/menu-shown.module').then( m => m.MenuShownPageModule)
  // },
  // {
  //   path: 'find-a-store',
  //   loadChildren: () => import('./page/find-a-store/find-a-store.module').then( m => m.FindAStorePageModule)
  // },
  // {
  //   path: 'resturant-menu/:id',
  //   loadChildren: () => import('./page/resturant-menu/resturant-menu.module').then( m => m.ResturantMenuPageModule)
  // },
  // {
  //   path: 'gather-location',
  //   loadChildren: () => import('./page/gather-location/gather-location.module').then( m => m.GatherLocationPageModule)
  // },
    // {
    //   path: 'catering',
    //   loadChildren: () => import('./page/catering/catering.module').then( m => m.CateringPageModule)
    // },
 
  // {
  //   path: 'order',
  //   loadChildren: () => import('./page/order/order.module').then( m => m.OrderPageModule)
  // },
  {
    path: 'customise',
    loadChildren: () => import('./page/customise/customise.module').then( m => m.CustomisePageModule)
  },
  // {
  //   path: 'check-out',
  //   loadChildren: () => import('./page/check-out/check-out.module').then( m => m.CheckOutPageModule),
  //   canActivate : [PreventNavigationService]
  // },
  // {
  //   path: 'place',
  //   loadChildren: () => import('./page/place/place.module').then( m => m.PlacePageModule)
  // },
  {
    path: 'ordering-system',
    loadChildren: () => import('./page/side-panel-menu/side-panel-menu.module').then( m => m.SidePanelMenuPageModule)
  },
  // {
  //   path: 'get-items/:id',
  //   loadChildren: () => import('./page/get-items/get-items.module').then( m => m.GetItemsPageModule)
  // },
  {
    path: 'ordering-system/:id',
    loadChildren: () => import('./page/get-items/get-items.module').then( m => m.GetItemsPageModule)
  },
  {
    path: 'item-selected/:id',
    loadChildren: () => import('./page/item-selected/item-selected.module').then( m => m.ItemSelectedPageModule)
  },
  {
    path: 'gain-credentials/:id',
    loadChildren: () => import('./page/gain-credentials/gain-credentials.module').then( m => m.GainCredentialsPageModule)
  },
  // {
  //   path: 'starting',
  //   loadChildren: () => import('./page/starting/starting.module').then( m => m.StartingPageModule)
  // },
  {
    path: 'reset-password/:email/:token',
    loadChildren: () => import('./page/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
   {path: '404', 
  loadChildren: () => import('./page/not-found/not-found.module').then( m => m.NotFoundPageModule)
   },
  {path: '**', redirectTo: '/404'},
  {
    path: 'rewards',
    loadChildren: () => import('./page/rewards/rewards.module').then( m => m.RewardsPageModule)
  }






];

@NgModule({

  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
