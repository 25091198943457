import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  hide = true;
  constructor(
    public dialogRef: MatDialogRef<RegisterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
signUpPageCalled(){
  this.dialogRef.close('Sign up');

}
forgotPassword(){
  this.dialogRef.close('forgot_password');
}
loginPageCalled(){
  this.dialogRef.close('login');
}
  ngOnInit() {}
  ngAfterViewInit(){
  }

}
