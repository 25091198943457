import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { UtilityService } from 'src/app/service/utility.service';
import {
  MenuController,
  NavParams,
  NavController,
  PopoverController,
  ModalController,
} from '@ionic/angular';
import { SubjectCollectionService } from 'src/app/service/subject-collection.service';
//import { OptionSelectedComponent } from '../option-selected/option-selected.component';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { RegistrationFormComponent } from '../registration-form/registration-form.component';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit {
  @Input() data: any;
  @Input() items:any;
  public user_info: any;
  @Input() current_resturant: any;
  public header_button_clicked: any;
  public thanks_giving_form_display:any;
  constructor(
    public auth: AuthService,
    public util: UtilityService,
    public menu: MenuController,
    public subject_service: SubjectCollectionService,
    public navCtrl: NavController,
    public popoverController: PopoverController,
    public modalController: ModalController,
    public popoverCtrl: PopoverController,
    public router: Router,
    public route:ActivatedRoute
  ) {
    route.params.subscribe(val => {
    });
  }
  ngOnInit() {
    const vm = this;
    this.user_info = this.data;
  }
  ngAfterViewInit() {
  }
  formToOpen(form_name) {
    this.header_button_clicked = form_name;
    this.thanks_giving_form_display=false
    const vm = this;
    vm.util.dismissPopover();
    const navigationExtras: NavigationExtras = {
      state: {
        form_name: form_name,
      },
    };
    if(location.href.includes('/gain-credentials/'))
    { 
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;}
      this.router.onSameUrlNavigation = 'reload';
      this.util.presentLoading();
    }
    this.router.navigate([`/gain-credentials/${this.current_resturant}`], {

      ...navigationExtras,
    });
  }
  async logout() {
    const vm = this;
   await vm.util.presentLoading();
    await this.auth.logOut().subscribe((data) => {
      for (var key in localStorage) {
        if(key!="resturant_timings" && key!="current_resturant_data" && key!="SUN_resturant_timings")
        {
         localStorage.removeItem(key);
        }
     }
      
      // localStorage.clear();
      this.user_info = 'null';
      vm.util.dismissPopover();
      vm.util.dismissLoader();
        (error) => {
      vm.util.dismissLoader();
        };
    });
  }
  openCart() {
    const vm = this;
    vm.util.dismissPopover();
    this.menu.open('cart');
  }
  // async presentModalWIthSelectedOptions(option_name) {
  //   this.popoverCtrl.dismiss();
  //   const modal = await this.modalController.create({
  //     component: OptionSelectedComponent,
  //     cssClass: 'my-custom-class',
  //     swipeToClose: true,
  //     componentProps: { option_selected: option_name, user_info: this.data },
  //   });
  //   return await modal.present();
  // }
}
