import { Component, OnInit, Input, ViewChild } from "@angular/core";
import {
  FormControl,
  FormBuilder,
  Validators,
  FormGroup,
  FormArray,
} from "@angular/forms";
import { ModalController, AlertController, IonContent, NavController } from "@ionic/angular";
import { ConfigService } from "src/app/service/config.service";
import { RegistrationFormComponent } from "src/app/component/registration-form/registration-form.component";
import { ResturantApiService } from "src/app/service/resturant-api.service";
import { SubjectCollectionService } from "src/app/service/subject-collection.service";
import { async } from "@angular/core/testing";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UtilityService } from "src/app/service/utility.service";
import { MatListOption, MatSelectionList } from "@angular/material/list";
import { NavigationExtras, Router } from "@angular/router";
import { MatSidenav } from "@angular/material/sidenav";

@Component({
  selector: "app-customise",
  templateUrl: "./customise.page.html",
  styleUrls: ["./customise.page.scss"],
})
export class CustomisePage implements OnInit {
  // tslint:disable-next-line: variable-name
  @Input() item_selected: any;
  @Input() edited_values?: any;
  @Input() complete_menu: any;
  public show_order_total: any;
  public all_categories_available: any;
  public title: any;
  public image_src: any;
  @ViewChild("drawer", { static: false }) drawerMenu: MatSidenav;
  @ViewChild(MatSelectionList) selectionList: MatSelectionList;
  public last_button: any;
  @Input() all_categories: any = [];
  @Input() menu_items: any = [];
  @Input() index?: any;
  public new_slider: any = 3;
  public loader_present:any;
  public image_url_for_smaller: any;
  public image_url_for_larger: any;
  public incomingSelectedAlert: any;
  public customise_option_variation: any = [];
  public warning_message: any;
  public isLoading:any;
  public is_loaded:any;
  @Input() current_resturant?: any;
  public collect_values: any = [];
  public money: any;
  public calculate_warning: any;
  public currency: any;
  public option_selcted: any;
  public variations_data: any = [];
  public option_value: any;
  public item_selected_out_of_category: any;
  public collect_customisation_ids: any = [];
  public again_test_customisation: any = [];
  public py: any = [];
  public addToCartButton: boolean = false;
  public skip: boolean = false;
  @ViewChild(IonContent) content: IonContent;
  public get_consumisation_from_api: any = [];
  public variation_id: any;
  public check_box_options_selected: any = [];
  public selected_values_obtained: any = [];
  public selected_complete_customisation: any = [];
  // tslint:disable-next-line: variable-name
  // tslint:disable-next-line: variable-name
  public customisation_options: any = [];
  // tslint:disable-next-line: variable-name
  public other_options: any = [];
  public availableColors: any = [];
  // tslint:disable-next-line: variable-name
  public slider_value: any;
  public todo: FormGroup;

  public customisation_options_selection: any;
  public skills = new FormArray([]);
  public user: any = new FormGroup({
    skills: new FormArray([]),
  });
  public customisation_options_of_selected: any = [];

  public questions: any = [];
  public angForm = new FormGroup({
    names1: new FormArray([new FormControl("", Validators.required)]),
  });
  get names1(): FormArray {
    return this.angForm.get("names1") as FormArray;
  }
  // public updateForm:FormGroup;
  // public value:any=[];
  // toppings = new FormControl();

  // toppingList: any = [];
  public selected: any;
  get names(): FormArray {
    return this.user.get("skills") as FormArray;
  }
  selectVariation(ev) {
    const vm = this;
    for (let i = 0; i < this.variations_data.length; i++) {
      if (vm.variations_data[i].name == ev.value) {
        vm.money = vm.variations_data[i].amount;
        vm.currency = vm.variations_data[i].currency;
        vm.variation_id = vm.variations_data[i].id;
      }
    }
  }
  ngOnChanges() {}
  onFormSubmit1(): void {
    for (let i = 0; i < this.names1.length; i++) {
    }
  }
  addNameField() {
    this.names1.push(new FormControl("", Validators.required));
  }

  deleteNameField(index: number) {
    if (this.names1.length !== 1) {
      this.names1.removeAt(index);
    }
  }
  constructor(
    public modal_controller: ModalController,
    private formBuilder: FormBuilder,
    public config: ConfigService,
    public alertController: AlertController,
    public resturant_service: ResturantApiService,
    public subject_service: SubjectCollectionService,
    public _snackBar: MatSnackBar,
    public util: UtilityService,
    public navCtrl: NavController,
    public router: Router,
    
  ) {
    var vm=this;
    this.isLoading=false;
    this.is_loaded=false;
    vm.navCtrl.setDirection('forward');
    this.item_selected_out_of_category = false;
    this.todo = this.formBuilder.group({
      title: ["", Validators.required],
      description_1: [""],
      description_2: [""],
      description_3: [""],
    });
    const val = {};
    this.questions.map((e) => (val[e.key] = e.value));
    this.collect_values[0] = { quantity: this.slider_value };
    this.collect_values[1] = { title: "main_customisation", ...val };
  }
  addCheck(){
    var vm=this;
    let ids: any = [];
    for (let y = 0; y < this.collect_customisation_ids.length; y++) {
      this.collect_customisation_ids[y].map((e, index) => {
        if (e != null && e != "" && e != undefined && e) {
          var b = vm.customise_option_variation.findIndex((f) => {
            return f.id_value == e;
          });
          if (b > -1) {
            ids.push({
              ItemCustomizationOptionId: e,
              CustomizationOptionPrice:
                vm.customise_option_variation[b].variation[1],
              CustomizationOptionVariationName:
                vm.customise_option_variation[b].variation[0],
            });
          } else {
            ids.push({
              ItemCustomizationOptionId: e,
            });
          }
        }
      });
    }
    this.modal_controller.dismiss({customisaion_collected:[...ids],cancel:'no'})
    
  }

  async addWithoutUser() {
    debugger
    let vm = this;
    await vm.collectnewData();
    await vm.util.presentLoading();
    if (this.variation_id) {
      var tax: any = [];
      var tax_id: any = [];
      if (vm.item_selected.other_info.itemTax.length > 0) {
        tax = [];
        tax_id = [];
        for (var i = 0; i < vm.item_selected.other_info.itemTax.length; i++) {
          tax.push({
            OrderDetailTaxId: vm.item_selected.other_info.itemTax[i].id,
          });
          tax_id.push({ TaxId: vm.item_selected.other_info.itemTax[i].id });
        }
      } else {
        tax = "";
        tax_id: "";
      }
      let order_id = localStorage.getItem("order_id");

      this.subject_service.getWaitingForCart(true);
      this.subject_service.getWaitingForSubtotal(true);
      if (this.current_resturant) {
        if (localStorage.getItem("current_resturant")) {

          if (
        
            this.current_resturant == localStorage.getItem("current_resturant")
          ) {
            let ids: any = [];
            for (let y = 0; y < this.collect_customisation_ids.length; y++) {
              this.collect_customisation_ids[y].map((e, index) => {
                if (e != null && e != "" && e != undefined && e) {
                  var b = vm.customise_option_variation.findIndex((f) => {
                    return f.id_value == e;
                  });
                  if (b > -1) {
                    ids.push({
                      ItemCustomizationOptionId: e,
                      CustomizationOptionPrice:
                        vm.customise_option_variation[b].variation[1],
                      CustomizationOptionVariationName:
                        vm.customise_option_variation[b].variation[0],
                    });
                  } else {
                    ids.push({
                      ItemCustomizationOptionId: e,
                    });
                  }
                }
              });
            }
            let item_add_to_cart: any;
            if (this.current_resturant) {
              item_add_to_cart = {
                OrderId: order_id ? order_id : "",
                LocationId: this.current_resturant,
                OrderDetails: [
                  {
                    VariationId: this.variation_id,
                    Quantity: JSON.stringify(this.slider_value),
                    OrderDetailTaxes: [...tax],
                    OrderDetailCustomizations: [...ids],
                  },
                ],
                Taxes: [...tax_id],
              };
              var p = await vm.clean(item_add_to_cart);
              p.OrderDetails = p.OrderDetails.map((e) => {
                return vm.clean(e);
              });
              if (p.Taxes) {
                p.Taxes.TaxId = await vm.removeByAttr(p.Taxes, "TaxId", "");
              }
              this.resturant_service.createOrderWithoutUserInfo(p).subscribe(
                (data: any) => {
                  vm.util.dismissLoader();
                  vm.afterGettingResponseFromOrderApiWithoutUser(data);
                },
                (error) => {
                  vm.util.dismissLoader();
                  vm._snackBar.open(error, "Done", {
                    duration: 2000,
                  });
                  vm.subject_service.getWaitingForCart(false);
                  vm.subject_service.getWaitingForSubtotal(false);
                }
              );
            } else {
              await vm.util.presentLoading();
              vm.presentAlertLocation();
            }
            this.collect_values[0] = {
              quantity: this.slider_value,
              currency: this.currency,
              price: this.money,
              vegetarian: this.item_selected.vegetarian,
              image_title: this.item_selected.image_title,
              name: this.item_selected.name,
              image: this.item_selected.image,
              description: this.item_selected.description,
            };
            this.modal_controller.dismiss({
              collect_values: this.collect_values,
              index: this.index,
            });
          } else {
            if (localStorage.getItem("cart_response")) {
              let get_local_storage_items = JSON.parse(
                localStorage.getItem("cart_response")
              );
              let item_id: any = [];
              get_local_storage_items.map((e) => {
                let val = "line_items[" + e.uid + "]";
                item_id.push(val);
              });
              let data = {
                OrderId: order_id,
                LocationId: localStorage.getItem("current_resturant"),
                FieldsToClear: [...item_id],
              };
              vm.resturant_service.createOrderWithoutUserInfo(data).subscribe(
                async (data) => {
                  if (vm.item_selected.other_info.itemTax.length > 0) {
                    tax = [];
                    tax_id = [];
                    for (
                      var i = 0;
                      i < vm.item_selected.other_info.itemTax.length;
                      i++
                    ) {
                      tax.push({
                        OrderDetailTaxId:
                          vm.item_selected.other_info.itemTax[i].id,
                      });
                      tax_id.push({
                        TaxId: vm.item_selected.other_info.itemTax[i].id,
                      });
                    }
                  } else {
                    tax = "";
                    tax_id: "";
                  }
                  await vm.afterGettingResponseFromOrderApiWithoutUser(data);
                  localStorage.setItem(
                    "current_resturant",
                    this.current_resturant
                  );
                  localStorage.setItem(
                    "selected_resturant_data",
                    localStorage.getItem("current_resturant_data")
                  );
                  localStorage.setItem(
                    "selected_resturant_timings",
                    localStorage.getItem("current_resturant_timings")
                  );
                  localStorage.setItem(
                    "deliver_charges",
                    JSON.parse(localStorage.getItem("selected_resturant_data"))[
                      "deliveryFee"
                    ] != null
                      ? JSON.parse(
                          localStorage.getItem("selected_resturant_data")
                        )["deliveryFee"]
                      : 0
                  );

                  localStorage.removeItem("order_id");
                  if (localStorage.getItem("fulfilment")) {
                    localStorage.removeItem("fulfilment");
                    vm.subject_service.getFulfilment(false);
                  }
                  order_id = localStorage.getItem("order_id");
                  vm.current_resturant = localStorage.getItem(
                    "current_resturant"
                  );
                  let ids: any = [];
                  for (
                    let y = 0;
                    y < this.collect_customisation_ids.length;
                    y++
                  ) {
                    this.collect_customisation_ids[y].map((e, index) => {
                      if (e != null && e != "" && e != undefined && e) {
                        var b = vm.customise_option_variation.findIndex((f) => {
                          return f.id_value == e;
                        });
                        if (b > -1) {
                          ids.push({
                            ItemCustomizationOptionId: e,
                            CustomizationOptionPrice:
                              vm.customise_option_variation[b].variation[1],
                            CustomizationOptionVariationName:
                              vm.customise_option_variation[b].variation[0],
                          });
                        } else {
                          ids.push({
                            ItemCustomizationOptionId: e,
                          });
                        }
                
                      }
                    });
                  }
                  let item_add_to_cart: any;

                  if (this.current_resturant) {
                    item_add_to_cart = {
                      OrderId: order_id,
                      LocationId: this.current_resturant,
                      OrderDetails: [
                        {
                          VariationId: this.variation_id,
                          Quantity: JSON.stringify(this.slider_value),
                          OrderDetailTaxes: [...tax],
                          OrderDetailCustomizations: [...ids],
                        },
                      ],
                      Taxes: [...tax_id],
                    };
                    var p = await vm.clean(item_add_to_cart);
                    p.OrderDetails = p.OrderDetails.map((e) => {
                      return vm.clean(e);
                    });
                    if (p.Taxes) {
                      p.Taxes.TaxId = await vm.removeByAttr(
                        p.Taxes,
                        "TaxId",
                        ""
                      );
                    }
                    this.resturant_service
                      .createOrderWithoutUserInfo(p)
                      .subscribe(
                        (data: any) => {
                          vm.util.dismissLoader();
                          vm.afterGettingResponseFromOrderApiWithoutUser(data);
                        },
                        (error) => {
                          vm.util.dismissLoader();
                          vm._snackBar.open(error, "Done", {
                            duration: 2000,
                          });
                          vm.subject_service.getWaitingForCart(false);
                          vm.subject_service.getWaitingForSubtotal(false);
                        }
                      );
                  } else {
                    vm.presentAlertLocation();
                  }

                  this.collect_values[0] = {
                    quantity: this.slider_value,
                    currency: this.currency,
                    price: this.money,
                    vegetarian: this.item_selected.vegetarian,
                    image_title: this.item_selected.image_title,
                    name: this.item_selected.name,
                    image: this.item_selected.image,
                    description: this.item_selected.description,
                  };
                  this.modal_controller.dismiss({
                    collect_values: this.collect_values,
                    index: this.index,
                  });
                },
                (error) => {
                  vm.util.dismissLoader();
                  vm._snackBar.open(error, "Done", {
                    duration: 2000,
                  });
                  vm.subject_service.getWaitingForCart(false);
                  vm.subject_service.getWaitingForSubtotal(false);
                }
              );
            } else {
              localStorage.setItem("current_resturant", this.current_resturant);
              localStorage.setItem(
                "selected_resturant_data",
                localStorage.getItem("current_resturant_data")
              );
              localStorage.setItem(
                "selected_resturant_timings",
                localStorage.getItem("current_resturant_timings")
              );

              localStorage.setItem(
                "deliver_charges",
                JSON.parse(localStorage.getItem("selected_resturant_data"))[
                  "deliveryFee"
                ] != null
                  ? JSON.parse(localStorage.getItem("selected_resturant_data"))[
                      "deliveryFee"
                    ]
                  : 0
              );
              let ids: any = [];
              for (let y = 0; y < this.collect_customisation_ids.length; y++) {
                this.collect_customisation_ids[y].map((e, index) => {
                  if (e != null && e != "" && e != undefined && e) {
                    var b = vm.customise_option_variation.findIndex((f) => {
                      return f.id_value == e;
                    });
                    if (b > -1) {
                      ids.push({
                        ItemCustomizationOptionId: e,
                        CustomizationOptionPrice:
                          vm.customise_option_variation[b].variation[1],
                        CustomizationOptionVariationName:
                          vm.customise_option_variation[b].variation[0],
                      });
                    } else {
                      ids.push({
                        ItemCustomizationOptionId: e,
                      });
                    }
                
                  }
                });
              }
              let item_add_to_cart: any;
              if (this.current_resturant) {
                var tax: any = [];
                var tax_id: any = [];
                if (vm.item_selected.other_info.itemTax.length > 0) {
                  for (
                    var i = 0;
                    i < vm.item_selected.other_info.itemTax.length;
                    i++
                  ) {
                    tax.push({
                      OrderDetailTaxId:
                        vm.item_selected.other_info.itemTax[i].id,
                    });
                    tax_id.push({
                      TaxId: vm.item_selected.other_info.itemTax[i].id,
                    });
                  }
                } else {
                  tax = "";
                  tax_id = "";
                }
                item_add_to_cart = {
                  OrderId: order_id ? order_id : "",
                  LocationId: this.current_resturant,
                  OrderDetails: [
                    {
                      VariationId: this.variation_id,
                      Quantity: JSON.stringify(this.slider_value),
                      OrderDetailTaxes: tax,
                      OrderDetailCustomizations: [...ids],
                    },
                  ],
                  Taxes: tax_id,
                };
                var p = await vm.clean(item_add_to_cart);
                p.OrderDetails = p.OrderDetails.map((e) => {
                  return vm.clean(e);
                });
                if (p.Taxes) {
                  p.Taxes.TaxId = await vm.removeByAttr(p.Taxes, "TaxId", "");
                }
                this.resturant_service.createOrderWithoutUserInfo(p).subscribe(
                  (data: any) => {
                    vm.util.dismissLoader();
                    vm.afterGettingResponseFromOrderApiWithoutUser(data);
                  },
                  (error) => {
                    vm.util.dismissLoader();
                    vm._snackBar.open(error, "Done", {
                      duration: 2000,
                    });
                    vm.subject_service.getWaitingForCart(false);
                    vm.subject_service.getWaitingForSubtotal(false);
                  }
                );
              } else {
                this.presentAlertLocation();
                vm.util.dismissLoader();
              }

              this.collect_values[0] = {
                quantity: this.slider_value,
                currency: this.currency,
                price: this.money,
                vegetarian: this.item_selected.vegetarian,
                image_title: this.item_selected.image_title,
                name: this.item_selected.name,
                image: this.item_selected.image,
                description: this.item_selected.description,
              };
              this.modal_controller.dismiss({
                collect_values: this.collect_values,
                index: this.index,
              });
            }
          }
        } else {
          localStorage.setItem("current_resturant", this.current_resturant);
          localStorage.setItem(
            "selected_resturant_data",
            localStorage.getItem("current_resturant_data")
          );
          localStorage.setItem(
            "selected_resturant_timings",
            localStorage.getItem("current_resturant_timings")
          );

          localStorage.setItem(
            "deliver_charges",
            JSON.parse(localStorage.getItem("selected_resturant_data"))[
              "deliveryFee"
            ] != null
              ? JSON.parse(localStorage.getItem("selected_resturant_data"))[
                  "deliveryFee"
                ]
              : 0
          );
          let ids: any = [];
          for (let y = 0; y < this.collect_customisation_ids.length; y++) {
            this.collect_customisation_ids[y].map((e, index) => {
              if (e != null && e != "" && e != undefined && e) {
                var b = vm.customise_option_variation.findIndex((f) => {
                  return f.id_value == e;
                });
                if (b > -1) {
                  ids.push({
                    ItemCustomizationOptionId: e,
                    CustomizationOptionPrice:
                      vm.customise_option_variation[b].variation[1],
                    CustomizationOptionVariationName:
                      vm.customise_option_variation[b].variation[0],
                  });
                } else {
                  ids.push({
                    ItemCustomizationOptionId: e,
                  });
                }
               
              }
            });
          }
          let item_add_to_cart: any;
          if (this.current_resturant) {
            if (vm.item_selected.other_info.itemTax.length > 0) {
              var tax: any = [];
              var tax_id: any = [];
              for (
                var i = 0;
                i < vm.item_selected.other_info.itemTax.length;
                i++
              ) {
                tax.push({
                  OrderDetailTaxId: vm.item_selected.other_info.itemTax[i].id,
                });
                tax_id.push({
                  TaxId: vm.item_selected.other_info.itemTax[i].id,
                });
              }
            } else {
              tax = "";
              tax_id = "";
            }
            item_add_to_cart = {
              OrderId: order_id ? order_id : "",
              LocationId: this.current_resturant,
              OrderDetails: [
                {
                  VariationId: this.variation_id,
                  Quantity: JSON.stringify(this.slider_value),
                  OrderDetailTaxes: tax,
                  OrderDetailCustomizations: [...ids],
                },
              ],
              Taxes: tax_id,
            };
            var p = await vm.clean(item_add_to_cart);
            p.OrderDetails = p.OrderDetails.map((e) => {
              return vm.clean(e);
            });
            if (p.Taxes) {
              p.Taxes.TaxId = await vm.removeByAttr(p.Taxes, "TaxId", "");
            }
            this.resturant_service.createOrderWithoutUserInfo(p).subscribe(
              (data: any) => {
                vm.util.dismissLoader();
                vm.afterGettingResponseFromOrderApiWithoutUser(data);
              },
              (error) => {
                vm.util.dismissLoader();
                vm._snackBar.open(error, "Done", {
                  duration: 2000,
                });
                vm.subject_service.getWaitingForCart(false);
                vm.subject_service.getWaitingForSubtotal(false);
              }
            );
          } else {
            this.presentAlertLocation();
            vm.util.dismissLoader();
          }

          this.collect_values[0] = {
            quantity: this.slider_value,
            currency: this.currency,
            price: this.money,
            vegetarian: this.item_selected.vegetarian,
            image_title: this.item_selected.image_title,
            name: this.item_selected.name,
            image: this.item_selected.image,
            description: this.item_selected.description,
          };
          this.modal_controller.dismiss({
            collect_values: this.collect_values,
            index: this.index,
          });
        }
      } else {
        this._snackBar.open(
          "Without Resturant name item cannot be added to cart",
          "Done",
          {
            duration: 2000,
          }
        );
        vm.util.dismissLoader();
        this.modal_controller.dismiss();
      }
    } else {
      this._snackBar.open(
        "Items without variation cannot be added to cart",
        "Done",
        {
          duration: 2000,
        }
      );
      this.modal_controller.dismiss();
      vm.util.dismissLoader();
    }
    // }
  }
  async loginOrRegistrationButtonClicked(form_name) {
    const modal = await this.modal_controller.create({
      component: RegistrationFormComponent,
      cssClass: "my-modal",
      componentProps: {
        form_name,
      },
    });
    return await modal.present();
  }
  async presentAlertLocation() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "CheckOut failed",

      message: "You have to select some location",
      buttons: [
        "OK",
        {
          text: "Login",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            this.loginOrRegistrationButtonClicked("login");
          },
        },
      ],
    });
    await alert.present();
  }
  async presentAlert() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "Checkout failed",
      message: "You have to login prior to adding items to cart",
      buttons: [
        "OK",
        {
          text: "Login",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            this.loginOrRegistrationButtonClicked("login");
          },
        },
      ],
    });
    await alert.present();
  }
  onFormSubmit(): void {
    for (let i = 0; i < this.skills.length; i++) {
    }
  }
  createItem(): FormGroup {
    return this.formBuilder.group({
      name: "",
      description: "",
      price: "",
    });
  }
  userForm() {
  }
  addSkill() {
    this.skills.push(
      new FormGroup({
        value: new FormControl(""),
      })
    );
  }
  logForm() {

  }
  getKeyByValue(object) {
    let object_keys: any = [];
    for (let prop in object) {
      if (object.hasOwnProperty(prop)) {
        if (object[prop] == "true" || object[prop] == true) {
          object_keys.push(prop);
        }
      }
    }
    return object_keys;
  }
  gotVariationName(ev, index, item) {
    var t: any;
    if (this.customise_option_variation.length > 0) {
      for (var i = 0; i < this.customise_option_variation.length; i++) {
        if (this.customise_option_variation[i]["id_value"] == ev.id_value) {
          t = i;
          break;
        } else {
          t = -1;
        }
      }
      if (t > -1) {
        var obj = {
          variation: ev.value_inside_selection,
          id_value: ev.id_value,
          index: index,
        };
        this.customise_option_variation[i] = obj;
      } else {
        this.customise_option_variation.push({
          variation: ev.value_inside_selection,
          id_value: ev.id_value,
          index: index,
        });
      }
    } else if (this.customise_option_variation.length == 0) {
      this.customise_option_variation.push({
        variation: ev.value_inside_selection,
        id_value: ev.id_value,
        index: index,
      });
    }
  }
  errorAfterOrderApi() {
    var vm = this;

    vm.subject_service.getWaitingForSubtotal(false);
    vm.subject_service.getWaitingForCart(false);
  }
  afterGettingResponseFromOrderApiWithoutUser(data) {

    let vm = this;
    let data_got: any = [];
    let subtotal: any = null;
    if (data) {
      if (data.subTotal) {
        subtotal = {
          subtotal: {
            amount: data.subTotal,
            currency: "USD",
          },
          tax: {
            amount: data.totalTax*(0.19075/0.1825),
            currency: "USD",
          },
        };
      }

      if (data.orderDetails) {
        data.orderDetails.map((e) => {
          let p: any = [];
          let taxes: any = [];
          var total_money: any = 0;
          if (
            e.orderDetailCustomizations &&
            e.orderDetailCustomizations.length > 0
          ) {
            e.orderDetailCustomizations.map((f) => {
              p.push({
                name: f.customizationOptionName,
                catalog_id: f.itemCustomizationOptionId,
                customizationOptionPrice: f.customizationOptionPrice
                  ? f.customizationOptionPrice
                  : null,
                customizationOptionVariationName: f.customizationOptionVariationName
                  ? f.customizationOptionVariationName
                  : null,
                uid: f.uid,
                amount: f.customizationOptionPrice,
              });
              total_money =
                total_money + parseFloat(f.customizationOptionPrice);
            });
          }
          if (e.orderDetailTaxes && e.orderDetailTaxes.length > 0) {
            e.orderDetailTaxes.map((g) => {
              taxes.push({
                OrderDetailTaxId: g.orderDetailTaxId,
              });
            });
          }
          data_got.push({
            variation_price: e.variationPrice,
            variation_currency: "USD",
            variation_name: e.variationName,
            uid: e.uid,
            catalog_id: e.variationId,
            item_name: e.itemName,
            quantity: e.quantity,
            amount: JSON.stringify(
              JSON.parse(e.quantity) *
                (JSON.parse(e.variationPrice) + total_money)
            ),
            currency: "USD",
            customisation: p,
            tax_id: taxes,
          });
        });
      }
    }
    localStorage.setItem("order_id", data["orderId"]);
    localStorage.setItem(
      "deliver_charges",
      JSON.parse(localStorage.getItem("selected_resturant_data"))[
        "deliveryFee"
      ] != null
        ? JSON.parse(localStorage.getItem("selected_resturant_data"))[
            "deliveryFee"
          ]
        : 0
    );
    vm.subject_service.getcartReponse(data_got);
    localStorage.setItem("cart_response", JSON.stringify(data_got));
    localStorage.setItem("cart_subtotal", JSON.stringify(subtotal));//subtotal
    vm.subject_service.getSubtotalReponse(subtotal);
    vm.subject_service.getWaitingForSubtotal(false);
    vm.subject_service.getWaitingForCart(false);
  }
  afterGettingResponseFromOrderApi(data) {
    let vm = this;
    let data_got: any = [];
    let subtotal: any = null;
    if (data) {
      if (data.totalMoney) {
        subtotal = {
          subtotal: {
            amount: JSON.stringify(
              parseFloat(data.totalMoney.amount) -
                parseFloat(data.totalTaxMoney.amount)
            ),
            currency: data.totalMoney.currency,
          },
          tax: {
            amount: data.totalTaxMoney.amount*(0.19075/0.1825),
            currency: data.totalTaxMoney.currency,
          },
        };
      }
      if (data.lineItems) {
        data.lineItems.map((e) => {
          let p: any = [];
          let taxes: any = [];
          if (e.modifiers && e.modifiers.length > 0) {
            e.modifiers.map((f) => {
              p.push({
                name: f.name,
                catalog_id: f.catalogObjectId,
                uid: f.uid,
                amount: f.basePriceMoney.amount,
              });
            });
          }
          if (e.appliedTaxes && e.appliedTaxes.length > 0) {
            e.appliedTaxes.map((g) => {
              taxes.push({
                OrderDetailTaxId: g.taxUid,
              });
            });
          }
          data_got.push({
            variation_price: JSON.stringify(
              parseFloat(e.basePriceMoney.amount) / 100
            ),
            variation_currency: e.basePriceMoney.currency,
            variation_name: e.variationName,
            uid: e.uid,
            catalog_id: e.catalogObjectId,
            item_name: e.name,
            quantity: e.quantity,
            amount: e.totalMoney.amount - parseFloat(e.totalTaxMoney.amount),
            currency: e.totalMoney.currency,
            customisation: p,
            tax_id: taxes,
          });
        });
      }
    }
    localStorage.setItem("order_id", data["orderId"]);
    localStorage.setItem(
      "deliver_charges",
      JSON.parse(localStorage.getItem("selected_resturant_data"))[
        "deliveryFee"
      ] != null
        ? JSON.parse(localStorage.getItem("selected_resturant_data"))[
            "deliveryFee"
          ]
        : 0
    );
    vm.subject_service.getcartReponse(data_got);
    localStorage.setItem("cart_response", JSON.stringify(data_got));
    localStorage.setItem("cart_subtotal", JSON.stringify(subtotal));//subtotal
    vm.subject_service.getSubtotalReponse(subtotal);
    vm.subject_service.getWaitingForSubtotal(false);
    vm.subject_service.getWaitingForCart(false);
  }
  async gotFormValues(ev, item_name, index) {
    let vm = this;
    vm.collect_customisation_ids[index] = [];
    let get_selected_values: any[] = await this.getKeyByValue(ev);
    let collect_names: any = [];
    collect_names[index] = get_selected_values;
    let option_array: any = [];
    option_array = [
      ...this.item_selected.other_info.customization[index][
        "customizationOptions"
      ],
    ];
    if (get_selected_values.length > 0) {
      collect_names[index].map((e) => {
        option_array.map((f) => {
          if (
            f.name == e &&
            vm.collect_customisation_ids[index].includes(f.id) == false
          ) {
            vm.collect_customisation_ids[index].push(f.id);
          }
        });
      });
    } else {
    }
    this.customisation_options_selection = ev;
    this.collect_values[1] = { title: this.item_selected.name, ...ev };
  }
  async ngOnInit() {
    const vm = this;
    vm.again_test_customisation = new Array(
      this.item_selected.other_info.customization.length
    );
    vm.collect_customisation_ids = new Array(
      this.item_selected.other_info.customization.length
    );
    this.new_slider = this.edited_values
      ? parseFloat(this.edited_values.quantity)
      : 1;
    this.slider_value = this.edited_values
      ? parseFloat(this.edited_values.quantity)
      : 1;
    this.image_url_for_larger = this.config.image_url_for_larger_image;
    this.image_url_for_smaller = this.config.image_url_for_smaller_image;

    vm.py = new Array(this.item_selected.other_info.customization.length);
    for (let u = 0; u < vm.collect_customisation_ids.length; u++) {
      vm.collect_customisation_ids[u] = new Array();
      vm.again_test_customisation[u] = new Array();
    }
    this.collect_values[1].title = this.item_selected.name;
    if (this.index > -1) {
      const val = JSON.parse(localStorage.getItem("cart_items"));
      let identifiers = Object.keys(val[this.index][1]);
      identifiers = identifiers.filter((e) => {
        if (
          val[this.index][1][e] == true ||
          val[this.index][1][e] == false ||
          val[this.index][1][e] == "true" ||
          val[this.index][1][e] == "false"
        ) {
          return true;
        }
        else {
          false;
        }
      });
      const active = identifiers.map(function (e) {
        if (val[vm.index][1][e] == true || val[vm.index][1][e] == "true") {
          return {
            key: e,
            label: "check_bopx_selction",
            value: true,
            selected: true,
            controlType: "check_box",
          };
        } else {
          return {
            key: e,
            label: "check_bopx_selction",
            value: false,
            selected: false,
            controlType: "check_box",
          };
        }
      });
      this.questions = [...active];
      if (val[this.index].length > 1) {
        for (let i = 2; i < val[this.index].length; i++) {
          const next_values = Object.keys(val[this.index][i]);
        }
      }
    }
    this.variations_data = this.item_selected.other_info
      ? this.item_selected.other_info.variation
        ? this.item_selected.other_info.variation
        : []
      : [];
    this.variations_data =
      this.variations_data.length > 0
        ? this.variations_data.map((e) => {
            return {
              name: e.name,
              amount: e.amount,
              currency: "USD",
              id: e.id,
            };
          })
        : [];
    if (this.edited_values) {
      this.option_value = this.edited_values.variation_name;
      vm.currency = this.edited_values.variation_currency;
      vm.money = this.edited_values.variation_price;
      await vm.edited_values.customisation.map((e) => {
        vm.selected_values_obtained.push(e.catalog_id);
        vm.selected_complete_customisation.push({
          catalog_id: e.catalog_id,
          variation_name: e.customizationOptionVariationName,
          amount: e.customizationOptionPrice,
        });
      });
    } else {
      vm.option_value =
        vm.variations_data.length > 0 ? vm.variations_data[0].name : "";
      vm.money =
        vm.variations_data.length > 0 ? vm.variations_data[0].amount : "";
      vm.currency =
        vm.variations_data.length > 0 ? vm.variations_data[0].currency : "USD";
      vm.variation_id =
        vm.variations_data.length > 0 ? vm.variations_data[0].id : "";
    }
  }
  clean(obj) {
    var vm = this;
    for (var propName in obj) {
      if (
        obj[propName] == null ||
        obj[propName] == "" ||
        obj[propName] == undefined
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }
  removeByAttr(arr, attr, value) {
    var i = arr.length;
    while (i--) {
      if (
        arr[i] &&
        arr[i].hasOwnProperty(attr) &&
        arguments.length > 2 &&
        arr[i][attr] === value
      ) {
        arr.splice(i, 1);
      }
    }
    return arr;
  }
  async addToCart() {
    debugger
    let vm = this;
    await vm.util.presentLoading();

    if (this.variation_id) {
      var tax: any = [];
      var tax_id: any = [];
      if (vm.item_selected.other_info.itemTax.length > 0) {
        tax = [];
        tax_id = [];
        for (var i = 0; i < vm.item_selected.other_info.itemTax.length; i++) {
          tax.push({
            OrderDetailTaxId: vm.item_selected.other_info.itemTax[i].id,
          });
          tax_id.push({ TaxId: vm.item_selected.other_info.itemTax[i].id });
        }
      } else {
        tax = "";
        tax_id: "";
      }
      let order_id = localStorage.getItem("order_id");
      this.subject_service.getWaitingForCart(true);
      this.subject_service.getWaitingForSubtotal(true);
      if (this.current_resturant) {
        if (localStorage.getItem("current_resturant")) {
          if (
            this.current_resturant == localStorage.getItem("current_resturant")
          ) {
            let ids: any = [];
            for (let y = 0; y < this.collect_customisation_ids.length; y++) {
              this.collect_customisation_ids[y].map((e) => {
                if (e != null && e != "" && e != undefined && e) {
                  ids.push({
                    ItemCustomizationOptionId: e,
                  });
                }
              });
            }

            let user_info = JSON.parse(localStorage.getItem("user_info"));
            let item_add_to_cart: any;

            if (user_info && this.current_resturant) {
              item_add_to_cart = {
                OrderId: order_id ? order_id : "",
                LocationId: this.current_resturant,
                OrderDetails: [
                  {
                    VariationId: this.variation_id,
                    Quantity: JSON.stringify(this.slider_value),
                    OrderDetailTaxes: [...tax],
                    OrderDetailCustomizations: [...ids],
                  },
                ],
                Taxes: [...tax_id],
              };
              var p = await vm.clean(item_add_to_cart);
              p.OrderDetails = p.OrderDetails.map((e) => {
                return vm.clean(e);
              });
              if (p.Taxes) {
                p.Taxes.TaxId = await vm.removeByAttr(p.Taxes, "TaxId", "");
              }
              this.resturant_service.createOrderWithoutUserInfo(p).subscribe(
                (data: any) => {
                  vm.util.dismissLoader();
                  vm.afterGettingResponseFromOrderApi(data);
                },
                (error) => {
                  vm.util.dismissLoader();
                  vm._snackBar.open(error, "Done", {
                    duration: 2000,
                  });
                  vm.subject_service.getWaitingForCart(false);
                  vm.subject_service.getWaitingForSubtotal(false);
                }
              );
            } else {
              await vm.util.presentLoading();
              vm.presentAlert();
            }
            this.collect_values[0] = {
              quantity: this.slider_value,
              currency: this.currency,
              price: this.money,
              vegetarian: this.item_selected.vegetarian,
              image_title: this.item_selected.image_title,
              name: this.item_selected.name,
              image: this.item_selected.image,
              description: this.item_selected.description,
            };
            this.modal_controller.dismiss({
              collect_values: this.collect_values,
              index: this.index,
            });
          } else {
            if (localStorage.getItem("cart_response")) {
              let get_local_storage_items = JSON.parse(
                localStorage.getItem("cart_response")
              );
              let item_id: any = [];
              let customer_id = JSON.parse(localStorage.getItem("user_info"))[
                "squareCustomerid"
              ];
              get_local_storage_items.map((e) => {
                let val = "line_items[" + e.uid + "]";
                item_id.push(val);
              });
              let data = {
                OrderId: order_id,
                LocationId: localStorage.getItem("current_resturant"),
                FieldsToClear: [...item_id],
              };
              vm.resturant_service.createOrderWithoutUserInfo(data).subscribe(
                async (data) => {
                  if (vm.item_selected.other_info.itemTax.length > 0) {
                    tax = [];
                    tax_id = [];
                    for (
                      var i = 0;
                      i < vm.item_selected.other_info.itemTax.length;
                      i++
                    ) {
                      tax.push({
                        OrderDetailTaxId:
                          vm.item_selected.other_info.itemTax[i].id,
                      });
                      tax_id.push({
                        TaxId: vm.item_selected.other_info.itemTax[i].id,
                      });
                    }
                  } else {
                    tax = "";
                    tax_id: "";
                  }
                  await vm.afterGettingResponseFromOrderApi(data);
                  localStorage.setItem(
                    "current_resturant",
                    this.current_resturant
                  );
                  localStorage.setItem(
                    "selected_resturant_data",
                    localStorage.getItem("current_resturant_data")
                  );
                  localStorage.setItem(
                    "selected_resturant_timings",
                    localStorage.getItem("current_resturant_timings")
                  );
                  localStorage.setItem(
                    "deliver_charges",
                    JSON.parse(localStorage.getItem("selected_resturant_data"))[
                      "deliveryFee"
                    ] != null
                      ? JSON.parse(
                          localStorage.getItem("selected_resturant_data")
                        )["deliveryFee"]
                      : 0
                  );

                  localStorage.removeItem("order_id");
                  if (localStorage.getItem("fulfilment")) {
                    localStorage.removeItem("fulfilment");
                    vm.subject_service.getFulfilment(false);
                  }
                  order_id = localStorage.getItem("order_id");
                  vm.current_resturant = localStorage.getItem(
                    "current_resturant"
                  );
                  let ids: any = [];
                  for (
                    let y = 0;
                    y < this.collect_customisation_ids.length;
                    y++
                  ) {
                    this.collect_customisation_ids[y].map((e) => {
                      if (e) {
                        ids.push({
                          ItemCustomizationOptionId: e,
                        });
                      }
                    });
                  }
                  let user_info = JSON.parse(localStorage.getItem("user_info"));
                  let item_add_to_cart: any;

                  if (user_info && this.current_resturant) {
                    item_add_to_cart = {
                      OrderId: order_id,
                      LocationId: this.current_resturant,
                      OrderDetails: [
                        {
                          VariationId: this.variation_id,
                          Quantity: JSON.stringify(this.slider_value),
                          OrderDetailTaxes: [...tax],

                          OrderDetailCustomizations: [...ids],
                        },
                      ],
                      Taxes: [...tax_id],
                    };
                    var p = await vm.clean(item_add_to_cart);
                    p.OrderDetails = p.OrderDetails.map((e) => {
                      return vm.clean(e);
                    });
                    if (p.Taxes) {
                      p.Taxes.TaxId = await vm.removeByAttr(
                        p.Taxes,
                        "TaxId",
                        ""
                      );
                    }
                    this.resturant_service.createOrderWithoutUserInfo(p).subscribe(
                      (data: any) => {
                        vm.util.dismissLoader();
                        vm.afterGettingResponseFromOrderApi(data);
                      },
                      (error) => {
                        vm.util.dismissLoader();
                        vm._snackBar.open(error, "Done", {
                          duration: 2000,
                        });
                        vm.subject_service.getWaitingForCart(false);
                        vm.subject_service.getWaitingForSubtotal(false);
                      }
                    );
                  } else {
                    this.presentAlert();
                  }

                  this.collect_values[0] = {
                    quantity: this.slider_value,
                    currency: this.currency,
                    price: this.money,
                    vegetarian: this.item_selected.vegetarian,
                    image_title: this.item_selected.image_title,
                    name: this.item_selected.name,
                    image: this.item_selected.image,
                    description: this.item_selected.description,
                  };
                  this.modal_controller.dismiss({
                    collect_values: this.collect_values,
                    index: this.index,
                  });
                },
                (error) => {
                  vm.util.dismissLoader();
                  vm._snackBar.open(error, "Done", {
                    duration: 2000,
                  });
                  vm.subject_service.getWaitingForCart(false);
                  vm.subject_service.getWaitingForSubtotal(false);
                }
              );
            } else {
              localStorage.setItem("current_resturant", this.current_resturant);
              localStorage.setItem(
                "selected_resturant_data",
                localStorage.getItem("current_resturant_data")
              );
              localStorage.setItem(
                "selected_resturant_timings",
                localStorage.getItem("current_resturant_timings")
              );

              localStorage.setItem(
                "deliver_charges",
                JSON.parse(localStorage.getItem("selected_resturant_data"))[
                  "deliveryFee"
                ] != null
                  ? JSON.parse(localStorage.getItem("selected_resturant_data"))[
                      "deliveryFee"
                    ]
                  : 0
              );
              let ids: any = [];
              for (let y = 0; y < this.collect_customisation_ids.length; y++) {
                this.collect_customisation_ids[y].map((e) => {
                  if (e) {
                    ids.push({
                      ItemCustomizationOptionId: e,
                    });
                  }
                });
              }
              let user_info = JSON.parse(localStorage.getItem("user_info"));
              let item_add_to_cart: any;

              if (user_info && this.current_resturant) {
                var tax: any = [];
                var tax_id: any = [];
                if (vm.item_selected.other_info.itemTax.length > 0) {
                  for (
                    var i = 0;
                    i < vm.item_selected.other_info.itemTax.length;
                    i++
                  ) {
                    tax.push({
                      OrderDetailTaxId:
                        vm.item_selected.other_info.itemTax[i].id,
                    });
                    tax_id.push({
                      TaxId: vm.item_selected.other_info.itemTax[i].id,
                    });
                  }
                } else {
                  tax = "";
                  tax_id = "";
                }
                item_add_to_cart = {
                  OrderId: order_id ? order_id : "",
                  LocationId: this.current_resturant,
                  OrderDetails: [
                    {
                      VariationId: this.variation_id,
                      Quantity: JSON.stringify(this.slider_value),
                      OrderDetailTaxes: tax,
                      OrderDetailCustomizations: [...ids],
                    },
                  ],
                  Taxes: tax_id,
                };
                var p = await vm.clean(item_add_to_cart);
                p.OrderDetails = p.OrderDetails.map((e) => {
                  return vm.clean(e);
                });
                if (p.Taxes) {
                  p.Taxes.TaxId = await vm.removeByAttr(p.Taxes, "TaxId", "");
                }
                this.resturant_service.createOrderWithoutUserInfo(p).subscribe(
                  (data: any) => {
                    vm.util.dismissLoader();
                    vm.afterGettingResponseFromOrderApi(data);
                  },
                  (error) => {
                    vm.util.dismissLoader();
                    vm._snackBar.open(error, "Done", {
                      duration: 2000,
                    });
                    vm.subject_service.getWaitingForCart(false);
                    vm.subject_service.getWaitingForSubtotal(false);
                  }
                );
              } else {
                this.presentAlert();
                vm.util.dismissLoader();
              }

              this.collect_values[0] = {
                quantity: this.slider_value,
                currency: this.currency,
                price: this.money,
                vegetarian: this.item_selected.vegetarian,
                image_title: this.item_selected.image_title,
                name: this.item_selected.name,
                image: this.item_selected.image,
                description: this.item_selected.description,
              };
              this.modal_controller.dismiss({
                collect_values: this.collect_values,
                index: this.index,
              });
            }
          }
        } else {
          localStorage.setItem("current_resturant", this.current_resturant);
          localStorage.setItem(
            "selected_resturant_data",
            localStorage.getItem("current_resturant_data")
          );
          localStorage.setItem(
            "selected_resturant_timings",
            localStorage.getItem("current_resturant_timings")
          );

          localStorage.setItem(
            "deliver_charges",
            JSON.parse(localStorage.getItem("selected_resturant_data"))[
              "deliveryFee"
            ] != null
              ? JSON.parse(localStorage.getItem("selected_resturant_data"))[
                  "deliveryFee"
                ]
              : 0
          );
          let ids: any = [];
          for (let y = 0; y < this.collect_customisation_ids.length; y++) {
            this.collect_customisation_ids[y].map((e) => {
              if (e) {
                ids.push({
                  ItemCustomizationOptionId: e,
                });
              }
            });
          }
          let user_info = JSON.parse(localStorage.getItem("user_info"));
          let item_add_to_cart: any;

          if (user_info && this.current_resturant) {
            if (vm.item_selected.other_info.itemTax.length > 0) {
              var tax: any = [];
              var tax_id: any = [];
              for (
                var i = 0;
                i < vm.item_selected.other_info.itemTax.length;
                i++
              ) {
                tax.push({
                  OrderDetailTaxId: vm.item_selected.other_info.itemTax[i].id,
                });
                tax_id.push({
                  TaxId: vm.item_selected.other_info.itemTax[i].id,
                });
              }
            } else {
              tax = "";
              tax_id = "";
            }
            item_add_to_cart = {
              OrderId: order_id ? order_id : "",
              LocationId: this.current_resturant,
              OrderDetails: [
                {
                  VariationId: this.variation_id,
                  Quantity: JSON.stringify(this.slider_value),
                  OrderDetailTaxes: tax,
                  OrderDetailCustomizations: [...ids],
                },
              ],
              Taxes: tax_id,
            };
            var p = await vm.clean(item_add_to_cart);
            p.OrderDetails = p.OrderDetails.map((e) => {
              return vm.clean(e);
            });
            if (p.Taxes) {
              p.Taxes.TaxId = await vm.removeByAttr(p.Taxes, "TaxId", "");
            }
            this.resturant_service.createOrderWithoutUserInfo(p).subscribe(
              (data: any) => {
                vm.util.dismissLoader();
                vm.afterGettingResponseFromOrderApi(data);
              },
              (error) => {
                vm.util.dismissLoader();
                vm._snackBar.open(error, "Done", {
                  duration: 2000,
                });
                vm.subject_service.getWaitingForCart(false);
                vm.subject_service.getWaitingForSubtotal(false);
              }
            );
          } else {
            this.presentAlert();
            vm.util.dismissLoader();
          }

          this.collect_values[0] = {
            quantity: this.slider_value,
            currency: this.currency,
            price: this.money,
            vegetarian: this.item_selected.vegetarian,
            image_title: this.item_selected.image_title,
            name: this.item_selected.name,
            image: this.item_selected.image,
            description: this.item_selected.description,
          };
          this.modal_controller.dismiss({
            collect_values: this.collect_values,
            index: this.index,
          });
        }
      } else {
        this._snackBar.open(
          "Without Resturant name item cannot be added to cart",
          "Done",
          {
            duration: 2000,
          }
        );
        vm.util.dismissLoader();
        this.modal_controller.dismiss();
      }
    } else {
      this._snackBar.open(
        "Items without variation cannot be added to cart",
        "Done",
        {
          duration: 2000,
        }
      );
      this.modal_controller.dismiss();
      vm.util.dismissLoader();
    }
  }

  collectnewData() {
    this.calculate_warning = true;
    for (var i = 0; i < this.collect_customisation_ids.length; i++) {
      if (
        this.collect_customisation_ids[i].length !=
          this.item_selected.other_info.customization[i]["selectionCount"] &&
        this.item_selected.other_info.customization[i]["selectionCount"]
      ) {
        this.warning_message = true;
        this.content.scrollToTop();
        return;
      } else {
        this.warning_message = false;
      }
    }
  }
  async recustomiseWithoutUser() {
    debugger
    let vm = this;
    await vm.collectnewData();
    await vm.util.presentLoading();
    this.subject_service.getWaitingForCart(true);
    this.subject_service.getWaitingForSubtotal(true);
    let order_id = localStorage.getItem("order_id");
    let fiels_to_clear: any = [];
    this.edited_values.customisation.map((e) => {
      let p =
        "line_items[" + this.edited_values.uid + "].modifiers[" + e.uid + "]";
      fiels_to_clear.push(p);
    });

    let remove_data = {
      OrderId: order_id,
      LocationId: this.current_resturant,
      // CustomerId: user_info.squareCustomerid,
      // [...fiels_to_clear]
      FieldsToClear: [...fiels_to_clear],
    };
    vm.resturant_service.createOrderWithoutUserInfo(remove_data).subscribe(
      (removed_modifier) => {
        vm.afterGettingResponseFromOrderApiWithoutUser(removed_modifier);
        let items_to_be_added: any = [];
        for (let y = 0; y < this.collect_customisation_ids.length; y++) {
          this.collect_customisation_ids[y].map((e, index) => {
            if (e != null && e != "" && e != undefined && e) {
              var b = vm.customise_option_variation.findIndex((f) => {
                return f.id_value == e;
              });
              if (b > -1) {
                items_to_be_added.push({
                  ItemCustomizationOptionId: e,
                  CustomizationOptionPrice:
                    vm.customise_option_variation[b].variation[1],
                  CustomizationOptionVariationName:
                    vm.customise_option_variation[b].variation[0],
                });
              } else {
                items_to_be_added.push({
                  ItemCustomizationOptionId: e,
                });
              }
            }
          });
        }
        var tax_id: any = [];
        vm.edited_values.tax_id.map((t) => {
          tax_id.push({ TaxId: t.OrderDetailTaxId });
        });
        let new_data = {
          OrderId: order_id,
          LocationId: vm.current_resturant,
          OrderDetails: [
            {
              VariationId: vm.edited_values.uid,
              Quantity: JSON.stringify(vm.slider_value),
              OrderDetailCustomizations: [...items_to_be_added],
              OrderDetailTaxes: vm.edited_values.tax_id
                ? [...vm.edited_values.tax_id]
                : [],
            },
          ],
          Taxes: [...tax_id],
        };
        vm.resturant_service.createOrderWithoutUserInfo(new_data).subscribe(
          (data) => {
            vm.afterGettingResponseFromOrderApiWithoutUser(data);
            vm.close();
            vm.util.dismissLoader();
          },
          (error) => {
            vm.util.dismissLoader();
            vm._snackBar.open(error, "Done", {
              duration: 2000,
            });
            vm.subject_service.getWaitingForCart(false);
            vm.subject_service.getWaitingForSubtotal(false);
          }
        );
      },
      (error) => {
        vm.util.dismissLoader();
        vm._snackBar.open(error, "Done", {
          duration: 2000,
        });
        vm.subject_service.getWaitingForCart(false);
        vm.subject_service.getWaitingForSubtotal(false);
      }
    );
  }
  async reCustomise() {
    debugger
    let vm = this;
    await vm.util.presentLoading();
    this.subject_service.getWaitingForCart(true);
    this.subject_service.getWaitingForSubtotal(true);
    let order_id = localStorage.getItem("order_id");
    let fiels_to_clear: any = [];
    this.edited_values.customisation.map((e) => {
      let p =
        "line_items[" + this.edited_values.uid + "].modifiers[" + e.uid + "]";
      fiels_to_clear.push(p);
    });

    let remove_data = {
      OrderId: order_id,
      LocationId: this.current_resturant,
      // CustomerId: user_info.squareCustomerid,
      FieldsToClear: [...fiels_to_clear],
    };
    vm.resturant_service.createOrderWithoutUserInfo(remove_data).subscribe(
      (removed_modifier) => {
        vm.afterGettingResponseFromOrderApi(removed_modifier);
        let items_to_be_added: any = [];
        for (let y = 0; y < this.collect_customisation_ids.length; y++) {
          this.collect_customisation_ids[y].map((e) => {
            if (e) {
              items_to_be_added.push({
                ItemCustomizationOptionId: e,
              });
            }
          });
        }

        let new_data = {
          OrderId: order_id,
          LocationId: vm.current_resturant,
          OrderDetails: [
            {
              uid: vm.edited_values.uid,
              Quantity: JSON.stringify(vm.slider_value),
              OrderDetailCustomizations: [...items_to_be_added],
              OrderDetailTaxes: [...vm.edited_values.tax_id],
            },
          ],
          OrderDetailTaxes: [...vm.edited_values.tax_id],
        };
        vm.resturant_service.createOrderWithoutUserInfo(new_data).subscribe(
          (data) => {
            vm.afterGettingResponseFromOrderApi(data);
            vm.close();
            vm.util.dismissLoader();
          },
          (error) => {
            vm.util.dismissLoader();
            vm._snackBar.open(error, "Done", {
              duration: 2000,
            });
            vm.subject_service.getWaitingForCart(false);
            vm.subject_service.getWaitingForSubtotal(false);
          }
        );
      },
      (error) => {
        vm.util.dismissLoader();
        vm._snackBar.open(error, "Done", {
          duration: 2000,
        });
        vm.subject_service.getWaitingForCart(false);
        vm.subject_service.getWaitingForSubtotal(false);
      }
    );
  }
  async navigateToSelectedCategoryItemPage(id,item){
    
    this.closeModel();
    var vm=this;
    vm.menu_items=[];
    if (item == "get_all")
     {
      vm.menu_items = vm.complete_menu.map((e) => {
        return {
          name: e.itemName,
          vegetarian: true,
          image_title: "Red color",
          other_info: { ...e },
          image: e.itemImageName,
          description: e.description,
          price: "$14",
        };
      });
    } else {
      vm.complete_menu.map((e) => {
        if (id == e.categoryId) {
          vm.menu_items.push({
            name: e.itemName,
            vegetarian: true,
            image_title: "Red color",
            other_info: { ...e },
            image: e.itemImageName,
            description: e.description,
            price: "$14",
          });
        }
      });
    }
    if (item.category_name == "GIFT CARDS") {
      var data: any = [];
      vm.menu_items.map((e) => {
        data.push(e.other_info);
      });
      const navigationExtras: NavigationExtras = {
        state: {
          item: vm.menu_items,
          category_name:'GIFT CARDS'
        },
      };
     this.navCtrl.pop()
      this.router.navigate([`/item-selected/${vm.current_resturant}`], {
        ...navigationExtras,
      });
    }
    else{
      if(vm.menu_items.length>1)
      {
        const navigationExtras: NavigationExtras = {
          state: {
            item: item,
            category_id:id,
            title:vm.menu_items[0].other_info.categoryName,
            loader_present:false
          }
        };
      
        vm.router.navigate([`/ordering-system/${navigationExtras.state.title.replace(/-/g, "-").toLowerCase()}`],navigationExtras)
        //vm.router.navigate([`/get-items/${vm.current_resturant}`],navigationExtras)
      await  vm.util.dismissLoader(true);
        vm.loader_present=false;
      }
      else{
        console.log("menudata",vm.menu_items[0])
        
        const navigationExtras: NavigationExtras = {
          state: {
            item: vm.menu_items[0],
            category_id: vm.menu_items[0].other_info.category_id,
            item_data: vm.menu_items[0].other_info,
          },
        };
        // const navigationExtras: NavigationExtras = {
        //   state: {
        //     item: item,
        //     category_id: item.category_id,
        //     item_data: item.other_info,
        //     title:vm.menu_items[0].other_info.categoryName,
        //     loader_present:false
        //   },
        // };
        debugger
        vm.router.navigate([`/item-selected/${navigationExtras.state.item.name.replace(/\s/g, "-").toLowerCase()}`], navigationExtras);
       
        // this.router.navigate([`/item-selected/${vm.current_resturant}`], {
        //   ...navigationExtras,
        // });
      //  await vm.util.dismissLoader(true);
      //   vm.loader_present=false;
      }
    }
    
    this.menuDisplay()
  }
  menuDisplay() {
    this.item_selected_out_of_category = false;
    if (this.show_order_total == false) {
      this.drawerMenu.close();
    }
    this.all_categories_available = true;
    this.menu_items = [];
    this.title = "MENU";
    this.image_src = "";
    this.incomingSelectedAlert = "";
  }
  ngAfterViewInit() {
    this.item_selected.other_info.customization = this.item_selected.other_info.customization.slice(
      0
    );
    this.item_selected.other_info.customization.sort(function (a, b) {
      return a.priority - b.priority;
    });
    this.last_button = this.edited_values
      ? "Save Recustomisation"
      : "Add to Cart";
    this.get_consumisation_from_api = [];
    this.get_consumisation_from_api.push(
      ...this.item_selected.other_info.associatedCategories
    );
    this.skip=true;
      this.addToCartButton=true;
    const vm = this;
    if (typeof this.item_selected == "number") {
      const cart_content = JSON.parse(localStorage.getItem("cart_items"));
      this.item_selected = cart_content[this.item_selected];
      this.item_selected = {
        vegetarian: cart_content[this.item_selected][0].vegetarian,
        image_title: cart_content[this.item_selected][0].image_title,
        name: cart_content[this.item_selected][0].name,
        image: cart_content[this.item_selected][0].image,
        description: cart_content[this.item_selected][0].description,
        price: cart_content[this.item_selected][0].price,
        quantity: cart_content[this.item_selected][0].quantity,
      };
      vm.slider_value = cart_content[this.item_selected][0].quantity;
    }
  }
  sliderValueGot(ev) {
    this.slider_value = ev;
  }
  sliderCount(ev) {
    this.slider_value = JSON.parse(ev);
  }
  closeModel()
  {
    this.modal_controller.dismiss({customisaion_collected:[],cancel:'no',model:'yes'});
  }
  closeWithSkip(){
    this.modal_controller.dismiss({customisaion_collected:[],cancel:'no',model:'no'});
  }
  close(decide?) {
    if(decide){
      this.modal_controller.dismiss({'cancel':decide});
    }
    else{
      this.modal_controller.dismiss();
    }
  }
  radioButtonClicked(group_name, ev) {
    this.option_selcted = ev.value;
  }
  changeTheColor(event, value, index) {
    if (event.checked == true) {
      this.customisation_options[index].selected = true;
    } else {
      this.customisation_options[index].selected = false;
    }
  }
  checkBoxButtonClicked(event, value, index) {
    if (event.checked == true) {
      this.check_box_options_selected.push(value);
    } else {
    }
  }
  gotEveryOptionCustomisation(ev) {
    if (this.collect_values.some((e) => e.title == ev.title)) {
      const index = this.collect_values.findIndex((e) => e.title == ev.title);
      this.collect_values[index] = { ...ev };
    } else {
      this.collect_values.push({ ...ev });
    }
  }
}
