import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-dynamic-options",
  templateUrl: "./dynamic-options.component.html",
  styleUrls: ["./dynamic-options.component.scss"],
})
export class DynamicOptionsComponent implements OnInit {
  @Input() question: any;
  @Input() form: FormGroup;
  @Input() selected_complete_customisation?:any=[];

  @Output() form_value = new EventEmitter();
  @Output() customisation_with_variation = new EventEmitter();

  public checked: any;
  public value: any;
  get isValid() {
    return this.form.controls[this.question.key].valid;
  }
  constructor() {}
  v(id){
    for (var i=0; i < this.selected_complete_customisation.length; i++) {
      if (this.selected_complete_customisation[i].catalog_id == id) {

          return this.selected_complete_customisation[i]['variation_name']+'-'+this.selected_complete_customisation[i]['amount'];
      }
  }
  
  }
  ngOnInit() {}

  ngAfterViewInit() {
    if(this.selected_complete_customisation.length>0){
      for (var i=0; i < this.selected_complete_customisation.length; i++) {
        if (this.selected_complete_customisation[i].catalog_id == this.question.id) {
  if(this.selected_complete_customisation[i]['variation_name']){
    this.customisation_with_variation.emit({value_inside_selection:[this.selected_complete_customisation[i]['variation_name'],this.selected_complete_customisation[i]['amount']],id_value:this.question.id})

  }
          
        }
    }

    }
    var vm = this;
    // vm.question.customizationOptionVariations=[{id:1,name:"small",amount:"4"},{id:2,name:"medium",amount:"5"},{id:2,name:"large",amount:"6"}]
    // setTimeout(() => {
      // this.checked =
      //   this.question.selected == true
      //     ? JSON.stringify(this.question.selected)
      //     : this.question.selected == false
      //     ? JSON.stringify(false)
      //     : "";
      // this.value =
      //   (vm.question.selected == true || vm.question.selected == false)
      //     ? JSON.stringify(vm.question.selected)
      //     : vm.question.selected;
    // }, 300);
    this.selectDefaultValue()
  }
  changeColorRadio(ev) {

    if (this.question.selected == true) {
      this.question.selected = false;
      this.form.controls[this.question.key].setValue(this.question.selected);
    } else {
      this.question.selected = true;
      this.form.controls[this.question.key].setValue(this.question.selected);

    }
  }
  async  selectDefaultValue(){
    if(this.question && this.question.customizationOptionVariations.length>0 && this.question.customizationOptionVariations){
      var val=this.question.customizationOptionVariations[0].name+-+this.question.customizationOptionVariations[0].amount
      this.customisation_with_variation.emit({value_inside_selection:val.split("-"),id_value:this.question.id})
    }
  

   

  }
  async checkBoxDefault(){
   await this.changeTheColor()
    return true
  }
  dropDownValueSelected(event,question){
    this.customisation_with_variation.emit({value_inside_selection:event.target.value.split("-"),id_value:question.id})

  }

  changeTheColor() {
    if (this.question.selected == true) {
      this.question.selected = false;
      this.form.controls[this.question.key].setValue(this.question.selected);
    } else {
      this.question.selected = true;
      this.form.controls[this.question.key].setValue(this.question.selected);
    }
  }
}
