import { Component, Input, OnInit } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public current_value_for_segment: any;
  @Input() footer_value:any;
  constructor(
    public navCtrl: NavController,
    public menu: MenuController,
    public router: Router
  ) { }
  ngOnChanges(){
    var vm=this;
    vm.current_value_for_segment=this.footer_value;
  }
  ngOnInit() {
  
//     switch (this.router.url) {
//       case '/home': {
//         vm.current_value_for_segment = 'home'
//         break;
//       }
//       case '/catering': {

//         vm.current_value_for_segment = 'catering'

//         break;
//       }
//       case '/find-a-store': {
//         vm.current_value_for_segment = 'find_a_store';


//         break;
//       }
//       case '/menu-shown': {

//         vm.current_value_for_segment = 'menu'

//         break;
//       }
//       case '/resturant-menu': {

//         // this.current_value_for_segment = 'home'

//         break;
//       }
//     }
  }
  getElement() {
    
    if (JSON.parse(localStorage.getItem("resturant_timings")) != null)
    {
    var min_date = JSON.parse(localStorage.getItem("resturant_timings"))["startLocalTime"];
    var max_date = JSON.parse(localStorage.getItem("resturant_timings"))["endLocalTime"];
    }
    if (JSON.parse(localStorage.getItem("Sat_resturant_timings")) != null)
    {
    var Sat_Mindate = JSON.parse(localStorage.getItem("Sat_resturant_timings"))["startLocalTime"];
    var Sat_Maxdate = JSON.parse(localStorage.getItem("Sat_resturant_timings"))["endLocalTime"];
    }
    if (JSON.parse(localStorage.getItem("SUN_resturant_timings")) != null)
    {
    var Sun_Mindate = JSON.parse(localStorage.getItem("SUN_resturant_timings"))["startLocalTime"];
    var Sun_Maxdate = JSON.parse(localStorage.getItem("SUN_resturant_timings"))["endLocalTime"];
    }
    var time24To12 = function(a) {
      //below date doesn't matter.
      return (new Date("1955-11-05T" + a + "Z")).toLocaleTimeString("bestfit", {
          timeZone: "UTC",
          hour12: !0,
          hour: "numeric",
          minute: "numeric"
      });
     };
    
      var time=" Mon - Fri: "+time24To12(min_date)+" to "+time24To12(max_date)+" & Sat-Sun : Closed "
      // & Sun : "+time24To12(Sun_Mindate)+" to "+time24To12(Sun_Maxdate)+""

     
    return time;
  }
  
ngAfterViewInit(){


}
  openCart() {
    this.menu.open('cart');
    // this.menu.enable(false, 'end');
  }
  segmentChangedww(ev){

  }
  moveToMenuPage() {
    this.navCtrl.navigateForward('menu-shown');

  }
  segmentChanged(ev) {
    var vm=this;
    switch (ev.detail.value) {
      case 'home': {
        vm.navCtrl.navigateRoot('home');
        break;
      }
      case 'catering': {
        vm.navCtrl.navigateRoot('catering');

        break;
      }
      case 'find-a-store': {
        vm.navCtrl.navigateRoot('find-a-store');
        break;
      }
      case 'menu': {
        this.navCtrl.navigateRoot('menu-shown');

        break;
      }
      case 'cart': {
        this.menu.open('cart');

        break;
      }
    }
  }

}
