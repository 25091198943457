import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { UtilityService } from 'src/app/service/utility.service';

@Component({
  selector: 'app-register-sign-in',
  templateUrl: './register-sign-in.component.html',
  styleUrls: ['./register-sign-in.component.scss'],
})
export class RegisterSignInComponent implements OnInit {
  @Input() items:any;
  @Input() current_resturant: any;
  public header_button_clicked: any;
  public thanks_giving_form_display:any;
  constructor(
    public util: UtilityService,
    public router: Router,
  ) { }

  ngOnInit() {}
  formToOpen(form_name) {
    this.header_button_clicked = form_name;
    this.thanks_giving_form_display=false;
    const vm = this;
    vm.util.dismissPopover();
    const navigationExtras: NavigationExtras = {
      state: {
        form_name: form_name,
      },
    };
    if(location.href.includes('/gain-credentials/'))
    { 
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;}
      this.router.onSameUrlNavigation = 'reload';
      this.util.presentLoading();
    }
    this.router.navigate([`/gain-credentials/${this.current_resturant}`], {

      ...navigationExtras,
    });
  }
}
