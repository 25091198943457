import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Storage } from "@ionic/storage";
import { Platform, NavController } from "@ionic/angular";
import { UtilityService } from "./utility.service";
import { tap, catchError } from "rxjs/operators";
import { SubjectCollectionService } from "./subject-collection.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public base_url: any;
  constructor(
    private http: HttpClient,
    private storage: Storage,
    // tslint:disable-next-line: whitespace
    public plt: Platform,
    public util: UtilityService,
    public snackbar: MatSnackBar,
    // tslint:disable-next-line: variable-name
    public subject_service: SubjectCollectionService,
    public navCtrl: NavController
  ) {
    this.base_url = environment.test_url;
  }
  // tslint:disable-next-line: variable-name
  sendAuthCredentialsToBackend(data_to_be_send) {
    var vm = this;
    // vm.util.presentLoading();
    return new Promise((resolve, reject) => {

      this.http
        .post(this.base_url + "/Authentication/ExternalLogin", {
          ...data_to_be_send,
        })
        .subscribe(
          (result: any) => {
            vm.subject_service.getLoginData(result);
            localStorage.setItem("user_info", JSON.stringify(result));
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  register(data_to_be_send) {
    return new Promise((resolve, reject) => {
      const vm = this;
      this.http
        .post(this.base_url + "/Authentication/register", {
          ...data_to_be_send,
        })
        .subscribe(
          async (result) => {
            resolve(result);
          },
          (error) => {
            vm.snackbar.open(error, "Done", {
              duration: 2000,
            });
            reject(error);
          }
        );
    });
  }
  resetPassword(body_to_be_sent){
     return new Promise((resolve, reject) => {
      this.http
      .post(
        this.base_url + "/Authentication/ResetPassword",
        { ...body_to_be_sent },
        { responseType: "json", observe: "response" }
      )
      .subscribe(
        (data)=>{
        resolve(data);
      },
      (error)=>{
         reject(error);
        }
      );
    });
  }
  login(data_to_be_send: { username?: any; password?: any; email?: any }) {
    return new Promise((resolve, reject) => {
      const vm = this;
      const httpOptions = {
        observe: "response" as "response",
      };
      this.http
        .post(
          this.base_url + "/Authentication/login",
          { ...data_to_be_send },
          { responseType: "json", observe: "response" }
        )
        .subscribe(
          (result) => {
            localStorage.setItem("token", result.headers.get("Token"));
            localStorage.setItem("user_info", JSON.stringify(result.body));
            vm.subject_service.getLoginData(result.body);
            vm.snackbar.open("Login successful", "Done", { duration: 2000 });
            resolve("success");
          },
          (error) => {
            vm.snackbar.open(error, "Done", {
              duration: 2000,
            });
            reject(error);
          }
        );
    });
  }
  logOut(): Observable<any> {
    // tslint:disable-next-line: prefer-const
    let vm = this;
    return this.http.get(this.base_url + "/Authentication/logout").pipe(
      tap((data) => {
        vm.subject_service.getLoginData(null);
        vm.navCtrl.navigateRoot("ordering-system");
        vm.subject_service.getOrderAddress(null);
        vm.subject_service.getOrderTiming(null);
        vm.subject_service.getOrderTimeMode(null);
        vm.subject_service.getOrderMode(null);
        vm.subject_service.getLocationItems(null);
        vm.subject_service.getSubtotalReponse(null);
        vm.subject_service.getcartReponse(null);
        vm.subject_service.getcartValue(null);
        vm.subject_service.getItemCount(0);
        catchError((error) => {
          return throwError(error);
        });
      })
    );
  }
  changePassword(data) {
    return this.http.post(
      this.base_url + "/Authentication/ChangePassword",
      data
    );
  }
  cancelAccount(email) {
    var data = { Email: email };
    return this.http.post(this.base_url + "/Authentication/Cancel", data);
  }
  updateProfile(data) {
    return this.http.post(
      this.base_url + "/Authentication/UpdateUserDetail",
      data
    );
  }
  updateAccount(data_to_be_send) {
    return new Promise((resolve, reject) => {
      const vm = this;
      let header = new HttpHeaders().set(
        "Authorization",
         localStorage.getItem("token")
      );
      this.http
        .post(this.base_url + "/Authentication/UpdateUserDetail", {
          ...data_to_be_send}, { responseType: "json", observe: "response" ,headers: header}
        )
        .subscribe(
          (result) => {
            localStorage.setItem("user_info", JSON.stringify(result.body));
            resolve(result);
          },
          (error) => {
            vm.snackbar.open("Error occured during update", "Done", {
              duration: 2000,
            });
            reject(error);
          }
        );
    });
  }
  forgotPassword(forgot_password) {
    var data = { Email: forgot_password.email };
    return this.http.post(
      this.base_url + "/Authentication/ForgotPassword",
      data
    );
  }
}
