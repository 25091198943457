
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { RegisterComponent } from "../register/register.component";
//import { DialogBoxesComponent } from "../dialog-boxes/dialog-boxes.component";
import {
  MatTreeFlattener,
  MatTreeFlatDataSource,
} from "@angular/material/tree";
import { FlatTreeControl } from "@angular/cdk/tree";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  NavController,
  MenuController,
  ModalController,
  AlertController,
} from "@ionic/angular";
import { NavigationExtras, Router } from "@angular/router";
import { SubjectCollectionService } from "src/app/service/subject-collection.service";
import { CustomisePage } from "src/app/page/customise/customise.page";
import { MatSnackBar } from "@angular/material/snack-bar";
//import { GroupOrderComponent } from "../group-order/group-order.component";
import * as moment from "moment";
import * as moment_time_zone from "moment-timezone";
import * as square from "square-connect";
import { SquareUtilService } from "src/app/service/square-util.service";
import { RegistrationFormComponent } from "../registration-form/registration-form.component";
import { ResturantApiService } from "src/app/service/resturant-api.service";
//import { OptionSelectedComponent } from "../option-selected/option-selected.component";
import { UtilityService } from "src/app/service/utility.service";
import { environment } from "src/environments/environment";
import { DatePipe } from '@angular/common';
import { IfStmt } from '@angular/compiler';

interface FoodNode {
  name: string;
  children?: FoodNode[];
}

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: "app-right-panel",
  templateUrl: "./right-panel.component.html",
  styleUrls: ["./right-panel.component.scss"],
})
export class RightPanelComponent implements OnInit {
  @Input() isLoaded?: any;
  @Input() complete_menu?: any;
  @Output() data_after_payment = new EventEmitter<any>();
  @Input() loader_present: any;
  public states: any = [];
  public fulfilment_state: any;
  public user_info: any;
  public length: any;
  public cart_Cat: any;
  public min_date: any;
  public max_date: any;
  @Input() Checkout?: any;
  public waiting_for_cart_response: any;
  public ordered_items: any = [];
  public right_panel_title: any;
  public waiting_subtotal_response: any;
  public sub_total_data: any;
  public subtotal_value: any;
  public tax_amont: any;
  public total_amount: any;
  public all_items: any = [];
  public actual_amount: any;
  public tax_amount: any;
  public total_with_delivery_charges: any;
  public display_order_options: any;
  public total_cal: any;
  public address_entered: any;
  public error_message: any;
  public delivery_charges: any;
  public interval_id: any;
  public resturant_state: any;
  public subscribe_current_resturant: any;
  public navigatto_check_out_clicked: any;
  public pickup_abled: any;
  constructor(
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public navCtrl: NavController,
    public menu: MenuController,
    public subject_service: SubjectCollectionService,
    public modalController: ModalController,
    public _snackBar: MatSnackBar,
    public square_util: SquareUtilService,
    public alertController: AlertController,
    public resturant_service: ResturantApiService,
    public util: UtilityService,
    public router: Router
  ) {
    this.right_panel_title = " Order details"
    this.display_order_options = false;
    var vm = this;
    this.subject_service.subscibeToOrderTiming().subscribe((data) => {
      if (vm.navigatto_check_out_clicked == true) {
        if (
          vm.order_option == "" ||
          vm.order_option == undefined ||
          vm.order_option == null ||
          !vm.order_option
        ) {
          vm.error_message = "Please select the Delivery option";
          vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
            vm.error_message
          );
        } else if (
          vm.order_option == "Delivery" &&
          localStorage.getItem("address_of_delivery")
        ) {
          vm.error_message = "";
          vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
            vm.error_message
          );
        } else if (
          vm.order_option == "Delivery" &&
          !localStorage.getItem("address_of_delivery")
        ) {
          vm.error_message = "Enter the address of delivery";
          vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
            vm.error_message
          );
        } else {
          vm.error_message = "";
          vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
            vm.error_message
          );
        }
      }
    });
    this.subject_service.subscibeToScheduleTime().subscribe((data) => {
      if (vm.navigatto_check_out_clicked == true) {
        if (
          vm.order_option == "" ||
          vm.order_option == undefined ||
          vm.order_option == null ||
          !vm.order_option
        ) {
          vm.error_message = "Please select the Delivery option";
          vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
            vm.error_message
          );
        } else if (
          vm.order_option == "Delivery" &&
          localStorage.getItem("address_of_delivery")
        ) {
          vm.error_message = "";
          vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
            vm.error_message
          );
        } else if (
          vm.order_option == "Delivery" &&
          !localStorage.getItem("address_of_delivery")
        ) {
          vm.error_message = "Enter the address of delivery";
          vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
            vm.error_message
          );
        } else {
          vm.error_message = "";
          vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
            vm.error_message
          );
        }
      }
    });
    if (localStorage.getItem("Proceed_to_check_out_clicked")) {
      this.navigatto_check_out_clicked = localStorage.getItem(
        "Proceed_to_check_out_clicked"
      );
    } else {
      this.navigatto_check_out_clicked = false;
    }
    if (localStorage.getItem("Proceed_checkout_error_message")) {
      this.error_message = localStorage.getItem(
        "Proceed_checkout_error_message"
      );
    } else {
      this.error_message = "";
    }
    if (localStorage.getItem("fulfilment")) {
      this.fulfilment_state = true;
    } else {
      this.fulfilment_state = false;
    }
    this.states = [
      { value: 0, viewValue: "Alabama" },
      { value: 1, viewValue: "Alaska" },
      { value: 2, viewValue: "American Samoa" },
      { value: 3, viewValue: "Arizona" },
      { value: 4, viewValue: "Arkansas" },
      { value: 5, viewValue: "Armed Forces" },
      { value: 6, viewValue: "Armed Forces" },
      { value: 7, viewValue: "Armed Forces" },
      { value: 8, viewValue: "California" },
      { value: 9, viewValue: "Colorado" },
      { value: 10, viewValue: "Connecticut" },
      { value: 11, viewValue: "Delaware" },
      { value: 12, viewValue: "District of Columbia" },
      { value: 13, viewValue: "Florida" },
      { value: 14, viewValue: "Georgia" },
      { value: 15, viewValue: "Guam" },
      { value: 16, viewValue: "Hawaii" },
      { value: 17, viewValue: "Idaho" },
      { value: 18, viewValue: "Illinois" },
      { value: 19, viewValue: "Indiana" },
      { value: 20, viewValue: "Iowa" },
      { value: 21, viewValue: "Kansas" },
      { value: 22, viewValue: "Kentucky" },
      { value: 23, viewValue: "Louisiana" },
      { value: 24, viewValue: "Maine" },
      { value: 25, viewValue: "Marshall Islands" },
      { value: 26, viewValue: "Maryland" },
      { value: 27, viewValue: "Massachusetts" },
      { value: 28, viewValue: "Michigan" },
      { value: 29, viewValue: "Micronesia" },
      { value: 30, viewValue: "Minnesota" },
      { value: 31, viewValue: "Mississippi" },
      { value: 32, viewValue: "Missouri" },
      { value: 33, viewValue: "Montana" },
      { value: 34, viewValue: "Nebraska" },
      { value: 35, viewValue: "Nevada" },
      { value: 36, viewValue: "New Hampshire" },
      { value: 37, viewValue: "New Jersey" },
      { value: 38, viewValue: "New Mexico" },
      { value: 39, viewValue: "New York" },
      { value: 40, viewValue: "North Carolina" },
      { value: 41, viewValue: "North Dakota" },
      { value: 42, viewValue: "Northern Mariana Islands" },
      { value: 43, viewValue: "Ohio" },
      { value: 44, viewValue: "Oklahoma" },
      { value: 45, viewValue: "Oregon" },
      { value: 46, viewValue: "Palau" },
      { value: 47, viewValue: "Pennsylvania" },
      { value: 48, viewValue: "Puerto Rico" },
      { value: 49, viewValue: "Rhode Island" },
      { value: 50, viewValue: "South Carolina" },
      { value: 51, viewValue: "South Dakota" },
      { value: 52, viewValue: "Tennessee" },
      { value: 53, viewValue: "Texas" },
      { value: 54, viewValue: "Utah" },
      { value: 55, viewValue: "Vermont" },
      { value: 56, viewValue: "Virgin Islands" },
      { value: 57, viewValue: "Virginia" },
      { value: 58, viewValue: "Washington" },
      { value: 59, viewValue: "West Virginia" },
      { value: 60, viewValue: "Wisconsin" },
      { value: 61, viewValue: "Wyoming" },
    ];
    var vm = this;
    this.subscribe_current_resturant = this.subject_service
      .subscibeToCurrentResturent()
      .subscribe((new_resturant_data) => {
        // vm.checkOpeningHours("-5");
      });
    vm.subject_service
      .subscibeToErrormessageProceedToCheckOutClicked()
      .subscribe((error) => {
        vm.error_message = error;
      });
    vm.subject_service
      .subscibeToProceedToCheckOutClicked()
      .subscribe((data) => {
        this.navigatto_check_out_clicked = data;
      });
    vm.subject_service.subscibeToFulfimnet().subscribe((data) => {
      vm.fulfilment_state = data;
    });
    this.subject_service.subscibeToOrderAddress().subscribe(
      (data) => {
        if (vm.navigatto_check_out_clicked == true) {
          if (!vm.order_for && !localStorage.getItem("address_of_delivery")) {
            vm.error_message =
              "Please select timing of order and enter address for delivery";
          } else if (!vm.order_for) {
            vm.error_message = "Please select timing of order";
          } else if (localStorage.getItem("address_of_delivery")) {
            vm.error_message = "Please select the timing of delivery";
          }
        }
        vm.address_entered = data;
      },
      (error) => {
      }
    );

    this.subject_service.subscibeToOrderMode().subscribe(
      (data) => {
        if (data) {
          var obj = JSON.parse(data);
          vm.order_option = obj.order_delivery_mode;
        } else {
          vm.order_option = "";
        }

        vm.address_entered = "";
      },
      (error) => {
      }
    );
    vm.subject_service.subscibeToOrderTimeMode().subscribe((data) => {
      if (data) {
        var obj = JSON.parse(data);
        vm.order_for = obj.order_time_mode;
      } else {
        vm.order_for = "";
      }
    });
    vm.subject_service.subscibeTolocatinItems().subscribe(
      (data) => {
        if (data) {
          vm.all_items = [...data];
        }
      },
      (error) => {
      }
    );
    vm.waiting_for_cart_response = false;
    vm.waiting_subtotal_response = false;

    vm.subject_service.subscibeToWaitingSubtotal().subscribe((data) => {
      vm.waiting_subtotal_response = data;
    });
    if (this.isLoaded = true) {
      this.util.dismissLoader();
      this.loader_present = false;
    }
    vm.subject_service.subscibeToWaitingAddToCart().subscribe((data) => {
      vm.waiting_for_cart_response = data;
      if (this.isLoaded = true) {
        this.util.dismissLoader();
        this.loader_present = false;
      }
    });


    vm.subject_service.subscibeToSubtotal().subscribe((data) => {
      debugger;
      vm.sub_total_data = data;
      if (data) {
        // vm.subtotal_value = parseFloat(vm.sub_total_data.subtotal.amount);
        // vm.tax_amont =vm.subtotal_value * (1 + 0.0825) * (1 + 0.10) - vm.subtotal_value;
        // // parseFloat(vm.sub_total_data.tax.amount)
        // vm.total_amount =
        //   parseFloat(vm.sub_total_data.subtotal.amount) +
        //   vm.tax_amont;

        vm.subtotal_value = parseFloat(vm.sub_total_data.subtotal.amount);
    
        // Calculate tax at 18.25% and round it to two decimal places
        vm.tax_amont = Math.round(vm.subtotal_value * 0.1825 * 100) / 100;
        
        // Calculate the total amount by adding tax to the subtotal
        vm.total_amount = vm.subtotal_value + vm.tax_amont;

        vm.total_with_delivery_charges =
          vm.total_amount + parseFloat(vm.delivery_charges);
        if (this.isLoaded = true) {
          this.util.dismissLoader();
          this.loader_present = false;
        }
        if (vm.order_option == 'Delivery') {
          if (parseFloat(vm.sub_total_data.subtotal.amount) == 0) {
            vm.subject_service.getTotalPayableAmount(0.00)
            vm.total_with_delivery_charges = 0
          }
          else {
            vm.subject_service.getTotalPayableAmount(vm.total_with_delivery_charges)
          }
        }
        else {
          vm.subject_service.getTotalPayableAmount(vm.total_amount)
        }

      }
      else {
        vm.subject_service.getTotalPayableAmount(0.00)

      }
    });
    vm.subject_service.subscibeToResponseFromCart().subscribe((data) => {
      vm.ordered_items = [];
      if (data) {
        if (data.length == 0) {
          vm.display_order_options = false;
          vm.right_panel_title = "Order details";
          vm.subject_service.getItemCount(0)
        } else {
          vm.right_panel_title = "My order";
          vm.display_order_options = true;
          vm.subject_service.getItemCount(data.length)
        }
      } else {
        vm.display_order_options = false;
        vm.right_panel_title = "Order details";
      }

      if (data) {
        vm.ordered_items.push(...data);
        vm.delivery_charges = localStorage.getItem("deliver_charges")
          ? localStorage.getItem("deliver_charges")
          : 0;
      }
    });
    this.subject_service.subscibeToLoginData().subscribe(
      (data) => {
        if (data) {
          vm.user_info = data;
        } else {
          vm.user_info = null;
          vm.waiting_for_cart_response = false;
          vm.ordered_items = [];
          vm.isLoaded = true;
          vm.sub_total_data = null;
          vm.subtotal_value = 0.00;
          vm.tax_amont = 0.00
          vm.waiting_subtotal_response = false;
        }
      },
      (error) => {

      }
    );

    if (localStorage.getItem("user_info")) {
      vm.user_info = JSON.parse(localStorage.getItem("user_info"));
      vm.delivery_charges = localStorage.getItem("deliver_charges")
        ? localStorage.getItem("deliver_charges")
        : 0;
    } else {
      vm.user_info = null;
      vm.delivery_charges = localStorage.getItem("deliver_charges")
        ? localStorage.getItem("deliver_charges")
        : 0;
    }
    this.paymentForm = true;
  }
  async evokeLoader() {
    try {
      var p = await this.util.presentLoading();
      await this.presentAlert();
      await this.util.dismissLoader();
    } catch (error) {
    }
  }
  removeCustomisation(i, main_product) {
    var item_id = "line_items[" + main_product.uid + "].modifiers[" + i.uid + "]";
    this.removeItemWithoutUser(main_product, item_id)
  }
  search(nameKey, myArray) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].itemName.trim() == nameKey.trim()) {
        return myArray[i];
      }
    }
  }

  async editContentNewWithoutUser(index) {
    var vm = this;
    if (vm.complete_menu) {
      vm.all_items = [];
      vm.all_items = [...vm.complete_menu]
    }
    var searched_value = await vm.search(
      vm.ordered_items[index]["item_name"],
      vm.all_items
    );
    var modal = await this.modalController.create({
      component: CustomisePage,
      cssClass: "my-modal",
      componentProps: {
        edited_values: vm.ordered_items[index],
        item_selected: {
          description: searched_value.description,
          other_info: searched_value,
          image: searched_value.itemImageName,
          name: searched_value.itemName,
        },
        current_resturant: localStorage.getItem("current_resturant"),
      },
    });
    return await modal.present();
  }
  async editContentNew(index) {
    var vm = this;
    var searched_value = await vm.search(
      vm.ordered_items[index]["item_name"],
      vm.all_items
    );
    var modal = await this.modalController.create({
      component: CustomisePage,
      cssClass: "my-modal",
      componentProps: {
        edited_values: vm.ordered_items[index],
        item_selected: {
          description: searched_value.description,
          other_info: searched_value,
          image: searched_value.itemImageName,
          name: searched_value.itemName,
        },
        current_resturant: localStorage.getItem("current_resturant"),
      },
    });
    return await modal.present();
  }
  removeItemWithoutUser(index, modifier_removal?) {
 
    var vm = this;
    vm.subject_service.getWaitingForSubtotal(true);
    vm.subject_service.getWaitingForCart(true);
    var item_id = modifier_removal ? modifier_removal : "line_items[" + this.ordered_items[index].uid + "]";
    var order_id = localStorage.getItem("order_id");
    var location_id = JSON.parse(
      localStorage.getItem("current_resturant_data")
    )["id"];
    var data = {
      OrderId: order_id,
      LocationId: location_id,
      FieldsToClear: [item_id],
    };
    vm.resturant_service.createOrderWithoutUserInfo(data).subscribe(
      (data: any) => {
        var data_got: any = [];
        var subtotal: any;
        debugger;
        var total_tax:any=data.subTotal * (1 + 0.0825) * (1 + 0.10) - data.subTotal;
        if (data.orderDetails) {
          if (data.subTotal) {
            subtotal = {
              subtotal: {
                amount: data.subTotal,
                currency: "USD"
              },
              tax: {
                amount: total_tax *(0.19075/0.1825),
                currency: "USD"
              }

            };
          }
          data.orderDetails.map((e) => {
            var p: any = [];
            var total_money: any = 0;

            if (e.orderDetailCustomizations && e.orderDetailCustomizations.length > 0) {
              e.orderDetailCustomizations.map((f) => {
                p.push({
                  name: f.customizationOptionName,
                  catalog_id: f.itemCustomizationOptionId,
                  uid: f.uid,
                  amount: f.customizationOptionPrice,
                  customizationOptionPrice: f.customizationOptionPrice ? f.customizationOptionPrice : null,
                  customizationOptionVariationName: f.customizationOptionVariationName ? f.customizationOptionVariationName : null,
                });
                total_money = total_money + parseFloat(f.customizationOptionPrice)

              });
            }

            data_got.push({
              uid: e.uid,
              item_name: e.itemName,
              quantity: e.quantity,
              amount: JSON.stringify(JSON.parse(e.quantity) * (JSON.parse(e.variationPrice) + total_money)),
              catalog_id: e.variationId,
              variation_price: e.variationPrice,
              currency: "USD",
              customisation: p,
            });
          });
        }
        localStorage.setItem("order_id", data["orderId"]);
    
        vm.subject_service.getcartReponse(data_got);
       
        data_got
          ? localStorage.setItem("cart_response", JSON.stringify(data_got))
          : localStorage.setItem("cart_response", null);
        subtotal
          ? localStorage.setItem("cart_subtotal", JSON.stringify(subtotal))
          : localStorage.setItem("cart_subtotal", null);

        vm.subject_service.getSubtotalReponse(subtotal);

        vm.subject_service.getWaitingForSubtotal(false);
        vm.subject_service.getWaitingForCart(false);
      },
      (error) => {
        vm.subject_service.getWaitingForCart(false);
        vm.subject_service.getWaitingForSubtotal(false);
        this._snackBar.open("Error while removing item from cart", "End now", {
          duration: 500,
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
      }
    );
  }
  removeItemNew(index) {

    var vm = this;
    vm.subject_service.getWaitingForSubtotal(true);
    vm.subject_service.getWaitingForCart(true);
    var item_id = "line_items[" + this.ordered_items[index].uid + "]";
    var order_id = localStorage.getItem("order_id");
    var location_id = localStorage.getItem("current_resturant");
    var customer_id = JSON.parse(localStorage.getItem("user_info"))[
      "squareCustomerid"
    ];

    var data = {
      OrderId: order_id,
      LocationId: location_id,
      FieldsToClear: [item_id],
    };
    vm.resturant_service.createOrderApi(data).subscribe(
      (data: any) => {
        var data_got: any = [];
        var subtotal: any;
        debugger;
        var total:any=parseFloat(data.totalMoney.amount) -parseFloat(data.totalTaxMoney.amount);
        var total_tax=total * (1 + 0.0825) * (1 + 0.10) - total;
        if (data.lineItems) {
          subtotal = {
            subtotal: {
              amount:
                parseFloat(data.totalMoney.amount) -
                parseFloat(data.totalTaxMoney.amount),
              currency: data.totalMoney.currency,
            },
            tax: {
              amount: total_tax*(0.19075/0.1825),
              currency: data.totalTaxMoney.currency,
            },
          };
          data.lineItems.map((e) => {
            var p: any = [];
            if (e.modifiers && e.modifiers.length > 0) {
              e.modifiers.map((f) => {
                p.push({
                  name: f.name,
                  catalog_id: f.catalogObjectId,
                  uid: f.uid,
                });
              });
            }

            data_got.push({
              uid: e.uid,
              item_name: e.name,
              quantity: e.quantity,
              amount:
                parseFloat(e.totalMoney.amount) -
                parseFloat(e.totalTaxMoney.amount),
              currency: e.totalMoney.currency,
              customisation: p,
            });
          });
        }
        localStorage.setItem("order_id", data["orderId"]);
        vm.subject_service.getcartReponse(data_got);
        data_got
          ? localStorage.setItem("cart_response", JSON.stringify(data_got))
          : localStorage.setItem("cart_response", null);
        subtotal
          ? localStorage.setItem("cart_subtotal", JSON.stringify(subtotal))
          : localStorage.setItem("cart_subtotal", null);

        vm.subject_service.getSubtotalReponse(subtotal);

        vm.subject_service.getWaitingForSubtotal(false);
        vm.subject_service.getWaitingForCart(false);
      },
      (error) => {
        vm.subject_service.getWaitingForCart(false);
        vm.subject_service.getWaitingForSubtotal(false);
        this._snackBar.open("Error while removing item from cart", "End now", {
          duration: 500,
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
      }
    );
  }
  // async createOrder() {
  //   var SquareConnect = require("square-connect");
  //   var defaultClient = SquareConnect.ApiClient.instance;
  //   defaultClient.basePath = environment.square_keys.path;
  //   var oauth2 = defaultClient.authentications["oauth2"];
  //   oauth2.accessToken = environment.square_keys.squareAccessToken;
  //   var apiInstance = new SquareConnect.OrdersApi();
  //   var locationId = "1CGTBPQTCJ8QV";

  //   var body = new SquareConnect.CreateOrderRequest();
  //   try {
  //     var p = await apiInstance.createOrderWithHttpInfo(locationId, body);
  //   } catch (error) {
  //   }
  // }
  public _transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level,
    };
  };
  // tslint:disable-next-line: member-ordering
  @Input() displaying_place: any;
  // tslint:disable-next-line: member-ordering
  @Input() current_resturant?: any;
  // tslint:disable-next-line: member-ordering
  public collect_orders: any = [];
  // tslint:disable-next-line: member-ordering
  public user_info_from_local: any;
  // tslint:disable-next-line: member-ordering
  public group_order_data: any;
  // tslint:disable-next-line: member-ordering
  public group_order_time: any;
  public order_fulfillmet_time: any;
  // tslint:disable-next-line: member-ordering
  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  // tslint:disable-next-line: member-ordering
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  // tslint:disable-next-line: member-ordering
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  // tslint:disable-next-line: member-ordering
  public paymentForm: any;
  // tslint:disable-next-line: variable-name
  // tslint:disable-next-line: member-ordering
  size_of_left_panel: any;
  // tslint:disable-next-line: variable-name
  // tslint:disable-next-line: member-ordering
  public order_option: any;
  // tslint:disable-next-line: variable-name
  // tslint:disable-next-line: member-ordering
  public order_for: any;
  // tslint:disable-next-line: variable-name
  // tslint:disable-next-line: member-ordering
  public order_content: any = [];
  // tslint:disable-next-line: member-ordering
  // tslint:disable-next-line: variable-name
  // tslint:disable-next-line: member-ordering
  public save_favorite_result: any;
  public group_order_date: any;
  // tslint:disable-next-line: variable-name
  // tslint:disable-next-line: no-trailing-whitespace

  async removeItem(index) {
    const item = JSON.parse(localStorage.getItem("cart_items"));
    if (item) {
      item.map((e) => {
      });
      const val = item.splice(index, 1);
      localStorage.setItem("cart_items", JSON.stringify(item));
    }
    if (localStorage.getItem("cart_items")) {
      // tslint:disable-next-line: no-shadowed-variable
      const val = JSON.parse(localStorage.getItem("cart_items"));
      this.order_content = [];
      // let p: any = [];
      this.order_content = val.map((e: any[]) => {
        return {
          quantity: e[0].quantity,
          item_name: e[1].title,
          currency: e[0].currency,
          total_cost: JSON.stringify(
            parseFloat(e[0].price) * parseFloat(e[0].quantity)
          ),
          items_selected: e.map((e: {}) => {
            // tslint:disable-next-line: no-shadowed-variable
            let p: any;
            if ("quantity" in e || "price" in e) {
              // tslint:disable-next-line: no-string-literal
              p = "";
            }
            if ("title" in e) {
              // tslint:disable-next-line: no-string-literal
              p = e["title"];
            } else if ("option_selected" in e) {
              // tslint:disable-next-line: no-string-literal
              p = e["option_selected"];
            } else {
              // tslint:disable-next-line: forin
              for (const f in e) {
                // tslint:disable-next-line: triple-equals
                if (e[f] == "true") {
                  p = e[f];
                }
                // tslint:disable-next-line: triple-equals
                if (e[f] == true) {
                  p = e[f];
                }
              }
            }
            return p;
          }),
        };
      });
    }
  }
  // async presentModal(option_name) {
  //   var vm = this;
  //   const modal = await this.modalController.create({
  //     component: OptionSelectedComponent,
  //     cssClass: "my-custom-class",
  //     componentProps: {
  //       option_selected: option_name,
  //     },
  //   });

  //   modal.onDidDismiss().then(() => {
  //     vm.address_entered = localStorage.getItem("address_of_delivery")
  //       ? JSON.parse(localStorage.getItem("address_of_delivery"))
  //       : null;
  //     if (vm.navigatto_check_out_clicked == true) {
  //       if (!vm.order_for && !localStorage.getItem("address_of_delivery")) {
  //         vm.error_message =
  //           "Please select order_for and enter delivery address";
  //         vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
  //           vm.error_message
  //         );
  //       } else if (
  //         vm.order_for == "" ||
  //         vm.order_for == undefined ||
  //         vm.order_for == null ||
  //         !vm.order_for
  //       ) {
  //         vm.error_message = "Please select the order_for option";
  //         vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
  //           vm.error_message
  //         );
  //       } else if (vm.order_for == "ASAP") {
  //         vm.error_message = "";
  //         vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
  //           vm.error_message
  //         );
  //       } else if (
  //         vm.order_for == "Scheduled" &&
  //         !localStorage.getItem("timing_of_fulfilment")
  //       ) {
  //         vm.error_message = "Enter the Schedule timing";
  //         vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
  //           vm.error_message
  //         );
  //       } else if (!localStorage.getItem("address_of_delivery")) {
  //         vm.error_message = "Please  enter delivery address";
  //         vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
  //           vm.error_message
  //         );
  //       } else {
  //         vm.error_message = "";
  //         vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
  //           vm.error_message
  //         );
  //       }
  //     }
  //   });
  //   return await modal.present();
  // }
  getStateName() {
    var p: any;
    if (this.address_entered.state) {
      this.states.filter((e) => {
        if (e.value == this.address_entered.state) {
          p = e.viewValue;
        }
      });
      return p;
    }
  }
  async editContent(item, item_id?) {
    try {
      const vm = this;
      const val = JSON.parse(localStorage.getItem("cart_items"));
      const val_1 = {
        vegetarian: val[item][0].vegetarian,
        image_title: val[item][0].image_title,
        name: val[item][0].name,
        image: val[item][0].image,
        description: val[item][0].description,
        price: val[item][0].price,
        quantity: val[item][0].quantity,
      };
      const modal = await this.modalController.create({
        component: CustomisePage,
        cssClass: "my-modal",
        componentProps: {
          item_selected: val_1,
          current_resturant: vm.current_resturant,
          index: item,
        },
      });
      modal.onDidDismiss().then(async (collected_value) => {
        if (collected_value && collected_value.data) {
          if (collected_value.data.index > -1) {
            let old_values: any = [];
            old_values = [...JSON.parse(localStorage.getItem("cart_items"))];
            old_values[collected_value.data.index] =
              collected_value.data.collect_values;

            localStorage.setItem("cart_items", JSON.stringify(old_values));

            await vm.subject_service.getcartValue(old_values);
          } else {

            // vm.get_customisation_values.push(collected_value.data.collect_values);
            let old_values: any = [];
            old_values = [...JSON.parse(localStorage.getItem("cart_items"))];
            const new_values = [
              ...old_values,
              collected_value.data.collect_values,
            ];
            localStorage.setItem("cart_items", JSON.stringify(new_values));
            await vm.subject_service.getcartValue(new_values);
          }
        }
      });
      return await modal.present();
    } catch (error) {
    }
  }
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  // saveFavorite() {
  //   const vm = this;
  //   const val = vm.order_content.map((e) => ({ name: e.item_name }));
  //   if (vm.order_content.length > 0) {
  //     const dialogRef = this.dialog.open(DialogBoxesComponent, {
  //       data: { form_name: "save_favorite" },
  //     });
  //     dialogRef.afterClosed().subscribe((result) => {
  //       const TREE_DATA: FoodNode[] = [
  //         {
  //           name: this.order_content.length + " " + "items",
  //           children: [...val],
  //         },
  //       ];
  //       this.dataSource.data = TREE_DATA;
  //       vm.save_favorite_result = result;
  //     });
  //   } else {
  //     this._snackBar.open("No Item to add in favorites", "End now", {
  //       duration: 500,
  //       horizontalPosition: "center",
  //       verticalPosition: "bottom",
  //     });
  //   }
  // }
  ngOnChanges() { }

  ngOnInit() {
    const vm = this;
    if (localStorage.getItem("cart_response")) {
      var data: any = [];
      data = JSON.parse(localStorage.getItem("cart_response"));
      vm.order_content = [];
      vm.ordered_items = [];
      if (data.length == 0) {
        vm.right_panel_title = "Order details";

        vm.display_order_options = false;
      } else {
        vm.right_panel_title = "My order";

        vm.display_order_options = true;
        vm.subject_service.getItemCount(data.length)

      }
      vm.order_content = data;
      vm.ordered_items = [...data];
      this.isLoaded = true;
      this.waiting_for_cart_response = false;
    } else {
      vm.right_panel_title = "Order details";

      this.isLoaded = true;
      this.waiting_for_cart_response = false;
    }
    if (localStorage.getItem("order_fulfilment_mode")) {
      if (
        JSON.parse(localStorage.getItem("order_fulfilment_mode"))[
        "order_delivery_mode"
        ] == "Delivery" ||
        JSON.parse(localStorage.getItem("order_fulfilment_mode"))[
        "order_delivery_mode"
        ] == "Pickup"
      ) {
        vm.address_entered = JSON.parse(
          localStorage.getItem("address_of_delivery")
        );
      }

      vm.order_option = JSON.parse(
        localStorage.getItem("order_fulfilment_mode")
      )["order_delivery_mode"];
    }
    if (localStorage.getItem("order_fulfilment_time")) {
      vm.order_for = JSON.parse(localStorage.getItem("order_fulfilment_time"))[
        "order_time_mode"
      ];
      if (vm.order_for == "ASAP") {
      }
      if (vm.order_for == "Scheduled") {
        if (localStorage.getItem("schedule_time")) {
        }
      }
    }
    if (localStorage.getItem("cart_subtotal")) {
      var data = JSON.parse(localStorage.getItem("cart_subtotal"));

      vm.sub_total_data = data;
      if (data) {
        debugger;
        // vm.subtotal_value = parseFloat(vm.sub_total_data.subtotal.amount);
        // vm.tax_amont = vm.subtotal_value * (1 + 0.0825) * (1 + 0.10) - vm.subtotal_value;
        // //parseFloat(vm.sub_total_data.tax.amount)
        // vm.total_amount =
        //   parseFloat(vm.sub_total_data.subtotal.amount) +
        //   vm.tax_amont;
        vm.subtotal_value = parseFloat(vm.sub_total_data.subtotal.amount);
    
        // Calculate tax at 18.25% and round it to two decimal places
        vm.tax_amont = Math.round(vm.subtotal_value * 0.1825 * 100) / 100;
        
        // Calculate the total amount by adding tax to the subtotal
        vm.total_amount = vm.subtotal_value + vm.tax_amont;



        vm.total_with_delivery_charges =
          (vm.total_amount + parseFloat(vm.delivery_charges));
        if (vm.order_option == 'Delivery') {
          vm.subject_service.getTotalPayableAmount(vm.total_with_delivery_charges)
        }
        else {
          vm.subject_service.getTotalPayableAmount(vm.total_amount)

        }
      }
    }
    const val: any = [];
    this.subject_service.subscibeToCartValue().subscribe((data) => {
      if (data) {
        vm.collect_orders.push(data[0].collect_values);

        if (localStorage.getItem("cart_items")) {
          const val = JSON.parse(localStorage.getItem("cart_items"));
          this.order_content = [];
          const p: any = [];
          this.order_content = val.map((e: any[]) => {
            return {
              quantity: e[0].quantity,
              item_name: e[1].title,
              currency: e[0].currency,
              total_cost: JSON.stringify(
                parseFloat(e[0].price) * parseFloat(e[0].quantity)
              ),
              items_selected: e.map((e: {}) => {
                let p: any;
                if ("quantity" in e || "price" in e) {
                  p = "";
                }
                if ("title" in e) {
                  p = e["title"];
                } else if ("option_selected" in e) {
                  p = e["option_selected"];
                } else {
                  for (const f in e) {
                    if (e[f] == "true") {
                      p = e[f];
                    }
                    if (e[f] == true) {
                      p = e[f];
                    }
                  }
                }
                return p;
              }),
            };
          });
        }
      }
    });
  }
  regiaterButton(form_type: any) {
    const dialogRef = this.dialog.open(RegisterComponent, {
      data: { form_name: form_type },
      panelClass: ["no-padding", "no-scrolls"],
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "Sign up") {
        const dialogRef = this.dialog.open(RegisterComponent, {
          data: { form_name: "register" },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result == "login") {
            const dialogRef = this.dialog.open(RegisterComponent, {
              data: { form_name: "login" },
            });
            dialogRef.afterClosed().subscribe((result) => {
              if (result == "forgot_password") {
              }
            });
          }
        });
      }
      else if (result == "forgot_password") {
      }
      else if (result == "login") {
      }
    });
  }
  async loginOrRegistrationButtonClicked(form_name) {
    const modal = await this.modalController.create({
      component: RegistrationFormComponent,
      cssClass: "my-modal",
      componentProps: {
        form_name,
      },

    });
    return await modal.present();
  }

  async presentAmountAlert() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "Checkout failed",

      message:
        "Your basket is having less items to deliver.Please fill some  more items in it before proceeding to check-out page",
      buttons: [
        {
          text: "OK",
          role: "OK",
          cssClass: "secondary",
          handler: (blah) => {
          },
        },
      ],
    });

    await alert.present();
  }
  async presentEmptyBasketAlert() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "Checkout failed!!",

      message:
        "Your basket is empty.",
      buttons: [
        {
          text: "OK",
          role: "OK",
          cssClass: "secondary",
          handler: (blah) => {
          },
        },
      ],
    });

    await alert.present();
  }
  async presentMinimumDeliveryAlert(amount) {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "CheckOut failed",

      message: `Minimum delivery of $${amount} should be entered`,
      buttons: [
        "OK",
      ],
    });

    await alert.present();
  }
  async presentAlert() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "Checkout failed",
      message: "You have to login prior to proceeding to checkout section",
      buttons: [
        "OK",
        {
          text: "Login",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            this.loginOrRegistrationButtonClicked("login");
          },
        },
      ],
    });

    await alert.present();
  }
  createUtc(offset) {
    var d = new Date();

    var utc = d.getTime() + d.getTimezoneOffset() * 60000;

    var nd = new Date(utc + 3600000 * offset);
    return nd;
  }
  async checkOpeningHours(offset) {
    var vm = this;

    if (JSON.parse(localStorage.getItem("selected_resturant_timings"))) {
      var current_hotel_period = JSON.parse(
        localStorage.getItem("selected_resturant_timings")
      );
      if (
        current_hotel_period["endLocalTime"] &&
        current_hotel_period["startLocalTime"]
      ) {
        var end_time_split = current_hotel_period["endLocalTime"].split(":", 3);
        var start_time_split = current_hotel_period["startLocalTime"].split(
          ":",
          3
        );
        var end_hour = parseInt(end_time_split[0]);
        var start_hour = parseInt(start_time_split[0]);
        var end_minute = parseInt(end_time_split[1]);
        var start_minute = parseInt(start_time_split[1]);
        var nd = this.createUtc(offset);
        if (nd.getHours() >= start_hour && end_hour >= nd.getHours()) {
          if (start_hour == nd.getHours()) {
            if (start_minute == nd.getMinutes()) {
              vm.resturant_state = "Reesturant is close";
              return "close";
            } else {
              vm.resturant_state = "Resturant is open";
              return "open";
            }
          } else if (end_hour == nd.getHours()) {
            if (end_minute == nd.getMinutes()) {
              vm.resturant_state = "Resturant is close";

              return "close";
            } else {
              vm.resturant_state = "Resturant is open";

              return "open";
            }
          } else {
            vm.resturant_state = "Resturant is open";

            return "open";
          }
        } else {
          vm.resturant_state = "Resturant is close";
          return "close";
        }
      }
    } else {
      return "close";
    }
  }
  // addAddressAgain() {
  //   this.presentModal("change_addresses");
  // }
  async deliveryTime(ev) {
    var vm = this;
    var obj: any;
    obj = JSON.stringify({ order_time_mode: ev["value"], time: "" }); //moment(Date.now()).format( "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")
    if (ev["value"] == "ASAP") {
      localStorage.removeItem("schedule_time");
      if (localStorage.getItem("selected_resturant_timings")) {
        if ((await this.checkOpeningHours("-5")) == "close") {
          this.presentAlertofClose();
        } else {
        }
      }
    }
    if (ev["value"] == "Scheduled") {
    }

    localStorage.setItem("order_fulfilment_time", obj);
    this.subject_service.getOrderTimeMode(obj);
    if (vm.navigatto_check_out_clicked == true) {
      if (
        vm.order_option == "" ||
        vm.order_option == undefined ||
        vm.order_option == null ||
        !vm.order_option
      ) {
        vm.error_message = "Please select the Delivery option";
        vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
          vm.error_message
        );
      } else if (
        vm.order_option == "Delivery" &&
        localStorage.getItem("address_of_delivery")
      ) {
        vm.error_message = "";
        vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
          vm.error_message
        );
      } else if (
        vm.order_option == "Delivery" &&
        !localStorage.getItem("address_of_delivery")
      ) {
        vm.error_message = "Enter the address of delivery";
        vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
          vm.error_message
        );
      } else {
        vm.error_message = "";
        vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
          vm.error_message
        );
      }
      if (
        ev["value"] == "Scheduled" &&
        !localStorage.getItem("timing_of_fulfilment")
      ) {
        vm.error_message = vm.error_message
          ? vm.error_message + "" + "and please select the schedule timing"
          : "Please select the schedule timing";
        vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
          vm.error_message
        );
      }
    }
  }
  radioButtonClicked(ev) {
  }
  async presentAlertofClose() {
    var vm = this;
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "Alert",
      message: "This resturant is currently closed",
      buttons: [
        {
          text: "OK",
          role: "OK",
          cssClass: "secondary",
          handler: (blah) => {
            vm.order_for = "Scheduled";
          },
        },
      ],
    });

    await alert.present();
  }
  // deliveryMode(ev) {
  //   var vm = this;
  //   var obj: any;
  //   obj = JSON.stringify({ order_delivery_mode: ev["value"], address: "" }); //moment(Date.now()).format( "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")
  //   if (ev["value"] == "Delivery") {
  //     this.presentModal("change_addresses");
  //     if (vm.order_option == 'Delivery') {
  //       vm.subject_service.getTotalPayableAmount(vm.total_with_delivery_charges)
  //     }
  //     else {
  //       vm.subject_service.getTotalPayableAmount(vm.total_amount)

  //     }
  //   }
  //   if (ev["value"] == "Pickup") {
  //     if (vm.order_option == 'Delivery') {
  //       vm.subject_service.getTotalPayableAmount(vm.total_with_delivery_charges)
  //     }
  //     else {
  //       vm.subject_service.getTotalPayableAmount(vm.total_amount)

  //     }
  //   }

  //   localStorage.setItem("order_fulfilment_mode", obj);
  //   vm.subject_service.getOrderMode(obj);
  //   if (vm.navigatto_check_out_clicked == true) {
  //     if (
  //       vm.order_for == "" ||
  //       vm.order_for == undefined ||
  //       vm.order_for == null ||
  //       !vm.order_for
  //     ) {
  //       vm.error_message = "Please select the order_for  option";
  //       vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
  //         vm.error_message
  //       );
  //     } else if (vm.order_for == "ASAP") {
  //       vm.error_message = "";
  //       vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
  //         vm.error_message
  //       );
  //     } else if (
  //       vm.order_for == "Scheduled" &&
  //       !localStorage.getItem("timing_of_fulfilment")
  //     ) {
  //       vm.error_message = "Enter the Schedule timing";
  //       vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
  //         vm.error_message
  //       );
  //     } else {
  //       vm.error_message = "";
  //       vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
  //         vm.error_message
  //       );
  //     }
  //   }
  // }
  async proceedToCheckOut() {

    if (JSON.parse(localStorage.getItem("cart_response")) != null) {
      var Olength = 0;
      length = 0;
      var menu = JSON.parse(localStorage.getItem("menu"));
      var cart = JSON.parse(localStorage.getItem("cart_response"));
      cart.forEach(function (cart) {
       
        var a = menu.filter(x => x.itemName == cart.item_name)
        a.forEach(function (a) {
          if (a.categoryName == "BREAKFAST" || a.categoryName == "SNACKS" || a.categoryName == "DESSERTS" || a.categoryName == "BEVERAGES") {

            if (a.categoryName == "BREAKFAST") {
              Olength = Olength + 1;
            }
            length = length + 1;

          }
        });

      });
      if (length == cart.length && Olength != 0) {
        this.cart_Cat = "BREAKFAST";
      }
      else if (Olength == 0 && length == cart.length) {
        this.cart_Cat = "";
      }
      else if (Olength == 0 && length != cart.length) {
        this.cart_Cat = "lunch";
      }



    }
    if (JSON.parse(localStorage.getItem("current_resturant_timings")) != null) {
      var current_period = new Date();
      if (this.cart_Cat == "BREAKFAST") {
        this.min_date = JSON.parse(localStorage.getItem("current_resturant_timings"))["startLocalTime"];
        this.max_date = environment.timing.Bf_Maxtime;


      } else if (this.cart_Cat == "lunch") {
        this.min_date = environment.timing.Lunch_mintime;
        this.max_date = JSON.parse(localStorage.getItem("current_resturant_timings"))["endLocalTime"];
      } else {
        this.min_date = JSON.parse(localStorage.getItem("current_resturant_timings"))["startLocalTime"];
        this.max_date = JSON.parse(localStorage.getItem("current_resturant_timings"))["endLocalTime"];
      }
      var current_time = this.datepipe.transform(current_period, 'HH:mm');
      var time = this.datepipe.transform(current_period, 'yyyy-MM-dd');
      var closed = JSON.parse(localStorage.getItem("current_resturant_timings"))["isClosed"];


      if (!closed) {

        
          if (current_time > this.min_date && current_time < this.max_date) {
            this.pickup_abled = true;
          }
          else {
            this.pickup_abled = false;
          }
       

      }
      else {
        this.pickup_abled = false;
      }

    }
    else {
      this.pickup_abled = false;
    }
    var location =localStorage.getItem("current_resturant_data")? JSON.parse(
      localStorage.getItem("current_resturant_data")
    )["id"]: [];
    var cart_response = localStorage.getItem("cart_response")
      ? JSON.parse(localStorage.getItem("cart_response"))
      : [];
    var user_info = JSON.parse(localStorage.getItem('user_info'));
    const navigationExtras: NavigationExtras = {
      state: {
        data_of_order: this.order_content,
        form_name: user_info ? 'login' : 'check-out',
        next: 'account',
        pickup_abled: this.pickup_abled
      },
    };
    if (window.location.href.includes('/gain-credentials/')) {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      }
      this.router.onSameUrlNavigation = 'reload';
    }
    if (cart_response && cart_response.length > 0) {
      this.navCtrl.navigateRoot([`/gain-credentials/${location}`], {
        ...navigationExtras,
      });
      this.subject_service.getProceedToCheckOutClicked('check-out')
    }
    else {
      await this.presentEmptyBasketAlert();
    }
    this.menu.close();
  }
  async proceedToCheckOut1() {
    var vm = this;
    vm.navigatto_check_out_clicked = true;
    localStorage.setItem(
      "Proceed_to_check_out_clicked",
      vm.navigatto_check_out_clicked
    );
    vm.subject_service.getProceedToCheckOutClicked(true);
    var total_amount =
      JSON.parse(localStorage.getItem("cart_subtotal")) != null
        ? JSON.parse(localStorage.getItem("cart_subtotal"))["subtotal"][
        "amount"
        ]
        : null;
    if (localStorage.getItem("selected_resturant_data")) {
      if (
        localStorage.getItem("cart_response") &&
        JSON.parse(localStorage.getItem("cart_response")).length > 0
      ) {
        const navigationExtras: NavigationExtras = {
          state: {
            data_of_order: this.order_content,
          },
        };
        this.navCtrl.navigateForward(
          ["check-out"],
          navigationExtras
        );
        this.menu.close();
      } else {
        vm.presentEmptyBasketAlert();
        return;
      }
    } else {
      vm.presentEmptyBasketAlert();
      return;
    }
  }
  async navigateToCheckOutWithoutUser() {
    var vm = this;
    vm.navigatto_check_out_clicked = true;
    localStorage.setItem(
      "Proceed_to_check_out_clicked",
      vm.navigatto_check_out_clicked
    );
    vm.subject_service.getProceedToCheckOutClicked(true);
    var total_amount =
      JSON.parse(localStorage.getItem("cart_subtotal")) != null
        ? JSON.parse(localStorage.getItem("cart_subtotal"))["subtotal"][
        "amount"
        ]
        : null;
    if (localStorage.getItem("selected_resturant_data")) {
      if (
        localStorage.getItem("cart_response") &&
        JSON.parse(localStorage.getItem("cart_response")).length > 0
      ) {
      } else {
        vm.presentEmptyBasketAlert();
        return;
      }
    } else {
      vm.presentEmptyBasketAlert();
      return;
    }
    var order_fulfilment_time = localStorage.getItem("order_fulfilment_time")
      ? JSON.parse(localStorage.getItem("order_fulfilment_time"))[
      "order_time_mode"
      ]
      : null;
    var order_fulfilment_mode = localStorage.getItem("order_fulfilment_mode")
      ? JSON.parse(localStorage.getItem("order_fulfilment_mode"))[
      "order_delivery_mode"
      ]
      : null;
    var address_of_shipment: any;
    if (order_fulfilment_mode == "Pickup") {
      address_of_shipment = localStorage.getItem("selected_resturant_data");
    } else {
      address_of_shipment = localStorage.getItem("address_of_delivery");
    }
    if (order_fulfilment_time && order_fulfilment_mode) {
      if (address_of_shipment) {
        if (order_fulfilment_time == "ASAP") {
          if (total_amount) {
            const navigationExtras: NavigationExtras = {
              state: {
                data_of_order: this.order_content,
              },
            };
            if (localStorage.getItem("selected_resturant_data")) {
              var min_delivery_amont = parseFloat(
                JSON.parse(localStorage.getItem("selected_resturant_data"))[
                "minimumDelivery"
                ]
              );
              var amount_in_basket = JSON.parse(
                localStorage.getItem("cart_subtotal")
              );
              var t =
                (parseFloat(amount_in_basket["subtotal"]["amount"]) +
                  parseFloat(amount_in_basket["tax"]["amount"]))
                ;
              if (amount_in_basket && vm.delivery_charges) {
                debugger;
                // vm.subtotal_value = parseFloat(vm.sub_total_data.subtotal.amount);
                // vm.tax_amont = vm.subtotal_value * (1 + 0.0825) * (1 + 0.10) - vm.subtotal_value;
                // //parseFloat(vm.sub_total_data.tax.amount)
                // vm.total_amount =
                //   parseFloat(vm.sub_total_data.subtotal.amount) +
                //   vm.tax_amont;

                vm.subtotal_value = parseFloat(vm.sub_total_data.subtotal.amount);
    
                // Calculate tax at 18.25% and round it to two decimal places
                vm.tax_amont = Math.round(vm.subtotal_value * 0.1825 * 100) / 100;
                
                // Calculate the total amount by adding tax to the subtotal
                vm.total_amount = vm.subtotal_value + vm.tax_amont;


                vm.total_with_delivery_charges =
                  (vm.total_amount + parseFloat(vm.delivery_charges));
                var total_with_delivery_charges: any;
                total_with_delivery_charges = vm.total_with_delivery_charges
              }
              var total_amount_obtained = t;
              if (vm.order_option == "Delivery") {
                if (min_delivery_amont <= total_with_delivery_charges) {
                  if ((await vm.checkOpeningHours("-5")) == "open") {
                    this.navCtrl.navigateForward(
                      ["check-out"],
                      navigationExtras
                    );
                    this.menu.close();
                  } else {
                    vm.presentAlertofClose();
                  }
                } else {
                  this.menu.close();

                  vm.presentMinimumDeliveryAlert(min_delivery_amont);
                }
              } else {
                if (total_amount_obtained) {
                  if ((await vm.checkOpeningHours("-5")) == "open") {
                    this.navCtrl.navigateForward(
                      ["check-out"],
                      navigationExtras
                    );
                    this.menu.close();
                  } else {
                    vm.presentAlertofClose();
                  }
                } else {
                  this.menu.close();
                  vm.presentEmptyBasketAlert();
                }
              }
            } else {
            }
          } else {
            vm.presentEmptyBasketAlert();
          }
        } else if (order_fulfilment_time == "Scheduled") {
          if (localStorage.getItem("timing_of_fulfilment")) {
            if (total_amount) {
              const navigationExtras: NavigationExtras = {
                state: {
                  data_of_order: this.order_content,
                },
              };
              if (localStorage.getItem("selected_resturant_data")) {
                var min_delivery_amont = parseFloat(
                  JSON.parse(localStorage.getItem("selected_resturant_data"))[
                  "minimumDelivery"
                  ]
                );
                var amount_in_basket: any
                amount_in_basket = JSON.parse(
                  localStorage.getItem("cart_subtotal")
                );

                var t =
                  (parseFloat(amount_in_basket["subtotal"]["amount"]) +
                    parseFloat(amount_in_basket["tax"]["amount"]))
                  ;
                var total_amount_obtained = t;
                var total_with_delivery_charges: any;
                if (vm.delivery_charges) {
                  total_with_delivery_charges =
                    (parseFloat(amount_in_basket["subtotal"]["amount"]) +
                      parseFloat(amount_in_basket["tax"]["amount"]) +
                      parseFloat(vm.delivery_charges))
                    ;
                }
                var total_amount_obtained = t;
                if (vm.order_option == "Delivery") {
                  if (min_delivery_amont <= total_with_delivery_charges) {
                    if ((await vm.checkOpeningHours("-5")) == "open") {
                      this.navCtrl.navigateForward(
                        ["check-out"],
                        navigationExtras
                      );
                      this.menu.close();
                    } else {
                      vm.presentAlertofClose();
                    }
                  } else {
                    this.menu.close();
                    vm.presentMinimumDeliveryAlert(min_delivery_amont);
                  }
                } else {
                  if (total_amount_obtained) {
                    if ((await vm.checkOpeningHours("-5")) == "open") {
                      this.navCtrl.navigateForward(
                        ["check-out"],
                        navigationExtras
                      );
                      this.menu.close();
                    } else {
                      vm.presentAlertofClose();
                    }
                  } else {
                    this.menu.close();
                    vm.presentEmptyBasketAlert();
                  }
                }
              } else {
              }
            } else {
              vm.presentEmptyBasketAlert();
            }
          } else {
            vm.error_message = "Time of schedule should be selected first";
            vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
              vm.error_message
            );
          }
        }
      } else {
        if (order_fulfilment_time == "ASAP") {
          vm.error_message = "Address is not entered";
          vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
            vm.error_message
          );
        }
        if (order_fulfilment_time == "Scheduled") {
          if (localStorage.getItem("timing_of_fulfilment")) {
            vm.error_message = "Address is not entered";
            vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
              vm.error_message
            );
          } else {
            vm.error_message = "Address and scheduled time is not enttered";
            vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
              vm.error_message
            );
          }
        }
      }
    } else {
      this.error_message = "Please select time or delivery mode or both";
      vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
        vm.error_message
      );
    }
  }
  async navigateToCheckOut() {
    var vm = this;
    vm.navigatto_check_out_clicked = true;
    localStorage.setItem(
      "Proceed_to_check_out_clicked",
      vm.navigatto_check_out_clicked
    );
    vm.subject_service.getProceedToCheckOutClicked(true);
    var user_info = JSON.parse(localStorage.getItem("user_info"));
    var total_amount =
      JSON.parse(localStorage.getItem("cart_subtotal")) != null
        ? JSON.parse(localStorage.getItem("cart_subtotal"))["subtotal"][
        "amount"
        ]
        : null;
    if (user_info) {
      if (localStorage.getItem("selected_resturant_data")) {
        if (
          localStorage.getItem("cart_response") &&
          JSON.parse(localStorage.getItem("cart_response")).length > 0
        ) {
        } else {
          vm.presentEmptyBasketAlert();
          return;
        }
      } else {
        vm.presentEmptyBasketAlert();

        return;
      }
      var order_fulfilment_time = localStorage.getItem("order_fulfilment_time")
        ? JSON.parse(localStorage.getItem("order_fulfilment_time"))[
        "order_time_mode"
        ]
        : null;
      var order_fulfilment_mode = localStorage.getItem("order_fulfilment_mode")
        ? JSON.parse(localStorage.getItem("order_fulfilment_mode"))[
        "order_delivery_mode"
        ]
        : null;
      var address_of_shipment: any;
      if (order_fulfilment_mode == "Pickup") {
        address_of_shipment = localStorage.getItem("selected_resturant_data");
      } else {
        address_of_shipment = localStorage.getItem("address_of_delivery");
      }
      if (order_fulfilment_time && order_fulfilment_mode) {
        if (address_of_shipment) {
          if (order_fulfilment_time == "ASAP") {
            if (total_amount) {
              const navigationExtras: NavigationExtras = {
                state: {
                  data_of_order: this.order_content,
                },
              };
              if (localStorage.getItem("selected_resturant_data")) {
                var min_delivery_amont = parseFloat(
                  JSON.parse(localStorage.getItem("selected_resturant_data"))[
                  "minimumDelivery"
                  ]
                );
                var amount_in_basket = JSON.parse(
                  localStorage.getItem("cart_subtotal")
                );
                var t =
                  (parseFloat(amount_in_basket["subtotal"]["amount"]) +
                    parseFloat(amount_in_basket["tax"]["amount"]))
                  ;
                var total_with_delivery_charges: any;
                total_with_delivery_charges = (parseFloat(amount_in_basket["subtotal"]["amount"]) +
                  parseFloat(amount_in_basket["tax"]["amount"]) +
                  parseFloat(vm.delivery_charges))
                  ;

                var total_amount_obtained = t;
                if (vm.order_option == "Delivery") {
                  if (min_delivery_amont <= total_with_delivery_charges) {
                    if ((await vm.checkOpeningHours("-5")) == "open") {
                      this.navCtrl.navigateForward(
                        ["check-out"],
                        navigationExtras
                      );
                      this.menu.close();
                    } else {
                      vm.presentAlertofClose();
                    }
                  } else {
                    this.menu.close();

                    vm.presentMinimumDeliveryAlert(min_delivery_amont);
                  }
                } else {
                  if (total_amount_obtained) {
                    if ((await vm.checkOpeningHours("-5")) == "open") {
                      this.navCtrl.navigateForward(
                        ["check-out"],
                        navigationExtras
                      );
                      this.menu.close();
                    } else {
                      vm.presentAlertofClose();
                    }
                  } else {
                    this.menu.close();

                    vm.presentEmptyBasketAlert();
                  }
                }
              } else {
              }
            } else {
              vm.presentEmptyBasketAlert();
            }
          } else if (order_fulfilment_time == "Scheduled") {
            if (localStorage.getItem("timing_of_fulfilment")) {
              if (total_amount) {
                const navigationExtras: NavigationExtras = {
                  state: {
                    data_of_order: this.order_content,
                  },
                };
                if (localStorage.getItem("selected_resturant_data")) {

                  var min_delivery_amont = parseFloat(
                    JSON.parse(localStorage.getItem("selected_resturant_data"))[
                    "minimumDelivery"
                    ]
                  );
                  var amount_in_basket = JSON.parse(
                    localStorage.getItem("cart_subtotal")
                  );
                  var t =
                    (parseFloat(amount_in_basket["subtotal"]["amount"]) +
                      parseFloat(amount_in_basket["tax"]["amount"]))
                    ;
                  var total_amount_obtained = t;
                  var total_with_delivery_charges: any
                  total_with_delivery_charges = parseFloat(amount_in_basket["subtotal"]["amount"]) +
                    parseFloat(amount_in_basket["tax"]["amount"]) +
                    parseFloat(vm.delivery_charges)
                    ;
                  var total_amount_obtained = t;
                  if (vm.order_option == "Delivery") {
                    if (min_delivery_amont <= total_with_delivery_charges) {
                      if ((await vm.checkOpeningHours("-5")) == "open") {
                        this.navCtrl.navigateForward(
                          ["check-out"],
                          navigationExtras
                        );
                        this.menu.close();
                      } else {
                        vm.presentAlertofClose();
                      }
                    } else {
                      this.menu.close();

                      vm.presentMinimumDeliveryAlert(min_delivery_amont);
                    }
                  } else {
                    if (total_amount_obtained) {
                      if ((await vm.checkOpeningHours("-5")) == "open") {
                        this.navCtrl.navigateForward(
                          ["check-out"],
                          navigationExtras
                        );
                        this.menu.close();
                      } else {
                        vm.presentAlertofClose();
                      }
                    } else {
                      this.menu.close();

                      vm.presentEmptyBasketAlert();
                    }
                  }
                } else {
                }
              } else {
                vm.presentEmptyBasketAlert();
              }
            } else {
              vm.error_message = "Time of schedule should be selected first";
              vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
                vm.error_message
              );
            }
          }
        } else {
          if (order_fulfilment_time == "ASAP") {
            vm.error_message = "Address is not entered";
            vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
              vm.error_message
            );
          }
          if (order_fulfilment_time == "Scheduled") {
            if (localStorage.getItem("timing_of_fulfilment")) {
              vm.error_message = "Address is not entered";
              vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
                vm.error_message
              );
            } else {
              vm.error_message = "Address and scheduled time is not enttered";
              vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
                vm.error_message
              );
            }
          }
        }
      } else {
        this.error_message = "Please select time or delivery mode or both";
        vm.subject_service.getErrorMessageOnProceedToCheckOutClicked(
          vm.error_message
        );
      }
    } else {
      await vm.presentAlert();
    }
  }
  // async startGroupOrder(data_entered?) {
  //   const vm = this;
  //   const modal = await this.modalController.create({
  //     component: GroupOrderComponent,
  //     cssClass: "my-custom-class",
  //     componentProps: { data_entered: vm.group_order_data },
  //   });
  //   modal.onDidDismiss().then(
  //     (data) => {

  //       if (data) {
  //         if (data.data) {
  //           vm.group_order_data = "";
  //           vm.group_order_data = data.data.data;
  //           this.group_order_date = moment(vm.group_order_data.date).format(
  //             "DD-MM-YYYY"
  //           );
  //           this.group_order_time = moment(vm.group_order_data.time).format(
  //             "h:mm:ss"
  //           );
  //         }
  //       }
  //     },
  //     (error) => {
  //     }
  //   );
  //   return await modal.present();
  // }
  tabNameChanged(ev) {
    if (ev.index == 0) {
    }
    if (ev.index == 1) {
      this.user_info_from_local = localStorage.getItem("user_info");
    }
  }
  getDataAfterPayment(ev) {
    this.data_after_payment.emit(ev);
  }
}
