import { Injectable } from "@angular/core";
import * as square from "square-connect";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class SquareUtilService {
  public SquareConnect: any;
  public defaultClient: any;
  public oauth2: any;
  public catalogApi:any;
  public locationApi:any;
  public orderApi:any;
  public paymentApi:any;
  public loyaltyApi:any;
  constructor() {
    // this.SquareConnect = require("square-connect");
    // this.defaultClient = this.SquareConnect.ApiClient.instance;
    // this.oauth2 = this.defaultClient.authentications["oauth2"];
    // this.oauth2.accessToken = environment.square_keys.squareAccessToken;
    // this.defaultClient.basePath = environment.square_keys.path;
    // this.catalogApi = this.SquareConnect.CatalogApi();
    // this.locationApi = this.SquareConnect.LocationsApi();
    // this.orderApi = this.SquareConnect.OrdersApi();
    // this.paymentApi = this.SquareConnect.PaymentsApi();
    // this.loyaltyApi = this.SquareConnect.LoyaltyApi();
  }
}
