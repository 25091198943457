import { Directive, HostListener, ComponentRef, Input, ElementRef } from '@angular/core';
import { Overlay, OverlayRef, OverlayPositionBuilder } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { TooltipComponent } from '@angular/material/tooltip';
//import { AwaesometooltipComponent } from '../component/awaesometooltip/awaesometooltip.component';
import { PopoverController } from '@ionic/angular';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective {
  @Input('appTooltip') text = '';
  private overlayRef: OverlayRef;
  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    public popoverController:PopoverController,
    private elementRef: ElementRef

  ) { }
  // tslint:disable-next-line: use-lifecycle-interface
  ngOnInit() {

    const positionStrategy = this.overlayPositionBuilder
    .flexibleConnectedTo(this.elementRef)
    .withPositions([{
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
    }]);

  // Connect position strategy
    this.overlayRef = this.overlay.create({ positionStrategy });
  }
  // xxx(){
  //   const tooltipPortal = new ComponentPortal(AwaesometooltipComponent);

  //   // Attach tooltip portal to overlay
  //   const tooltipRef: ComponentRef<AwaesometooltipComponent> = this.overlayRef.attach(tooltipPortal);

  //   // Pass content to tooltip component instance
  //   console.log(' tooltipRef.instance', tooltipRef.instance);
  //   console.log('this.text***********', this.text);
  //   tooltipRef.instance.text = this.text;
  // }
gg(){
  console.log("Hide api is called")
  this.overlayRef.detach();
}
  @HostListener('mouseenter') async mouseover($event){
    // const tooltipPortal = new ComponentPortal(AwaesometooltipComponent);

    // const tooltipRef: ComponentRef<AwaesometooltipComponent> = this.overlayRef.attach(tooltipPortal);

    // // Pass content to tooltip component instance
    // console.log(' tooltipRef.instance', tooltipRef.instance);
    // console.log('this.text***********', this.text);
    // tooltipRef.instance.text = this.text;

    // console.log("mouse entered")
 
    }
 
  
  // show() {
  //   // Create tooltip portal
  //   const tooltipPortal = new ComponentPortal(AwaesometooltipComponent);

  //   // Attach tooltip portal to overlay
  //   const tooltipRef: ComponentRef<AwaesometooltipComponent> = this.overlayRef.attach(tooltipPortal);

  //   // Pass content to tooltip component instance
  //   console.log(' tooltipRef.instance', tooltipRef.instance);
  //   console.log('this.text***********', this.text);
  //   tooltipRef.instance.text = this.text;
  // }
  @HostListener('mouseleave') mouseleave(){
    console.log("Hide api is called")
  this.overlayRef.detach();
  }
  // @HostListener('mouseout')
  // hide() {
  //   console.log("Hide api is called")
  //   this.overlayRef.detach();
  // }
}
