import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from './utility.service';
import { tap, catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResturantApiService {
  // tslint:disable-next-line: variable-name
  public base_url :any
  constructor(
    public http: HttpClient,
    public util: UtilityService
  ) { 
    this.base_url=environment.base_url;
  }

  createOrderWithoutUserInfo(body_to_be_sent){
    debugger
    if(localStorage.getItem('user_info')){
      body_to_be_sent.CustomerId=localStorage.getItem('user_info')?JSON.parse(localStorage.getItem('user_info'))['squareCustomerid']:""
    }
    if(localStorage.getItem('guest_info')){
      body_to_be_sent.CustomerId=localStorage.getItem('guest_info')?JSON.parse(localStorage.getItem('guest_info'))['squareCustomerId']:""
    }
    debugger;
    console.log("BodyToBeSent");
    console.log(body_to_be_sent);
    return this.http.post(this.base_url + '/order/CreateSquareOrder',body_to_be_sent);

  }
  getRecentOrders(): Observable<any>{
    debugger;
    var customer_id=localStorage.getItem('user_info')?JSON.parse(localStorage.getItem('user_info'))['squareCustomerid']:""
    var location_id = JSON.parse(
      localStorage.getItem("current_resturant_data")
    )["id"];
    return this.http.get(this.base_url + '/order/GetPaidOrders?customerId='+customer_id +'&locationId='+location_id);

  }
  errorService(){
    return this.http.get(this.base_url + '/Order/SearchOrderr');

  }
  searchOrder(order_to_searched){
    return this.http.post(this.base_url + '/Order/SearchOrder',order_to_searched);

  }
  placeOrder(body_to_be_sent,square_customer_id?){
    debugger
    var customer_id:any;
    if(!square_customer_id){
      customer_id=localStorage.getItem('user_info')?JSON.parse(localStorage.getItem('user_info'))['squareCustomerid']:""

    }
    if(localStorage.getItem('guest_info')){
      debugger
      customer_id=localStorage.getItem('guest_info')?JSON.parse(localStorage.getItem('guest_info'))['squareCustomerId']:""
    }
    else{
      customer_id:square_customer_id
    }
    
    body_to_be_sent['CustomerId']=customer_id
    return this.http.post(this.base_url + '/order/CreatePayment',body_to_be_sent);

  }
  contactUs(body_to_be_sent){
    return this.http.post( 'https://testservice.citadelbbq.com' + '/Home/ContactUs',body_to_be_sent);

  }
  createOrderApi(body_to_be_sent,customer_id?){
    debugger
    var customer_id:any
    if(customer_id){
      customer_id=customer_id
    }
    else{
      debugger
      customer_id=localStorage.getItem('user_info')?JSON.parse(localStorage.getItem('user_info'))['squareCustomerid']:""
   
    }

    body_to_be_sent.CustomerId=customer_id
    return this.http.post(this.base_url + '/order/CreateOrder',body_to_be_sent);

  }

  getAllResturants(): Observable<any> {
    
    return this.http.get(this.base_url + '/Location/GetAll');
  }

  getResturantById(id): Observable<any> {
    return this.http.get(this.base_url + '/Location/GetById/?id=' + id);
  }
  getCard(): Observable<any>{
    var customer_id=localStorage.getItem('user_info')?JSON.parse(localStorage.getItem('user_info'))['squareCustomerid']:""
    return this.http.get(this.base_url + '/order/GetCards?customerId='+customer_id );

  }
  createCard(body_of_card): Observable<any>{
    var customer_id=localStorage.getItem('user_info')?JSON.parse(localStorage.getItem('user_info'))['squareCustomerid']:""
body_of_card.CustomerId=customer_id
    return this.http.post(this.base_url + '/order/CreateCustomerCard',body_of_card);

  }
  getCompleteMenu(): Observable<any>{
    return this.http.get(this.base_url + '/Catalog/GetItems');
  }
  getCategoriesOfEachResturant(resturant_id?){
    // return this.http.get(this.base_url + '/Items/GetAllMenuCategoriesByRestaurantId?RestaurantId=' + resturant_id);
    return this.http.get(this.base_url + '/Catalog/GetCategories');

  }
  getCompleteMenuByLocation(location_id){

    return this.http.get(this.base_url+'/Catalog/GetItemsByLocationId?id='+location_id)
  }
  GetCategoriesbyLoaction(location_id){
    
        return this.http.get(this.base_url+'/Catalog/GetCategoriesbyLoaction?locationid='+location_id)
      }
  getItemsForEachCategory(category_id){
    return this.http.get(this.base_url + '/Catalog/GetItemsByCategoryId?id=' + category_id);
  }
  getCustomisationTypes(){
    return this.http.get(this.base_url + '/Items/GetAllCustomizationTypes');

  }
  getItemById(item_id){
    return this.http.get(this.base_url + '/Catalog/GetItemById?id='+item_id);

  }
  saveFavoriteOrders(body_of_favorite): Observable<any>{
    return this.http.post(this.base_url + '/Order/SaveFavorite',body_of_favorite);

  }
  getFavorite(): Observable<any>{
    return this.http.get(this.base_url+'/order/GetFavorite')
  }
  getLoyaltyPrograms(): Observable<any>{
    return this.http.get(this.base_url+'/order/ListLoyaltyPrograms')
  }  
  getLoyaltyAccount(customer_id):Observable<any>{
    return this.http.get(this.base_url+'/order/RetrieveLoyaltyAccount?customerId='+customer_id);
  }
}
