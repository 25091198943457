import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-item-count",
  templateUrl: "./item-count.component.html",
  styleUrls: ["./item-count.component.scss"],
})
export class ItemCountComponent implements OnInit {
  @Input() count: any;
  @Input() count_for?:any;
  // tslint:disable-next-line: variable-name
  @Output() count_changed = new EventEmitter<any>();
  constructor() {
  
  }
  
  addedValue() {
    var val = JSON.parse(this.count);
    if (val <100) {
      this.count = (val + 1).toString();
      this.count_changed.emit(this.count);
    }
  }
  subtractedValue() {
    // tslint:disable-next-line: radix
    const val = JSON.parse(this.count);
    if(!this.count_for){
      if (val > 1 ) {
        this.count = (val - 1).toString();
        this.count_changed.emit(this.count);
      }
    }
    else{
      if (val > 0) {
        this.count = (val - 1).toString();
        this.count_changed.emit(this.count);
      }
    }
  
  }
  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit() {
  }
  ngOnInit() {
  }
}
