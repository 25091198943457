import { NgModule } from '@angular/core';

import { ParallexHeaderDirective } from './parallex-header.directive';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TooltipDirective } from './tooltip.directive';
import { PhoneDirective } from './Validator/phone.directive';
import { PhoneNumberDirective } from './Validator/phone-number.directive';
import { EvokeButtonDirective } from './evoke-button.directive';
import { FontweightChangeDirective } from './fontweight-change.directive';



@NgModule({
  imports:      [ CommonModule,

    FormsModule,
    IonicModule, ],

    declarations: [ParallexHeaderDirective, FontweightChangeDirective,TooltipDirective, PhoneDirective, PhoneNumberDirective, EvokeButtonDirective, FontweightChangeDirective],
    exports: [ParallexHeaderDirective, TooltipDirective,EvokeButtonDirective,FontweightChangeDirective]
})
export class DirectiveModule{}
