import { Component, ViewChild, ElementRef, HostBinding, HostListener } from '@angular/core';

import { Platform, MenuController, NavController, ModalController, LoadingController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SubjectCollectionService } from './service/subject-collection.service';
import { JsMapService } from './service/js-map.service';
import { NativeMapService } from './service/native-map.service';
import { GoogleMaps } from '@ionic-native/google-maps';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { RegisterComponent } from './component/register/register.component';
import { MatDialog } from '@angular/material/dialog';
import { OverlayContainer } from '@angular/cdk/overlay';
import { RegistrationFormComponent } from './component/registration-form/registration-form.component';
import * as firebase from 'firebase/app';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UtilityService } from './service/utility.service';
import { AuthService } from './service/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public user_info: any;
  constructor(
    public auth: AuthService,
    public util: UtilityService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public menuCtrl: MenuController,
    public subject_service: SubjectCollectionService,
    public geolocation: Geolocation,
    public dialog: MatDialog,
    public navCtrl: NavController,
    public overlayContainer: OverlayContainer,
    public modalController: ModalController,
    public loc :Location,
    public router :Router,
    public loadingCtrl :LoadingController


  ) {

if (localStorage.getItem("user_info")) {
  this.user_info = JSON.parse(localStorage.getItem("user_info"));
  if(!this.user_info.squareLoyaltyId)
  {

     this.logout();
  }
} else {
  this.user_info = null;
}
    window.onpopstate = function(event) {
    }
    this.router.events.pipe(
      filter(e =>e instanceof NavigationStart),
      filter((e: NavigationStart)=>e.navigationTrigger == "popstate")
  )
    .subscribe((x: NavigationStart) => {
      this.router.getCurrentNavigation().extras.state={...x.restoredState, navigationId:x.id } ;
    });

    // this.subject_service.getThemeToBeApplied('teal-light-theme-css')
this.payment_form = false;
this.initializeApp();
this.text_on_slider = 'Dark';
    
this.loc.subscribe((data)=>{
})
  }
  public map: any;
  location: { latitude: number; longitude: number; };
  @ViewChild('map') mapElement: ElementRef;
  public paymentForm: any;
  public text_on_slider: any;
  public payment_form: any;
  public order_option: any;
  //   findUserLocation() {
  //     var options = {
  //       enableHighAccuracy: true,
  //       timeout: 25000
  //     };


  //     this.geolocation.getCurrentPosition()
  //     .then((position) => {
  //       this.location = {
  //         latitude: position.coords.latitude,
  //         longitude: position.coords.longitude
  //       };

  //     }).catch((error) => {
  //     });
  //   }
  @HostBinding('class') componentCssClass;
  init(location, element) {
    this.map.init(location, element);
  }
  viewChanged(){
  }
  async onPageActivated(){
    const topLoader = await this.loadingCtrl.getTop();
    if (topLoader && topLoader.getAttribute('dismissOnPageChange') === 'true') {
      await topLoader.dismiss();
    }
  }
  onSetTheme(theme) {
    this.overlayContainer.getContainerElement().classList.add(theme);
    this.componentCssClass = theme;
  }
  getTheme(ev) {
    if (ev.checked == true) {
this.text_on_slider = 'Light';
this.subject_service.getThemeToBeApplied('teal-theme-css');

    }
    if (ev.checked == false) {
 this.text_on_slider = 'Dark';

 this.subject_service.getThemeToBeApplied('teal-light-theme-css');

    }
  }
  async ngOnInit() {
    const vm = this;
    firebase.initializeApp(environment.firebaseConfig);
    this.subject_service.subscibeToThemeApplied().subscribe((data) => {
      vm.onSetTheme(data);
    });
  }
  openCart() {
    this.menuCtrl.open('cart');
    this.payment_form = true;
  }
  initializeApp() {
    const vm = this;
    this.platform.ready().then(() => {
      if (vm.platform.is('android') || vm.platform.is('ios')) {

      }
      this.platform.backButton.subscribe((data)=>{
      })
      this.statusBar.backgroundColorByHexString('#E80510');
      this.splashScreen.hide();
    });
    this.overlayContainer.getContainerElement().classList.add('teal-theme-css');
  }
  toggleMenu() {
    this.menuCtrl.toggle();
  }
  loginButtonPressed() {
    this.subject_service.getFormModalTypeToBeOpened('login');
    this.toggleMenu();
  }
  moveToMenuPage() {
    this.toggleMenu();
    this.navCtrl.navigateForward('menu-shown');
  }
  moveToFindAStorePage() {
    this.toggleMenu();
    this.navCtrl.navigateForward('find-a-store');
  }
  registerButtonPressed() {
    this.subject_service.getFormModalTypeToBeOpened('register');
    this.toggleMenu();
  }
  async logout() {
    const vm = this;
   await vm.util.presentLoading();
    await this.auth.logOut().subscribe((data) => {
      for (var key in localStorage) {
        if(key!="resturant_timings")
        {
         localStorage.removeItem(key);
        }
     }
      
      // localStorage.clear();
      this.user_info = 'null';
      vm.util.dismissPopover();
      vm.util.dismissLoader();
        (error) => {
      vm.util.dismissLoader();
        };
    });
  }
  regiaterButton() {
    this.toggleMenu();

    const dialogRef = this.dialog.open(RegisterComponent, {
      // width: '297px',
      data: { form_name: 'login' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Sign up') {
        const dialogRef = this.dialog.open(RegisterComponent, {
          // width: '297px',
          data: { form_name: 'register' }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result == 'login') {
            const dialogRef = this.dialog.open(RegisterComponent, {
              // width: '297px',
              data: { form_name: 'login' }
            });
            dialogRef.afterClosed().subscribe(result => {
              if (result == 'forgot_password') {
                const dialogRef = this.dialog.open(RegisterComponent, {
                  // width: '297px',
                  data: { form_name: 'forgot_password' }
                });
              }
            });
          }
        });
      }
      else if (result == 'forgot_password') {
        const dialogRef = this.dialog.open(RegisterComponent, {
          // width: '297px',
          data: { form_name: 'forgot_password' }
        });
      }
      else if (result == 'login') {
        const dialogRef = this.dialog.open(RegisterComponent, {
          // width: '297px',
          data: { form_name: 'login' }
        });
      }
      // this.animal = result;
    });


  }
  moveToCateringPage(){
    this.navCtrl.navigateForward('catering');
    this.toggleMenu();


  }
       // TODO: paste code from step 1.1.5
     // onGetCardNonce is triggered when the "Pay $1.00" button is clicked

     async loginOrRegistrationButtonClicked(form_name) {
       this.toggleMenu();
       const modal = await this.modalController.create({
        component: RegistrationFormComponent,
        cssClass: "my-modal",
        componentProps: {
          form_name
        },
     
      });
       return await modal.present();
    }
}
