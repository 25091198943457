import { Injectable } from '@angular/core';
import { AlertController, LoadingController, PopoverController } from '@ionic/angular';
import { UserInfoComponent } from '../component/user-info/user-info.component';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RegisterSignInComponent } from '../component/register-sign-in/register-sign-in.component';



@Injectable({
  providedIn: 'root'
})
export class UtilityService {
public isLoading:any;
  constructor(
    public loadingController: LoadingController,
    public popoverController: PopoverController,
    private _snackBar: MatSnackBar,
    public alertController: AlertController
  ) { 
    this.isLoading=false;
  }
  log(message?,data?){
if(environment.production==false){
  if(message && data){

  }
  if(message){
  }
  if(!data && !message){
  }
}
  }
  async presentLoading() {
    return new Promise(async (resolve,reject)=>{
      var vm=this;
      const loading = await this.loadingController.create({
        cssClass: 'waiting-loader',
        spinner: 'bubbles'
        // message: 'Please wait...',
  
      });
       loading.present().then((data)=>{
  vm.isLoading=true;
  resolve("success********")
      },(error)=>{
        reject(error)
      })
  
    })
  }
  async dismissLoader(load?){
    var vm=this;
    this.isLoading=true;
    if(this.isLoading==true){
      this.loadingController.dismiss().then(()=>{
        vm.isLoading=false;
      }).catch(() => {});
    }

  }
  async presentPopover1(ev: any,items,current_resturant){
    const popover = await this.popoverController.create({
      component: RegisterSignInComponent,
      cssClass: '',
      event: ev,
      componentProps:{items:items,current_resturant:current_resturant},
      translucent: true
    });
    return await popover.present();
  }
  async presentPopover(ev: any,data,items,current_resturant) {
    const popover = await this.popoverController.create({
      component: UserInfoComponent,
      cssClass: 'user-info-css',
      event: ev,
      componentProps:{data:data,items:items,current_resturant:current_resturant},
      translucent: true
    });
    return await popover.present();
  }
  dismissPopover(){
    this.popoverController.dismiss();
  }
  showErrorMessage(error){
    if(typeof(error)=="object"){
      var message:any="";
      for(var i=0;i<Object.keys(error).length;i++){
        message=message+`<li>${error[Object.keys(error)[i]]}</li>`
      }
      this.openAlertMessage(message)
    }
    else if(typeof(error)=="string"){
      this.openAlertMessage(error)
    }
  }
  async openAlertMessage(message) {
    var vm = this;
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "Following error has occurred",
      message: message,
      buttons: [
        {
          text: "OK",
          role: "OK",
          cssClass: "secondary",
          handler: (blah) => {},
        },
      ],
    });
    await alert.present();
  }
}
