import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ModalController } from "@ionic/angular";
import * as moment from "moment";
import { AlertController } from '@ionic/angular';


import { RewardsPage } from "src/app/page/rewards/rewards.page";
import { AuthService } from 'src/app/service/auth.service';
import { ResturantApiService } from "src/app/service/resturant-api.service";
import { SubjectCollectionService } from "src/app/service/subject-collection.service";
import { UtilityService } from "src/app/service/utility.service";
import { environment } from "src/environments/environment";

declare var SqPaymentForm: any;
declare var Square: any;
declare global {
  interface Window { dataLayer: any[]; }
}
@Component({
  selector: "app-pay-square",
  templateUrl: "./pay-square.component.html",
  styleUrls: ["./pay-square.component.scss"],
})
export class PaySquareComponent implements OnInit {
  public button :any ;
  public paymentForm: any;
  public rewards?:any = [];
  public Payments:any;
  public card1: any;
  public user_info: any;
  public account_info:any;
  public reward_tier_id: any;
  @Output() payment_status = new EventEmitter<any>();
  @Output() back_to_menu = new EventEmitter<any>();
  constructor(
    
    private alertController: AlertController,
    public util: UtilityService,
    public resturant_service: ResturantApiService,
    public subject_service: SubjectCollectionService,
    public auth:AuthService,
    public modalController: ModalController,
   
  ) {
  
  } 
  
  public loadExternalScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  async ngOnInit() {
    
    this.loadExternalScript(environment.payment_form.src);
   

    var vm = this;

    if (localStorage.getItem("user_info"))
    {
    await this.resturant_service.getLoyaltyPrograms().subscribe(
      (data)=>{
        vm.rewards.push(...data);
      }
    )
    this.user_info=JSON.parse(localStorage.getItem("user_info"));
    var customer_id=this.user_info.squareCustomerid;
    
    this.getLoyaltyAccount(customer_id);
    }

    await this.newCard();
    // vm.Payments.build();
  }
  getLoyaltyAccount(customer_id)
  {
     this.resturant_service.getLoyaltyAccount(customer_id).subscribe(
       (data)=>{
         if(data)
         {
         this.account_info=data;
         }
       }
     )
  }
  async onGetCardNonce(event) {
   debugger
    var vm = this;
    var amount:any=JSON.parse(localStorage.getItem("cart_subtotal"));
    if(amount.subtotal.amount<250)
    {
      {
        const alert = await this.alertController.create({
          cssClass: "my-custom-class",
          header: "Checkout failed!!",
          message:
          "A minimum order of $250 is required for delivery. For pick-up, please call our catering managers directly at 832.640.5236.",
            // "We are open. Please visit our restaurant on the corner of Dryden and Main to place your order.",
          //"Please schedule pick-up during business hours - Mon to Fri - "+time24To12(this.min_date)+" - "+time24To12(this.max_date)+"",
          buttons: [
            {
              text: "OK",
              role: "OK",
              cssClass: "secondary",
              handler: (blah) => {
              },
            },
          ],
        });
        await alert.present();
            return false;
      }
    }
     
    if (localStorage.getItem("user_info")) {
      vm.user_info = JSON.parse(localStorage.getItem("user_info"));
      var customer_id=this.user_info.squareCustomerid;
      this.getLoyaltyAccount(customer_id);
      var min_value=this.rewards[0].reward_tiers[0].points;
      for(var i=1;i<this.rewards[0].reward_tiers.length;i++)
      {
        if(this.rewards[0].reward_tiers[i].points < min_value)
        {
           min_value=this.rewards[0].reward_tiers[i].points
        }
      }
    } else {
      vm.user_info = null;
    }
    if(vm.user_info)
    {
      if(this.account_info.balance < min_value)
      {
        debugger
          // Don't submit the form until SqPaymentForm returns with a nonce
       event.preventDefault();
       // Request a nonce from the SqPaymentForm object
      //  this.paymentForm.requestCardNonce();
      const result=  await this.card1.tokenize();
      if (result.status =='OK')
      {
      var abc = JSON.stringify(result,null,2);
      var  details = JSON.parse(abc);
      await vm.util.presentLoading();
      await this.payment_nonce(details.token)   
      }else{

      }
      }
      else{
        const modal = await this.modalController.create({
          component: RewardsPage,
          cssClass: "my-modal",
          componentProps: {
                       rewards_programs:this.rewards,
                       account_info:this.account_info
                          }
                  });
        modal.onDidDismiss().then(async(collected_value)=>
         {
               if(collected_value &&  collected_value.data && collected_value.data.reward_tier_id)
                  {
                     this.reward_tier_id=collected_value.data.reward_tier_id;
                  }
              if(collected_value && collected_value.data && collected_value.data.do_payment)
                {
                  debugger
                   // Don't submit the form until SqPaymentForm returns with a nonce
                  event.preventDefault();
                 // Request a nonce from the SqPaymentForm object

               const result=  await this.card1.tokenize();
               if (result.status =='OK')
               {
               var abc = JSON.stringify(result,null,2);
               var  details = JSON.parse(abc);
               await vm.util.presentLoading();
               await this.payment_nonce(details.token)   
               }else{

               }
               
                
          }
         });
          return await modal.present();
      }
         
    }
  else{
    debugger
       // Don't submit the form until SqPaymentForm returns with a nonce
       event.preventDefault();
       // Request a nonce from the SqPaymentForm object
      //  this.paymentForm.requestCardNonce();
      const result=  await this.card1.tokenize();
      if (result.status =='OK')
      {
      var abc = JSON.stringify(result,null,2);
      var  details = JSON.parse(abc);
      await vm.util.presentLoading();
      await this.payment_nonce(details.token)   
      }else{

      }
  }
   
  }
  openMenu() {
    this.back_to_menu.emit(true);
    localStorage.removeItem('guest_user_form_value')
    localStorage.removeItem('guest_info')
    localStorage.removeItem('date_entered')
    localStorage.removeItem('time_chosen_for_pick_up')



    // this.subject_service.getheaderButtonClicked('menu')
  }
  async payment_nonce(nonce)
  {
    debugger;
    var vm = this;
    var guest_user_result:any;
                        if(localStorage.getItem('guest_user_form_value')){
                          guest_user_result=await vm.guestUserInfo()
                        }
                        else{
                          guest_user_result="success"
                        }
                        if(  guest_user_result="success"){
                          var fulfilment_result = await vm.prepareFulfilment_ready_to_hit();
                          if (fulfilment_result == "error") {
                            
                          } else {
                            var location_id = localStorage.getItem("current_resturant_data")
                              ? JSON.parse(localStorage.getItem("current_resturant_data"))[
                                  "id"
                                ]
                              : "";
                            var order_id = localStorage.getItem("order_id")
                              ? localStorage.getItem("order_id")
                              : "";
                            var fulfilment_data = localStorage.getItem("fulfilment_data")
                              ? JSON.parse(localStorage.getItem("fulfilment_data"))
                              : "";
                            if (location_id) {
                              if (order_id) {
            
                                if (fulfilment_data) {
                                  debugger;
                                  let data = {
                                    OrderId: order_id,
                                    LocationId: location_id,
                                    SourceId: nonce,
                                    // Amount: fulfilment_data["total"]*1.19075 / 1.1825,
                                    Amount: fulfilment_data["total"],
                                    // parseFloat(total_amount["subtotal"]["amount"]) +
                                    // parseFloat(total_amount["tax"]["amount"]),
                                  };
                                  vm.resturant_service.placeOrder(data).subscribe(
                                    async (data) => {
                                    
                                      window.dataLayer.push({
                                        event: 'Payement-Response',
                                         data: data
                                      });
                                   
                                      vm.payment_status.emit("done");
                                      localStorage.removeItem("fulfilment_data");
                                      localStorage.removeItem("order_id");
                                      localStorage.removeItem("guest_info");
                                      vm.subject_service.getcartReponse(null);
                                      vm.subject_service.getSubtotalReponse(null);
                                      vm.subject_service.getItemCount(0);
                                      vm.subject_service.getFulfilment(false);
              
                                      localStorage.removeItem("order_fulfilment_mode");
                                      localStorage.removeItem("cart_response");
                                      localStorage.removeItem("time_chosen_for_pick_up");
                                      localStorage.removeItem("date_entered");
                                      localStorage.removeItem("guest_user_form_value");
              
                                      // localStorage.removeItem("user_info");
                                      
              
                                      localStorage.removeItem("cart_subtotal");
                                      await vm.util.dismissLoader();
                                    },
                                    async (error) => {
                                      debugger;
                                      await vm.util.dismissLoader();
                                      var errorMessage = "";
                                      if (typeof error === 'object' && error.text) {
                                        const messageMatch = error.text.match(/"ErrorMessage":"(.*?)"/);
                                        if (messageMatch) {
                                            errorMessage = messageMatch[1];
                                        } else {
                                            errorMessage = error.text;
                                        }
                                    } else if (typeof error === 'string') {
                                        errorMessage = error;
                                    }
                                      vm.util.showErrorMessage(errorMessage); 
                                    }
                                  );
                                } else {
                                  await vm.util.dismissLoader();
              
                                  vm.util.showErrorMessage(
                                    "Time for pickup is not correctly selected"
                                  );
                                }
                              } else {
                                await vm.util.dismissLoader();
              
                                vm.util.showErrorMessage("Your card is empty yet");
                              }
                            } else {
                              await vm.util.dismissLoader();
              
                              vm.util.showErrorMessage("No location is present");
                            }
                          }
                        }
                       
                
  }
  async prepareFulfilment_ready_to_hit() {
    return new Promise(async (resolve, reject) => {
      var vm = this;
      var customer_id: any;
      if(localStorage.getItem('user_info')){
      customer_id=localStorage.getItem('user_info')?JSON.parse(localStorage.getItem('user_info'))['squareCustomerid']:""
      }
      if(localStorage.getItem('guest_info')){
      customer_id=localStorage.getItem('guest_info')?JSON.parse(localStorage.getItem('guest_info'))['squareCustomerId']:""
      }
     
      var date_entered :any
      date_entered= localStorage.getItem("date_entered");
      var time_chosen_for_pick_up = localStorage.getItem(
        "time_chosen_for_pick_up"
      );
      //  var guest_info_info=localStorage.getItem("guest_info")?JSON.parse(localStorage.getItem("guest_info")):""
      var user_info = localStorage.getItem("user_info")
        ? JSON.parse(localStorage.getItem("user_info"))
        : "";
      var guest_info = localStorage.getItem("guest_user_form_value")
        ? JSON.parse(localStorage.getItem("guest_user_form_value"))
        : "";
      var location_id = JSON.parse(
        localStorage.getItem("current_resturant_data")
      )["id"];
      var order_id = localStorage.getItem("order_id");
      if (date_entered) {
        if (order_id) {
          if (location_id) {
            var fulfilment_values: any;
            if ((time_chosen_for_pick_up == "schedule")) {
              fulfilment_values = {
                Type: 1,
                FulfillmentState: 0,
                ScheduleType: 1,
                PickupAt: new Date(date_entered).toISOString(),
                Note: "Optional",
                RecipientName: user_info
                  ? user_info.firstName
                  : guest_info.FirstName,
                Email: user_info ? user_info.email : guest_info.Email,
              };
            } else {
              fulfilment_values = {
                Type: 1,
                FulfillmentState: 0,
                ScheduleType: 0,
                RecipientName: user_info
                  ? user_info.firstName
                  : guest_info.FirstName,
                Email: user_info ? user_info.email : guest_info.Email,
              };
            }
            debugger;
            var p = {
              OrderId: order_id,
              CustomerId: customer_id,
              RewardTierId:this.reward_tier_id ?this.reward_tier_id : null,
              LocationId: location_id,
              // SquareLoyaltyAccountId:this.account_info ? this.account_info.squareLoyaltyAccountId:null ,
              SquareLoyaltyAccountId:localStorage.getItem('date_entered'),
              Fulfillment: { ...fulfilment_values },
              GuestInfo: {
                ContactNumber: guest_info.PhoneNumber,
                Address: guest_info.ShippedAt
              }
            };
            console.log(p);

            var guest_user_info = user_info
              ? ""
              : JSON.parse(localStorage.getItem("guest_info"));

      

            vm.resturant_service.createOrderWithoutUserInfo(p).subscribe(
              (data_2: any) => {
                // vm.util.dismissLoader();
                // vm.move_to_payment = true;
                // vm.guest_info = guest_user_info;
                localStorage.setItem("fulfilment_data", JSON.stringify(data_2));
                resolve("success");
              },
              (error) => {
                debugger
                var errorMessage = "";
                if (typeof error === 'object' && error.text) {
                  const messageMatch = error.text.match(/"ErrorMessage":"(.*?)"/);
                  if (messageMatch) {
                      errorMessage = messageMatch[1];
                  } else {
                      errorMessage = error.text;
                  }
              } else if (typeof error === 'string') {
                  errorMessage = error;
              }
                vm.util.dismissLoader();
                vm.util.showErrorMessage(errorMessage);
                //vm.util.showErrorMessage("Invalid Debit/Credit Card");
                reject("error");
                // vm.alertMessage("Error while submitting fulfilment",error)
              }
            );
          }
        } else {
         
          await vm.util.dismissLoader();

          vm.util.showErrorMessage("Your Basket is empty");

          reject("error");

          // vm.alertMessage("Order Problem","Your Basket is empty")
        }
      } else {
       
        // vm.util.dismissLoader();

        vm.util.showErrorMessage("No date is selected to place the order");
        reject("error");
      }
    });
  }
  async guestUserInfo() {
 
    var vm = this;

    return new Promise(async (resolve, reject) => {
      try{
      var guest_user_account=localStorage.getItem('guest_user_form_value')?JSON.parse(localStorage.getItem('guest_user_form_value')):""
        var data = {
          FirstName: guest_user_account.FirstName,
          LastName: guest_user_account.LastName,
          Email: guest_user_account.Email,
          PhoneNumber: guest_user_account.PhoneNumber,
          // "CaptchaToken" : "test",
          IsGuest: "true",
        };
      
        
          this.auth
        .register(data)
        .then(async (d: any) => {
          localStorage.setItem("guest_info", JSON.stringify(d));
          resolve("success");
        })
        .catch(async (error) => {
          await vm.util.dismissLoader();

          reject(error);
        });
    
  
    
    }
    catch(error){

    }
  })}
 async newCard() {
  debugger
    var vm = this;
    this.Payments = new Square.payments(environment.square_keys.squareApplicationId, "L9FC6A5SEF35Z");
    // const card1 = await payments.card();
    const darkModeCardStyle = {
      '.input-container': {
        borderColor: '#2D2D2D',
        borderRadius: '6px',
      },
      '.input-container.is-focus': {
        borderColor: '#006AFF',
      },
      '.input-container.is-error': {
        borderColor: '#ff1600',
      },
      '.message-text': {
        color: '#999999',
      },
      '.message-icon': {
        color: '#999999',
      },
      '.message-text.is-error': {
        color: '#ff1600',
      },
      '.message-icon.is-error': {
        color: '#ff1600',
      },
      input: {
        backgroundColor: '#2D2D2D',
        color: '#FFFFFF',
        fontFamily: 'helvetica neue, sans-serif',
      },
      'input::placeholder': {
        color: '#999999',
      },
      'input.is-error': {
        color: '#ff1600',
      },
    };
    this.card1 = await this.Payments.card();
    await this.card1.attach("#Payementform-container");
    let tokenResult;
    
    this.button = document.getElementById('#sq-creditcard');
  //  this.button.addEventListener('click', e => {
  //     debugger
  //     e.preventDefault();
  //     tokenResult = this.card1.tokenize();
  //   });
    // this.paymentForm = new SqPaymentForm({
    //   // Initialize the payment form elements
    //   applicationId: environment.square_keys.squareApplicationId,
    //   autoBuild: false,
    //   inputClass: "sq-input",
    //   // Customize the CSS for SqPaymentForm iframe elements

    //   inputStyles: [
    //     {
    //       fontSize: "16px",
    //       lineHeight: "24px",
    //       padding: "16px",
    //       placeholderColor: "#a0a0a0",
    //       backgroundColor: "transparent",
    //     },
    //   ],
    //   // Initialize the credit card placeholders
    //   // applePay:{
    //   //   elementId: 'applePay',
    //   // },
    //   // googlePay:{
    //   //   elementId: 'googlePay',
    //   // },
    //   cardNumber: {
    //     elementId: "sq-card-number",
    //     placeholder: "Card Number",
    //   },
    //   cvv: {
    //     elementId: "sq-cvv",
    //     placeholder: "CVV",
    //   },
    //   expirationDate: {
    //     elementId: "sq-expiration-date",
    //     placeholder: "MM/YY",
    //   },
    //   postalCode: {
    //     elementId: "sq-postal-code",
    //     placeholder: "BILLING ZIP CODE",
    //   },
    //   // SqPaymentForm callback functions
    //   callbacks: {
    //     /*
    //      * callback function: cardNonceResponseReceived
    //      * Triggered when: SqPaymentForm completes a card nonce request
    //      */

    //     cardNonceResponseReceived: async function (errors, nonce, cardData) {
    //       await vm.util.presentLoading();
    //       var err :any="";
    //       if (errors) {
           

    //     errors.forEach(function (error) {
             
    //           err = err + "<li>" + error.message + "</li><br>";
    //         });
    //         await vm.util.dismissLoader();
          
    //         vm.util.showErrorMessage(err);
    //       } else {
            
    //         var guest_user_result:any;
    //         if(localStorage.getItem('guest_user_form_value')){
    //           guest_user_result=await vm.guestUserInfo()
    //         }
    //         else{
    //           guest_user_result="success"
    //         }
    //         if(  guest_user_result="success"){
    //           var fulfilment_result = await vm.prepareFulfilment_ready_to_hit();
    //           if (fulfilment_result == "error") {
                
    //           } else {
    //             var location_id = localStorage.getItem("current_resturant_data")
    //               ? JSON.parse(localStorage.getItem("current_resturant_data"))[
    //                   "id"
    //                 ]
    //               : "";
    //             var order_id = localStorage.getItem("order_id")
    //               ? localStorage.getItem("order_id")
    //               : "";
    //             var fulfilment_data = localStorage.getItem("fulfilment_data")
    //               ? JSON.parse(localStorage.getItem("fulfilment_data"))
    //               : "";
    //             if (location_id) {
    //               if (order_id) {

    //                 if (fulfilment_data) {
    //                   let data = {
    //                     OrderId: order_id,
    //                     LocationId: location_id,
    //                     SourceId: nonce,
    //                     Amount: fulfilment_data["total"],
  
    //                     // parseFloat(total_amount["subtotal"]["amount"]) +
    //                     // parseFloat(total_amount["tax"]["amount"]),
    //                   };
    //                   vm.resturant_service.placeOrder(data).subscribe(
    //                     async (data) => {
                        
    //                       window.dataLayer.push({
    //                         event: 'Payement-Response',
    //                          data: data
    //                       });
                       
    //                       vm.payment_status.emit("done");
    //                       localStorage.removeItem("fulfilment_data");
    //                       localStorage.removeItem("order_id");
    //                       localStorage.removeItem("guest_info");
    //                       vm.subject_service.getcartReponse(null);
    //                       vm.subject_service.getSubtotalReponse(null);
    //                       vm.subject_service.getItemCount(0);
    //                       vm.subject_service.getFulfilment(false);
  
    //                       localStorage.removeItem("order_fulfilment_mode");
    //                       localStorage.removeItem("cart_response");
    //                       localStorage.removeItem("time_chosen_for_pick_up");
    //                       localStorage.removeItem("date_entered");
    //                       localStorage.removeItem("guest_user_form_value");
  
    //                       // localStorage.removeItem("user_info");
                          
  
    //                       localStorage.removeItem("cart_subtotal");
    //                       await vm.util.dismissLoader();
    //                     },
    //                     async (error) => {
    //                       await vm.util.dismissLoader();
  
    //                       vm.util.showErrorMessage(error);
    //                     }
    //                   );
    //                 } else {
    //                   await vm.util.dismissLoader();
  
    //                   vm.util.showErrorMessage(
    //                     "Time for pickup is not correctly selected"
    //                   );
    //                 }
    //               } else {
    //                 await vm.util.dismissLoader();
  
    //                 vm.util.showErrorMessage("Your card is empty yet");
    //               }
    //             } else {
    //               await vm.util.dismissLoader();
  
    //               vm.util.showErrorMessage("No location is present");
    //             }
    //           }
    //         }
           
    //       }
    //     },
    //   },
    // });
  }
}
