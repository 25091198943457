import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/internal/operators/catchError';
import {
  HttpErrorResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  constructor(
    public alertController: AlertController
  ) {}
  async presentAlert(message) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Report to Administrator',
  
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    var vm=this;
    // if (!window.navigator.onLine) {
    //   // if there is no internet, throw a HttpErrorResponse error
    //   // since an error is thrown, the function will terminate here
    //   const error = {
    //     status: 0,
    //     error: {
    //       description: 'Check Connectivity!'
    //     },
    //     statusText: 'Check Connectivity!'
    //   };
    //   return throwError(new HttpErrorResponse(error));
    // } 
 
    request = request.clone({
      setHeaders: {
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return next.handle(request).pipe(
      //  retry(1),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        if(error.status==500){
          vm.presentAlert(error.error)
        }
        return throwError(error.error);
      })
    );
  }
}
