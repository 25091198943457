import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appFontweightChange]'
})
export class FontweightChangeDirective {

  constructor(private element:ElementRef) {
    // console.log("FOnt-weight direcctive entered")
   
    }


    // @HostListener('mouseenter') onMouseEnter(){
    //   console.log("mouse enterde",this.element.nativeElement.style)
    //   this.element.nativeElement.style.fontFamily = 'Barlow-Bold';
      
    //   }
    //   @HostListener('mouseleave') onMouseLeave(){
    //   console.log("mouse left ")

    //     this.element.nativeElement.style.fontFamily = 'Barlow-Medium';
    //     }
    @HostListener('mouseenter') onMouseEnter(){
      var p=this.element.nativeElement.getElementsByClassName("para")[0]
      // console.log("mouse enterde",this.element.nativeElement.getElementsByClassName("para"))
      // this.element.nativeElement.style.position = 'absolute';
   p.style.opacity = '0.8';

      
      }
      @HostListener('mouseleave') onMouseLeave(){
      // console.log("mouse left ")
      var p=this.element.nativeElement.getElementsByClassName("para")[0]
      // this.element.nativeElement.style.position = null;
      p.style.opacity =null;
        }

}
