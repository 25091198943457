export const environment = {
  production: false,
//test
 image_url_for_larger: 'https://azapp-citadeladmin-thanksgiving-prod.azurewebsites.net/images(500X300)/',
  image_url_for_smaller: 'https://azapp-citadeladmin-thanksgiving-prod.azurewebsites.net/images(300X200)/',
  base_url: 'https://azapp-citadelservice-thanksgiving-prod.azurewebsites.net',
  test_url: 'https://azapp-citadelservice-thanksgiving-prod.azurewebsites.net',

 //prod
//  image_url_for_larger: 'https://azapp-citadeladmin-thanksgiving-prod.azurewebsites.net/images(500X300)/',
//  image_url_for_smaller: 'https://azapp-citadeladmin-thanksgiving-prod.azurewebsites.net/images(300X200)/',
//  base_url : 'http://localhost:61689',
//  test_url:'http://localhost:61689',

//sandbox
 
// square_keys: {
//   squareApplicationId: "sandbox-sq0idb-LWZjuN3GR88-6KZ4YgU0Xg",
//   squareAccessToken:"EAAAEM2Jj5gbPazY1ObFmhq5_naDn-uFsYG3L4GjVhIxPWlI0G6lfJAbQ93Xnfdw",
//   path: "https://connect.squareupsandbox.com",
//   script_path: "https://js.squareupsandbox.com/v2/paymentform"
// },

//production

 square_keys: {
   squareApplicationId: "sq0idp-uS3DRVFAuyrgxCxSbQT3bw",
   squareAccessToken: "EAAAFE1dzBpE8i_0BeBB9bZxjTv4ttPHsvfPIz_AKfGpWRDGzSJ2u-pV4DH3dVfj",
   path: "https://connect.squareup.com",
   script_path: "https://js.squareup.com/v2/paymentform"
 }, 


   //sandbox
      // payment_form:{
      //   src:"https://sandbox.web.squarecdn.com/v1/square.js" 

      // },  
   //production
      payment_form:{
      //  src:"https://js.squareup.com/v2/paymentform" 
      src:"https://web.squarecdn.com/v1/square.js" 
            },
  
  
  //sandbox -->
  // <script defer type="text/javascript" src="https://js.squareupsandbox.com/v2/paymentform">
  
    timing:
    {
      Bf_Mintime:"07:00",
      Bf_Maxtime:"10:00",
    
      Lunch_mintime:"07:00",
      Lunch_maxtime:"20:00",//JSON.parse(localStorage.getItem("current_resturant_timings"))["endLocalTime"],


        
      },




     home_page: {
      url: "https://www.citadelcatering.com/thanksgiving/"
     },
  firebaseConfig: {
    apiKey: "AIzaSyBHpq5Bqmt4fPBne9ykem1OeN49hmEQGb4",
    authDomain: "citadel-hospitality-app.firebaseapp.com",
    databaseURL: "https://citadel-hospitality-app.firebaseio.com",
    projectId: "citadel-hospitality-app",
    storageBucket: "citadel-hospitality-app.appspot.com",
    messagingSenderId: "649570719455",
    appId: "1:649570719455:web:da39ac7f1d710bb7dcbb6a",
    measurementId: "G-7VYXH2W5LT",
  },
};
