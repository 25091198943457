import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import 'hammerjs';
import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { Component, HostBinding } from '@angular/core';
import { GoogleMaps } from '@ionic-native/google-maps';
import { Geolocation } from '@ionic-native/geolocation/ngx';

import { SlickCarouselModule } from 'ngx-slick-carousel';

import { MaterialModule } from './material.module';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
  AmazonLoginProvider,
} from 'angularx-social-login';

import * as square from 'square-connect';
import { OverlayModule } from '@angular/cdk/overlay';
import { ComponentsModule } from './component/component.module';
// import { Facebook } from '@ionic-native/facebook/ngx';
// import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';
import { InterceptorService } from './service/interceptor.service';

// import { InAppBrowser } from '@ionic-native/in-app-browser';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import * as _rollupMoment from 'moment';
import { FlatpickrModule } from 'angularx-flatpickr';
import { environment } from 'src/environments/environment';
// import { GoogleChartsModule } from 'angular-google-charts';
const moment = _rollupMoment || _moment;
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import {PopoverModule} from "ngx-smart-popover";
import { DatePipe } from '@angular/common';
// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,PopoverModule ,
    OverlayModule,
    
    MaterialModule,
    SocialLoginModule,
    ComponentsModule,
    HttpClientModule,
    NgxCaptchaModule,
    NgbPopoverModule,
    NgbModule,
    IonicStorageModule.forRoot(),
    FlatpickrModule.forRoot(),
    // GoogleChartsModule,
    // Facebook,
    BrowserAnimationsModule, SlickCarouselModule

  ],
  providers: [
    DatePipe,
    StatusBar,
    SplashScreen,
    GoogleMaps,
    Geolocation,
    NavParams,
    // Facebook,
    // GooglePlus,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
      },
      // { provide: LOCALE_ID, useValue: "en-US" },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('591846001725172'),
          },
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '513393073982-s5kjqu1om32qd7ku36ce0s4l823mkb3t.apps.googleusercontent.com'
            ),
          },

        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},

    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
