import { Component, OnInit } from '@angular/core';
import { AlertController, MenuController, ModalController, NavController } from '@ionic/angular';
import {Input} from "@angular/core";
import { SubjectCollectionService } from 'src/app/service/subject-collection.service';
import { ResturantApiService } from 'src/app/service/resturant-api.service';
import { UtilityService } from 'src/app/service/utility.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.page.html',
  styleUrls: ['./rewards.page.scss'],
})
export class RewardsPage implements OnInit {
   @Input() rewards_programs:any;
   @Input() account_info:any;
   public reward_tiers:any;
   public reward_tier_id: any;
   public chck: boolean = false;
   public reward_button? : any;
   public free_item_available: any;
  constructor(
    public menu: MenuController,
    public modal_controller: ModalController,
    public navCtrl: NavController,  
    public alertController: AlertController,
    public subject_service: SubjectCollectionService,
    public resturant_service: ResturantApiService,
    public util: UtilityService,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.reward_tiers=this.rewards_programs[0].reward_tiers;
  }
  handleSelected() {
    if (this.chck==false)
     {
      this.chck=true;
     }
    else{
      this.chck=false;
      this.reward_button = false;
    }
 }
  closeWithSkip(){
    this.modal_controller.dismiss({reward_tier_id : null , do_payment : true});
    
  }
  closeModel()
  {
    this.modal_controller.dismiss({reward_tier_id : this.reward_tier_id , do_payment : true});
  }
  async alertMessage(question, message,time) {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: question,
      message: message,
      buttons: [
        {
          text: "OK",
          role: "OK",
          cssClass: "secondary",
          handler: (blah) => { },
        },
      ],
    });
    setTimeout(async ()=>await alert.present(),time);
  }
  async add_to_cart_free_item(id){
    debugger
    var menu=JSON.parse(localStorage.getItem("menu"));
    var a = menu.filter(x => x.variation.some((obj) => obj.id ==id));

    if(a[0].isSold==true)
      {
        this.alertMessage("Sold out!!","Item is sold out.",0);
      }
      else{
        var vm = this;
        vm.util.presentLoading();
        var order_detail: any = [];
        var all_taxes: any = [];
        order_detail = await a.map(
          (e) => {
            var taxes: any = [];
            if (e.itemTax && e.itemTax.length > 0) {
              for (let j = 0; j < e.itemTax.length; j++) {
                taxes.push({
                  OrderDetailTaxId: e.itemTax[j].id,
                });
              }
            }
            var data = {
              VariationId: id,
              Quantity: "1",
              OrderDetailTaxes: [...taxes]
            };
            return data;
          }
        );

        order_detail.map((f) => {
          f.OrderDetailTaxes.map((g) => {
            all_taxes.push({ OrderDetailTaxId: g.OrderDetailTaxId });
          });
        });

        var result = all_taxes.reduce((unique, o) => {
          if (!unique.some((obj) => obj.OrderDetailTaxId == o.OrderDetailTaxId)) {
            unique.push(o);
          }
          return unique;
        }, []);

        var all_taxes_filtered: any;
        all_taxes_filtered = result.map((e) => {
          return { TaxId: e.OrderDetailTaxId };
        });
        vm.subject_service.getWaitingForCart(true);
        vm.subject_service.getWaitingForSubtotal(true);
      
        let order_id = localStorage.getItem("order_id");
        var location_id = JSON.parse(
          localStorage.getItem("current_resturant_data")
        )["id"];
        var item_add_to_cart = {
          LocationId:location_id,
          OrderDetails: [...order_detail],
          Taxes: [...all_taxes_filtered],
          OrderId:order_id
        };
        var p = await vm.clean(item_add_to_cart);
        p.OrderDetails = p.OrderDetails.map((e) => {
          return vm.clean(e);
        });
        if (p.Taxes) {
          p.Taxes.TaxId = await vm.removeByAttr(p.Taxes, "TaxId", "");
        }
        vm.resturant_service.createOrderWithoutUserInfo(p).subscribe(
          (data: any) => {
            vm.util.dismissLoader();
            vm.afterGettingResponseFromOrderApiWithoutUser(data);
          },
          (error) => {
            vm.util.dismissLoader();
            vm.snackBar.open(error, "Done", {
              duration: 2000,
            });
            vm.subject_service.getWaitingForCart(false);
            vm.subject_service.getWaitingForSubtotal(false);
          }
        );
        this.reward_button = true;
      }

  }
  afterGettingResponseFromOrderApiWithoutUser(data) {

    return new Promise((resolve, reject) => {
      try {
        let vm = this;
        let data_got: any = [];
        let subtotal: any = null;
        if (data) {
          if (data.subTotal) {
            subtotal = {
              subtotal: {
                amount: data.subTotal,
                currency: "USD",
              },
              tax: {
                amount: data.totalTax *(0.19075/0.1825),
                currency: "USD",
              },
            };
          }

          if (data.orderDetails) {
            data.orderDetails.map((e) => {
              let p: any = [];
              let taxes: any = [];
              var total_money: any = 0;
              if (
                e.orderDetailCustomizations &&
                e.orderDetailCustomizations.length > 0
              ) {
                e.orderDetailCustomizations.map((f) => {
                  p.push({
                    name: f.customizationOptionName,
                    catalog_id: f.itemCustomizationOptionId,
                    customizationOptionPrice: f.customizationOptionPrice
                      ? f.customizationOptionPrice
                      : null,
                    customizationOptionVariationName: f.customizationOptionVariationName
                      ? f.customizationOptionVariationName
                      : null,
                    uid: f.uid,
                    amount: f.customizationOptionPrice,
                  });
                  total_money =
                    total_money + parseFloat(f.customizationOptionPrice);
                });
              }
              if (e.orderDetailTaxes && e.orderDetailTaxes.length > 0) {
                e.orderDetailTaxes.map((g) => {
                  taxes.push({
                    OrderDetailTaxId: g.orderDetailTaxId,
                  });
                });
              }
              data_got.push({
                variation_price: e.variationPrice,
                variation_currency: "USD",
                variation_name: e.variationName,
                uid: e.uid,
                catalog_id: e.variationId,
                item_name: e.itemName,
                quantity: e.quantity,
                amount: JSON.stringify(
                  JSON.parse(e.quantity) *
                  (JSON.parse(e.variationPrice) + total_money)
                ),
                currency: "USD",
                customisation: p,
                tax_id: taxes,
              });
            });
          }
        }
        localStorage.setItem("order_id", data["orderId"]);
        vm.subject_service.getcartReponse(data_got);
        localStorage.setItem("cart_response", JSON.stringify(data_got));
        localStorage.setItem("cart_subtotal", JSON.stringify(subtotal));//subtotal
        vm.subject_service.getSubtotalReponse(subtotal);
        vm.subject_service.getWaitingForSubtotal(false);
        vm.subject_service.getWaitingForCart(false);
        resolve("success");
      } catch (error) {
        reject("error");
      }
    });
  }
  clean(obj) {
    var vm = this;
    for (var propName in obj) {
      if (
        obj[propName] == null ||
        obj[propName] == "" ||
        obj[propName] == undefined
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }
  removeByAttr(arr, attr, value) {
    var i = arr.length;
    while (i--) {
      if (
        arr[i] &&
        arr[i].hasOwnProperty(attr) &&
        arguments.length > 2 &&
        arr[i][attr] === value
      ) {
        arr.splice(i, 1);
      }
    }
    return arr;
  }
  rewardChange(event,id,definition)
  {
    var cart_response = localStorage.getItem("cart_response")
    ? JSON.parse(localStorage.getItem("cart_response"))
    : [];
     cart_response.some(element=>{
      if(element.catalog_id == definition.catalog_object_ids[0] )
      {
        this.free_item_available = true;
        return true;
      }
     else{
      this.free_item_available = false;
     }
  });
     if(this.free_item_available == true)
     {
      this.reward_button = true;
     }
     else{
      this.reward_button = false;
      // this.presentAlert(definition.catalog_object_ids[0]);
      this.add_to_cart_free_item(definition.catalog_object_ids[0]);
     }
    this.reward_tier_id=id;
  }
  async presentAlert(id) {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "Add Free Item In The Cart!!",
      message:
        "Your basket does not contain free item to redeem this reward.",
      buttons: [
       
        {
          text: "ADD FREE ITEM",
          role: "OK",
          cssClass: "add_item",
          handler: () => {
            this.add_to_cart_free_item(id);
          },
        },
        {
          text: "OK",
          role: "OK",
          cssClass: "secondary",
          handler: () => {
          },
        }
      ],
    });

    await alert.present();
  }
  openHome()  {
    this.modal_controller.dismiss({reward_tier_id : null , do_payment : false});
    this.navCtrl.navigateRoot("ordering-system");

  }
}
