import { NgModule } from '@angular/core';
// import { AutocompletePlacesComponent } from './autocomplete-places/autocomplete-places.component';
// import { CrouselComponent } from './crousel/crousel.component';
import { FooterComponent } from './footer/footer.component';
// import { HeaderComponent } from './header/header.component';
// import { HeaderOnOrderComponent } from './header-on-order/header-on-order.component';
//import { MapDisplayingAllHotelsComponent } from './map-displaying-all-hotels/map-displaying-all-hotels.component';
//import { MenuComponent } from './menu/menu.component';
//import { MenuItemsComponent } from './menu-items/menu-items.component';
import { RegisterComponent } from './register/register.component';
import { RegistrationFormComponent } from './registration-form/registration-form.component';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
//import { SquarePaymentComponent } from './square-payment/square-payment.component';
import { ItemCountComponent } from './item-count/item-count.component';
//import { MealOptionsComponent } from './meal-options/meal-options.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { DynamicOptionsComponent } from './dynamic-options/dynamic-options.component';
import { RightPanelComponent } from './right-panel/right-panel.component';
//import { DialogBoxesComponent } from './dialog-boxes/dialog-boxes.component';
///import { OrderCollectionFormComponent } from './order-collection-form/order-collection-form.component';
import { UserInfoComponent } from './user-info/user-info.component';
//import { EachItemComponent } from './each-item/each-item.component';
//import { GroupOrderComponent } from './group-order/group-order.component';
//import { OptionSelectedComponent } from './option-selected/option-selected.component';
//import { RegionChartComponent } from './region-chart/region-chart.component';
import { TooltipComponent } from '@angular/material/tooltip';
//import { AwaesometooltipComponent } from './awaesometooltip/awaesometooltip.component';
//import { ShowErrorComponent } from './show-error/show-error.component';
import { DirectiveModule } from '../directive/directive.module';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//import { PopoverBootstrapComponent } from './popover-bootstrap/popover-bootstrap.component';
import { PopoverModule } from 'ngx-smart-popover';
//import { SideMenuComponent } from './side-menu/side-menu.component';

import { DpDatePickerModule } from 'ng2-date-picker';
import { FlatpickrModule } from 'angularx-flatpickr';
// import { TelFormatComponent } from './tel-format/tel-format.component';
// import { CustomTelInputComponent } from './custom-tel-input/custom-tel-input.component';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { PaySquareComponent } from './pay-square/pay-square.component';
import { RegisterSignInComponent } from './register-sign-in/register-sign-in.component';




@NgModule({
  imports:      [ CommonModule, MaterialModule,NgbModule,PopoverModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    DirectiveModule,

    NgxCaptchaModule,
    DpDatePickerModule,
    FlatpickrModule.forRoot(),
    MatDatepickerModule,
    MatInputModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
   
    MatButtonModule,
    NgxMatMomentModule,
    MatRadioModule,
    MatSelectModule,
    MatCheckboxModule,
  MaterialModule
   ],

  declarations: [
    FooterComponent,
    ItemCountComponent,

      RegisterComponent,
    RegistrationFormComponent,
    RegisterSignInComponent,

   
    DynamicFormComponent,
    DynamicOptionsComponent,
    RightPanelComponent,
    UserInfoComponent,
    PaySquareComponent
    // TelFormatComponent,
    // CustomTelInputComponent


],
  exports: [
    FooterComponent,
   
    PaySquareComponent,
    RegisterComponent,
    RegistrationFormComponent,
    RegisterSignInComponent,
    ItemCountComponent,
    
 
    DynamicFormComponent,
    DynamicOptionsComponent,
    RightPanelComponent,
    
    // TelFormatComponent,
    // CustomTelInputComponent


]

})
export class ComponentsModule {}
