import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
public image_url_for_smaller_image:any;
public image_url_for_larger_image:any;

  constructor() { 
    this.image_url_for_smaller_image=environment.image_url_for_smaller;
    this.image_url_for_larger_image=environment.image_url_for_larger;

  }
}
