import { Directive, ElementRef, HostListener, Output, Input } from '@angular/core';

@Directive({
  selector: '[appEvokeButton]'
})
export class EvokeButtonDirective {
  @Input() show?:any;
  // @Output() value:any;
  constructor(public el: ElementRef) {
    console.log("Evoke button enterd")
    // el.nativeElement.style.backgroundColor = 'yellow';
 }
 @HostListener('mouseenter') onMouseEnter() {
  this.highlight('yellow');
  this.show=!this.show
  // this.value.emit(this.show)

}

@HostListener('mouseleave') onMouseLeave() {
  this.highlight(null);
  this.show=!this.show
  // this.value.emit(this.show)

}
ngOnChanges(){
  console.log("Show on evoke directive",this.show)
}

private highlight(color: string) {
  this.el.nativeElement.style.backgroundColor = color;
}

}
