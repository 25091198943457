import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubjectCollectionService {
  public form_modal_request = new Subject<any>();
  public user_data = new Subject<any>();
public cart_data = new Subject<any>();
public cart_response = new Subject<any>();
public waitng_for_cart_response = new Subject<any>();
public waitng_for_subtotal_response = new Subject<any>();
public get_sub_total = new Subject<any>();
public all_items = new Subject<any>();
public order_mode=new Subject<any>();
public order_timing=new Subject<any>();
public order_timing_type=new Subject<any>();
public order_address=new Subject<any>();
public schedule_time=new Subject<any>();
public item_count=new Subject<any>();
public total_amount_payable=new Subject<any>();
public delivery_address=new Subject<any>();
public current_resturant_data=new Subject<any>();
public sign_up_sign_in_clicked=new Subject<any>();
public got_fulfiment=new Subject<any>();
public proceed_to_checkout_clicked=new Subject<any>();
public error_message_onproceed_to_checkout_clicked=new Subject<any>();
  constructor() { }
  getheaderButtonClicked(value){
    this.sign_up_sign_in_clicked.next(value);
  }
  subscibeToHeaderButtonClicked(): Observable<any>{
    return this.sign_up_sign_in_clicked.asObservable();
  }
  getItemCount(value){
    this.item_count.next(value);
  }
  subscibeToItemCount(): Observable<any>{
    return this.item_count.asObservable();
  }
  getTotalPayableAmount(value){
    this.total_amount_payable.next(value);
  }
  subscibeToTotalAmountPayable(): Observable<any>{
    return this.total_amount_payable.asObservable();
  }
  getErrorMessageOnProceedToCheckOutClicked(value){
    localStorage.setItem("Proceed_checkout_error_message",value)
    this.error_message_onproceed_to_checkout_clicked.next(value);
  }
  subscibeToErrormessageProceedToCheckOutClicked(): Observable<any>{
    return this.error_message_onproceed_to_checkout_clicked.asObservable();
  }
  getProceedToCheckOutClicked(value){
    this.proceed_to_checkout_clicked.next(value);
  }
  subscibeToProceedToCheckOutClicked(): Observable<any>{
    return this.proceed_to_checkout_clicked.asObservable();
  }
  getFulfilment(value){
    this.got_fulfiment.next(value);
  }
  subscibeToFulfimnet(): Observable<any>{
    return this.got_fulfiment.asObservable();
  }
  getCurrentResturant(value){
    this.current_resturant_data.next(value);
  }
  subscibeToCurrentResturent(): Observable<any>{
    return this.current_resturant_data.asObservable();
  }
  geScheduleTime(value){
    this.schedule_time.next(value);
  }
  subscibeToScheduleTime(): Observable<any>{
    return this.schedule_time.asObservable();
  }
  subscibeToOrderAddress(){
    return this.order_address.asObservable();

  }
  getOrderAddress(value){
    this.order_address.next(value);
  }
  subscibeToOrderTiming(){
    return this.order_timing.asObservable();

  }
  getOrderTiming(value){
    this.order_timing.next(value);
  }
  getOrderTimeMode(value){
    this.order_timing_type.next(value);
  }
  subscibeToOrderTimeMode(): Observable<any>{
    return this.order_timing_type.asObservable();
  }
  getOrderMode(value){
    this.order_mode.next(value);
  }
  subscibeToOrderMode(): Observable<any>{
    return this.order_mode.asObservable();
  }
  getLocationItems(value){
    this.all_items.next(value);
  }
  subscibeTolocatinItems(): Observable<any>{
    return this.all_items.asObservable();
  }
  getSubtotalReponse(value){
    this.get_sub_total.next(value);
  }
  subscibeToSubtotal(): Observable<any>{
    return this.get_sub_total.asObservable();
  }
  getcartReponse(value){
    this.cart_response.next(value);
  }
  subscibeToResponseFromCart(): Observable<any>{
    return this.cart_response.asObservable();
  }
  getWaitingForSubtotal(value){
    this.waitng_for_subtotal_response.next(value);
  }
  subscibeToWaitingSubtotal(): Observable<any>{
    return this.waitng_for_subtotal_response.asObservable();
  }
  getWaitingForCart(value){
    this.waitng_for_cart_response.next(value);
  }
  subscibeToWaitingAddToCart(): Observable<any>{
    return this.waitng_for_cart_response.asObservable();
  }
  getcartValue(form_name){
    this.cart_data.next(form_name);
  }
  subscibeToCartValue(): Observable<any>{
    return this.cart_data.asObservable();
  }
  getFormModalTypeToBeOpened(form_name){
    this.form_modal_request.next(form_name);
  }
  subscibeToFormModalType(): Observable<any>{
    return this.form_modal_request.asObservable();
  }
  getThemeToBeApplied(form_name){
    this.form_modal_request.next(form_name);
  }
  subscibeToThemeApplied(): Observable<any>{
    return this.form_modal_request.asObservable();
  }
  getLoginData(user_info){
    this.user_data.next(user_info);
  }
  subscibeToLoginData(): Observable<any>{
    return this.user_data.asObservable();
  }
}
