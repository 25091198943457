import {
  Component,
  OnInit,
  Input,
  Inject,
  ViewChild,
  ElementRef,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import { DatePipe } from '@angular/common'
import {
  AlertController,
  MenuController,
  ModalController,
  NavController,
  Platform,
} from "@ionic/angular";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SocialAuthService } from "angularx-social-login";
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
} from "angularx-social-login";
// import { Facebook, FacebookLoginResponse } from "@ionic-native/facebook/ngx";
// import { GooglePlus } from "@ionic-native/google-plus/ngx";
import { AuthService } from "src/app/service/auth.service";
import { Storage } from "@ionic/storage";
import { UtilityService } from "src/app/service/utility.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ErrorStateMatcher } from "@angular/material/core";
import { SubjectCollectionService } from "src/app/service/subject-collection.service";
import * as moment from "moment";
import { ResturantApiService } from "src/app/service/resturant-api.service";
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NavigationExtras, Router } from "@angular/router";
import { environment } from "src/environments/environment";
declare var FB: any;
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
@Component({
  selector: "app-registration-form",
  templateUrl: "./registration-form.component.html",
  styleUrls: ["./registration-form.component.scss"],
})

export class RegistrationFormComponent implements OnInit {
  @Input() form_name: any;
  public skip: boolean = false;
  public complete_menu: any = [];
  public recent_order_available: any;
  public recent_order_error: any;
  public recent_orders?: any = [];
  public rewards_available: any;
  public rewards?:any = [];
  public rewards_error: any;
  public account_info :  any;
  public cart_Cat: any;
  public isClosed:boolean =false;
  public min_date: any;
  public max_date: any;
  public submit_clicked: boolean = false;
  @Input() pickup_abled?: any;
  @Input() next: any;
  @Input() warning_presence?: any;
  @Input() login_warning?: any;
  @Input() check_out_page_calling?: any;
  @Input() thanks_giving_form_display?: any;
  @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;
  basicDemoValue = "2017-01-01";
  modelValueAsDate: Date = new Date();
  dateTimeValue: Date = new Date();
  multiDates: Date[] = [new Date(), (new Date() as any)["fp_incr"](10)];
  rangeValue: { from: Date; to: Date } = {
    from: new Date(),
    to: (new Date() as any)["fp_incr"](10),
  };
  inlineDatePicker: Date = new Date();
  timePicker: Date = null;
  // private phone_no : FormGroup;
  public cart_response: any = [];
  public email: any;
  public cart_subtotal: any = 0;
  public minDate: any;
  public maxDate: any;
  public user_info: any;
  public move_to_payment: any;
  public login_form: FormGroup;
  public form_name_displayed: any;
  public register_form: FormGroup;
  public hide: any;
  public total_amount :any;
  public config: any;
  public guest_user_form_value: any;
  public schedule_type: any;
  public schedule_time: any;
  public matcher: any;
  public forgot_password: FormGroup;
  public guest_user_account: FormGroup;
  public user_account: FormGroup;
  public FirstEdit: boolean = true;
  public LastEdit: boolean = true;
  public PhoneEdit: boolean = true;
  public aFormGroup: FormGroup;
  public token_value: any;
  public open_thanks_for_signing: any;
  public date_entered: any;
  public guest_info: any;
  public payment_status: any;
  public time_chosen_for_pick_up: any;
  public phone: any;
  constructor(
    public menu: MenuController,
    private router: Router,
    public datepipe: DatePipe,
    private formBuilder: FormBuilder,
    private authService: SocialAuthService,
    public modalCtrl: ModalController,
    // private fb_plugin: Facebook,
    public plt: Platform,
    // private googlePlus: GooglePlus,
    public auth: AuthService,
    public util: UtilityService,
    public snackBar: MatSnackBar,
    public navCtrl: NavController,
    public subject_service: SubjectCollectionService,
    public resturant_service: ResturantApiService,
    public alertController: AlertController
  ) {
    
    this.recent_order_available = true;
    this.recent_order_error = false;
    // this.phone_no= this.formBuilder.group({
    //   area: ['',  Validators.compose([Validators.required,Validators.pattern('[0-9]{3}'), Validators.maxLength(3)])],
    //   exchange:['',  Validators.compose([Validators.required, Validators.pattern('[0-9]{3}'),Validators.maxLength(3)])],
    //   subscriber:['',  Validators.compose([Validators.required,Validators.pattern('[0-9]{4}'), Validators.maxLength(4)])]
    // });
    this.cart_response = localStorage.getItem("cart_response")
      ? JSON.parse(localStorage.getItem("cart_response"))
      : [];
    var data = localStorage.getItem("cart_subtotal")
      ? JSON.parse(localStorage.getItem("cart_subtotal"))
      : "";
    // if (data) {
    //   debugger;
    //   this.total_amount=data.subtotal.amount;
    //   this.cart_subtotal =
    //     parseFloat(data.subtotal.amount) + this.total_amount * (1 + 0.0825) * (1 + 0.10) - this.total_amount;
    // }
    if (data) {
      debugger;
      this.total_amount = parseFloat(data.subtotal.amount);
      
      // Calculate tax at 18.25% and round it to two decimal places
      const taxAmount = Math.round(this.total_amount * 0.1825 * 100) / 100;
    
      // Add tax to subtotal
      this.cart_subtotal = this.total_amount + taxAmount;
    }


    this.user_info = localStorage.getItem("user_info")
      ? JSON.parse(localStorage.getItem("user_info"))
      : "";
    this.guest_info = localStorage.getItem("guest_info")
      ? JSON.parse(localStorage.getItem("guest_info"))
      : "";
    if (this.guest_info) {
      this.move_to_payment = true;
    }
    if (this.user_info) {
      if (localStorage.getItem("fulfilment_data")) {
        this.move_to_payment = true;
        this.payment_status = false;
      } else {
        this.move_to_payment = false;
        this.payment_status = false;
      }
    }
    // if(this.guest_info){
    //   if(localStorage.getItem('fulfilment_data')){
    //     this.move_to_payment=true;
    //     this.payment_status=false;
    //   }
    //   else{
    //     this.move_to_payment=false;
    //     this.payment_status=false;
    //   }

    // }
    this.open_thanks_for_signing = false;
    this.config = {
      firstDayOfWeek: "su",
      monthFormat: "MMM, YYYY",
      disableKeypress: false,
      allowMultiSelect: false,
      closeOnSelect: undefined,
      closeOnSelectDelay: 100,
      onOpenDelay: 0,
      weekDayFormat: "ddd",
      appendTo: document.body,
      drops: "down",
      opens: "right",
      showNearMonthDays: true,
      showWeekNumbers: false,
      enableMonthSelector: true,
      format: "YYYY-MM-DD HH:mm",
      yearFormat: "YYYY",
      showGoToCurrent: true,
      dayBtnFormat: "DD",
      monthBtnFormat: "MMM",
      hours12Format: "hh",
      hours24Format: "HH",
      meridiemFormat: "A",
      minutesFormat: "mm",
      minutesInterval: 1,
      secondsFormat: "ss",
      secondsInterval: 1,
      showSeconds: false,
      showTwentyFourHours: true,
      timeSeparator: ":",
      multipleYearsNavigateBy: 10,
      showMultipleYearsNavigation: false,
      locale: "en-US",
      min: "2017-08-29 15:50",
      minTime: "2017-08-29 15:50",
    };
    this.matcher = new MyErrorStateMatcher();
    this.hide = true;
    this.guest_user_account = this.formBuilder.group({
      first_name: ["", Validators.required],
      last_name: [""],
      email: [
        "",
        [Validators.required, Validators.email, Validators.maxLength(255)],
      ],
      phone_number: [
        "",
        [
          Validators.maxLength(10),
          Validators.minLength(10),
          Validators.required,
        ],
      ],
      shipped_at: ["", Validators.required],
    });
    if(this.user_info)
    {
      this.phone= this.user_info.phoneNumber.substring(2);
    }
    this.user_account = this.formBuilder.group({
      first_name: [this.user_info.firstName, Validators.required],
      last_name: [this.user_info.lastName],
      email: [
        this.user_info.email,
        [Validators.required, Validators.email, Validators.maxLength(255)],
      ],
      phone_number: [
       this.phone,
        [
          Validators.maxLength(10),
          Validators.minLength(10),
          Validators.required,
        ],
      ],
      shipped_at: ["", Validators.required],
    });
    this.forgot_password = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
    });
    this.login_form = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: [
        "",
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(15),
        ],
      ],
    });
    // Validators.pattern(/^(\d{3})(\d{3})(\d{4})/),
    this.register_form = this.formBuilder.group({
      first_name: ["", [Validators.required]],
      last_name: [""],
      phone_no: [
        "",
        [
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.required,
        ],
      ],
      // user_name: ['', Validators.required],
      // (?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}
      email: ["", [Validators.required, Validators.email]],
      password: [
        "",
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(15),
          // Validators.pattern(/^(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,})/),
        ],
      ],
      // agree: [false, Validators.required]
    });
  }
  backToMenu(ev) {
    this.openMenu();
  }
  openMenu() {
    this.navCtrl.navigateRoot("ordering-system");
  }
  async loginOrRegistrationButtonClicked(form_name) {
    const modal = await this.modalCtrl.create({
      component: RegistrationFormComponent,
      cssClass: "my-modal",
      componentProps: {
        form_name,
      },
    });
    return await modal.present();
  }
    isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }
  backToPreviousState() {
    this.user_account = this.formBuilder.group({
      first_name: [this.user_info.firstName, Validators.required],
      last_name: [this.user_info.lastName],
      email: [
        this.user_info.email,
        [Validators.required, Validators.email, Validators.maxLength(255)],
      ],
      phone_number: [
        this.phone,
        [
          Validators.maxLength(10),
          Validators.minLength(10),
          Validators.required,
        ],
      ]
    });
  }
  async updateAccount() {
    var vm = this;
    await vm.util.presentLoading();
    const val = {
      FirstName: this.user_account.value.first_name
        ? this.user_account.value.first_name
        : "",
      LastName: this.user_account.value.last_name
        ? this.user_account.value.last_name
        : "",
      Email: this.user_account.value.email
        ? this.user_account.value.email
        : ""
    };
    try {
      var update_result = await this.auth.updateAccount(val);
      this.user_info = JSON.parse(localStorage.getItem("user_info"));
      vm.util.dismissLoader();
    } catch (error) {
      debugger;
      vm.util.dismissLoader();
      vm.util.showErrorMessage(error);
    }
  }
  FirstEditButtonClicked() {
    this.FirstEdit = false;
    this.LastEdit = true;
    this.PhoneEdit = true;
    this.backToPreviousState();
  }
  FirstUpdateButtonClicked() {
    this.FirstEdit = true;
    this.updateAccount();
  }
  FirstCancelButtonClicked() {
    this.FirstEdit = true;
    this.backToPreviousState();
  }
  LastEditButtonClicked() {
    this.LastEdit = false;
    this.FirstEdit = true;
    this.PhoneEdit = true;
    this.backToPreviousState();
  }
  LastUpdateButtonClicked() {
    this.LastEdit = true;
    this.updateAccount();
  }
  LastCancelButtonClicked() {
    this.LastEdit = true;
    this.backToPreviousState();
  }
  PhoneEditButtonClicked() {
    this.PhoneEdit = false;
    this.LastEdit = true;
    this.FirstEdit = true;
    this.backToPreviousState();
  }
  PhoneUpdateButtonClicked() {
    this.PhoneEdit = true;
    this.updateAccount();
  }
  PhoneCancelButtonClicked() {
    this.PhoneEdit = true;
    this.backToPreviousState();
  }
  emailDomainValidator(control: FormControl) {
    let email = control.value;
    2;
    if (email && email.indexOf("@") != -1) {
      let [_, domain] = email.split("@");
      if (domain !== "gmail.com") {
        return {
          emailDomain: {
            parsedDomain: domain,
          },
        };
      }
    }
    return null;
  }

  checkLoginState() {
    FB.getLoginStatus(function (response) {
    });
  }
  checkform() {
  }
  async presentModal(form_name, warning_message?) {
    const modal = await this.modalCtrl.create({
      component: RegistrationFormComponent,
      cssClass: "my-modal",
      componentProps: {
        form_name: form_name,
        warning_presence: warning_message ? warning_message : "",
        login_warning: warning_message ? warning_message : ""
      },
    });
    return await modal.present();
  }
  async loginButtonPressed() {
    var vm = this;
    localStorage.removeItem("guest_user_form_value");
    await vm.util.presentLoading();
    const val = {
      username: this.login_form.value.email
        ? this.login_form.value.email
        : "NA",
      password: this.login_form.value.password
        ? this.login_form.value.password
        : "NA",
    };
    try {
      const p = await this.auth.login(val);
      if (p == "success") {
        this.warning_presence = false;
        this.login_warning = false;
        await this.openMenu();
      } else {
        vm.util.dismissLoader();
      }
      vm.util.dismissLoader();
    } catch (error) {
      if (error == "user not registered") {

        vm.formDirective.resetForm();
        vm.warning_presence = "";
        vm.util.dismissLoader();
      } else if (error == "Email is not confirmed.") {
        vm.formDirective.resetForm();
        vm.warning_presence = true;
        vm.util.dismissLoader();
      }
      else if (error == "Invalid login attempt.") {
        vm.formDirective.resetForm();
        vm.login_warning = true;
        vm.util.dismissLoader();
      } else {
        vm.formDirective.resetForm();
        vm.util.dismissLoader();
        vm.snackBar.open(error, "Done"
        );
      }
    }


  }
  async registerButtonClicked() {
    var vm = this;
    localStorage.removeItem("guest_user_form_value");
    await vm.util.presentLoading();
    const val = {
      FirstName: this.register_form.value.first_name
        ? this.register_form.value.first_name
        : "",
      LastName: this.register_form.value.last_name
        ? this.register_form.value.last_name
        : "",
      Password: this.register_form.value.password
        ? this.register_form.value.password
        : "",
      Email: this.register_form.value.email
        ? this.register_form.value.email
        : "",
      PhoneNumber: this.register_form.value.phone_no
        ? this.register_form.value.phone_no
        : "",
      CaptchaToken: vm.token_value ? vm.token_value : "",
    };
    try {
      var register_result = await this.auth.register(val);
      this.email = this.register_form.value.email;
      vm.register_form.reset();
      vm.util.dismissLoader();
      vm.open_thanks_for_signing = true;
    } catch (error) {
      if (error == "Email already exists.") {
        vm.register_form.reset();
        vm.login_warning = true;
        vm.util.dismissLoader();
      }
      else {
        debugger;
        vm.util.dismissLoader();
        vm.util.showErrorMessage(error);
      }
    }
  }
  openModal() {
    this.dimissFormPage();

    this.presentModal("login", true);
  }
  async forgotPasswordButtonPressed() {
    var vm = this;
    await vm.util.presentLoading();
    this.auth.forgotPassword(this.forgot_password.value).subscribe(
      (data) => {
        vm.snackBar.open("Forgot password email is sent successfully", "Done", {
          duration: 2000,
        });
        if (!this.check_out_page_calling) {
          this.dimissFormPage();
        }
        vm.util.dismissLoader();
        vm.login_warning = false;
        vm.form_name = "login";
      },
      async (error) => {
        if (!this.check_out_page_calling) {
          this.dimissFormPage();
        }
        await vm.util.dismissLoader();
        debugger;
        vm.util.showErrorMessage(error);

      }
    );
  }
  async alertMessage(question, message,time) {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: question,
      message: message,
      buttons: [
        {
          text: "OK",
          role: "OK",
          cssClass: "secondary",
          handler: (blah) => { },
        },
      ],
    });
    setTimeout(async ()=>await alert.present(),time);
  }
  paymentMade(ev) {
    this.payment_status = ev;
    this.move_to_payment = true;
  }
  getTimeForPickup(event) {
    debugger
    if (JSON.parse(localStorage.getItem("cart_response")) != null) {
      var menu = JSON.parse(localStorage.getItem("menu"));
      var cart = JSON.parse(localStorage.getItem("cart_response"));
      var burgerItem=0;
      cart.forEach(function (cart) {

        var a = menu.filter(x => x.itemName == cart.item_name)
        a.forEach(function (a) {
          if (a.categoryName == "BURGER BAR") {
           
            burgerItem = burgerItem + 1;

          }
        });

      });

    }
    this.time_chosen_for_pick_up = event.target.value;
    this.schedule_type = this.time_chosen_for_pick_up;
    localStorage.setItem(
      "time_chosen_for_pick_up",
      this.time_chosen_for_pick_up
    );

    if (this.time_chosen_for_pick_up == "asap") {
      
      this.date_entered = new Date();
      if(burgerItem>0)
      {
        this.date_entered.setMinutes(this.date_entered.getMinutes() + 40);

      }else{
        this.date_entered.setMinutes(this.date_entered.getMinutes() + 15);
      }
      
      this.schedule_time = this.date_entered;
      let latest_date = this.datepipe.transform(this.schedule_time, 'MMMM dd, h:mm a');
      this.schedule_time = latest_date;
      localStorage.setItem("schedule_time", this.schedule_time);
    } else {
      
      this.date_entered = new Date();
      if(burgerItem>0)
      {
        this.date_entered.setMinutes(this.date_entered.getMinutes() + 40);

      }else{
        this.date_entered.setMinutes(this.date_entered.getMinutes() + 15);
      }
      this.date_entered.setSeconds(0,0);
      this.schedule_time = this.date_entered;
      let latest_date = this.datepipe.transform(this.schedule_time, 'MMMM dd, h:mm a');
      this.schedule_time = latest_date;
    }
  }

  async presentAlertForPickup() {
    
    
    if (JSON.parse(localStorage.getItem("cart_response")) != null) {
      var Olength = 0;
      length = 0;
      var menu = JSON.parse(localStorage.getItem("menu"));
      var cart = JSON.parse(localStorage.getItem("cart_response"));
      cart.forEach(function (cart) {

        var a = menu.filter(x => x.itemName == cart.item_name)
        a.forEach(function (a) {
          if (a.categoryName == "BREAKFAST" || a.categoryName == "SNACKS" || a.categoryName == "DESSERTS" || a.categoryName == "BEVERAGES") {
            if (a.categoryName == "BREAKFAST") {
              Olength = Olength + 1;
            }
            length = length + 1;

          }
        });

      });
      if (length == cart.length && Olength != 0) {
        this.cart_Cat = "BREAKFAST";
      }
      else if (Olength == 0 && length == cart.length) {
        this.cart_Cat = "";
      }
      else if (Olength == 0 && length != cart.length) {
        this.cart_Cat = "lunch";
      }



    }
    if (this.cart_Cat == "BREAKFAST") {
      this.min_date = JSON.parse(localStorage.getItem("current_resturant_timings"))["startLocalTime"]; //environment.timing.Bf_Mintime; 
      this.max_date = environment.timing.Bf_Maxtime;


    } else if (this.cart_Cat == "lunch") {
      this.min_date = environment.timing.Lunch_mintime;
      this.max_date =JSON.parse(localStorage.getItem("current_resturant_timings"))["endLocalTime"]; //environment.timing.Lunch_maxtime;
    } else {


      this.min_date = JSON.parse(localStorage.getItem("current_resturant_timings"))["startLocalTime"];
      this.max_date = JSON.parse(localStorage.getItem("current_resturant_timings"))["endLocalTime"];
    }

    if (JSON.parse(localStorage.getItem("Sat_resturant_timings")) != null)
    {
      //var Sat_isClosed = JSON.parse(localStorage.getItem("Sat_resturant_timings"))["isClosed"];
    var Sat_Mindate = JSON.parse(localStorage.getItem("Sat_resturant_timings"))["startLocalTime"];
    var Sat_Maxdate = JSON.parse(localStorage.getItem("Sat_resturant_timings"))["endLocalTime"];
    }
    if (JSON.parse(localStorage.getItem("SUN_resturant_timings")) != null)
    {
      //var Sun_isClosed = JSON.parse(localStorage.getItem("SUN_resturant_timings"))["isClosed"];
    var Sun_Mindate = JSON.parse(localStorage.getItem("SUN_resturant_timings"))["startLocalTime"];
    var Sun_Maxdate = JSON.parse(localStorage.getItem("SUN_resturant_timings"))["endLocalTime"];
    }
    var max = JSON.parse(localStorage.getItem("resturant_timings"))["endLocalTime"];
    var closed = JSON.parse(localStorage.getItem("resturant_timings"))["isClosed"];
    var Msg = JSON.parse(localStorage.getItem("current_resturant_timings"))["closedDesc"];
    var day = JSON.parse(localStorage.getItem("current_resturant_timings"))["dayOfWeek"];
    var day1=localStorage.getItem("date_entered");
    var Day = this.datepipe.transform(day1, 'EEE').toUpperCase();
    var time24To12 = function (a) {
      return (new Date("1955-11-05T" + a + "Z")).toLocaleTimeString("bestfit", {
        timeZone: "UTC",
        hour12: !0,
        hour: "numeric",
        minute: "numeric"
      });
    };
    if (this.guest_info) {
      this.guest_info = [];
    }
    if( Day=="SAT" )
    {
      const alert = await this.alertController.create({
        cssClass: "my-custom-class",
        header: "Checkout failed!!",
        message:"We are Closed on Saturdays",
         
                 

        buttons: [
          {
            text: "OK",
            role: "OK",
            cssClass: "secondary",
            handler: (blah) => {
            },
          },
        ],
      });
      await alert.present();
    }
   
    else
    if(closed&& Msg!=null && Day==day )
    {
      const alert = await this.alertController.create({
        cssClass: "my-custom-class",
        header: "Checkout failed!!",
        message:Msg,
         
                 

        buttons: [
          {
            text: "OK",
            role: "OK",
            cssClass: "secondary",
            handler: (blah) => {
            },
          },
        ],
      });
      await alert.present();
    }
   
    else{
    if (this.cart_Cat == "BREAKFAST") {
      const alert = await this.alertController.create({
        cssClass: "my-custom-class",
        header: "Checkout failed!!",
        message:
         
         // "Breakfast items are available for pick-up during Breakfast hours of Mon - Fri from " + time24To12(this.min_date) + " to " + time24To12(environment.timing.Bf_Maxtime) + ". All non-breakfast items are available for pick-up Mon - Fri from " + time24To12(environment.timing.Lunch_mintime) + " - " + time24To12(max) + ", and Saturday  from " + time24To12(Sat_Mindate) + " to " + time24To12(Sat_Maxdate) +", and Sunday from " + time24To12(Sun_Mindate) + " to " + time24To12(Sun_Maxdate) +". Thank you for your order.",
          "Breakfast items are available for pick-up during Breakfast hours of Mon - Fri from " + time24To12(this.min_date) + " to " + time24To12(environment.timing.Bf_Maxtime) + ". All non-breakfast items are available for pick-up Mon - Fri from " + time24To12(environment.timing.Lunch_mintime) + " - " + time24To12(max) + ", and Sunday from " + time24To12(Sun_Mindate) + " to " + time24To12(Sun_Maxdate) +". Thank you for your order.",

        buttons: [
          {
            text: "OK",
            role: "OK",
            cssClass: "secondary",
            handler: (blah) => {
            },
          },
        ],
      });
      await alert.present();


    } else if (this.cart_Cat == "lunch") {
      const alert = await this.alertController.create({
        cssClass: "my-custom-class",
        header: "Checkout failed!!",
        message:
         "Delivery is available Mon-Fri from " + time24To12(this.min_date) + " - " + time24To12(this.max_date) + ". Please schedule your order during those hours. If you need a delivery time outside of those hours, please contact our catering manager at 832.640.5236. Thank you for your order. ",
        //"These items are available Mon - Fri between " + time24To12(this.min_date) + " to " + time24To12(this.max_date) + ". Please schedule during those hours. Thank you for your order.",
        // "These items are available for pick-up between "+time24To12(this.min_date)+" to "+time24To12(this.max_date)+". Please schedule your pick-up during those hours. Thank you for your order",

        buttons: [
          {
            text: "OK",
            role: "OK",
            cssClass: "secondary",
            handler: (blah) => {
            },
          },
        ],
      });
      await alert.present();
    } else {
      const alert = await this.alertController.create({
        cssClass: "my-custom-class",
        header: "Checkout failed!!",
        message:
        "Delivery is available Mon-Fri from " + time24To12(this.min_date) + " - " + time24To12(this.max_date) + ". Please schedule your order during those hours. If you need a delivery time outside of those hours, please contact our catering manager at 832.640.5236. Thank you for your order. ",
        // "Please schedule your order during business hours - Mon to Fri - " + time24To12(this.min_date) + " - " + time24To12(this.max_date) + ".",
        // "We are open. Please visit our restaurant on the corner of Dryden and Main to place your order.",
        //"Please schedule pick-up during business hours - Mon to Fri - "+time24To12(this.min_date)+" - "+time24To12(this.max_date)+"",
        buttons: [
          {
            text: "OK",
            role: "OK",
            cssClass: "secondary",
            handler: (blah) => {
            },
          },
        ],
      });
      await alert.present();
    }
  }

  }
  getElement() {
    
    var msgfor:any;
    if (JSON.parse(localStorage.getItem("cart_response")) != null) {
      var Olength = 0;
      length = 0;
      var menu = JSON.parse(localStorage.getItem("menu"));
      var cart = JSON.parse(localStorage.getItem("cart_response"));
      cart.forEach(function (cart) {

        var a = menu.filter(x => x.itemName == cart.item_name)
        a.forEach(function (a) {
          if (a.categoryName == "BREAKFAST" || a.categoryName == "SNACKS" || a.categoryName == "DESSERTS" || a.categoryName == "BEVERAGES") {
            if (a.categoryName == "BREAKFAST") {
              Olength = Olength + 1;
            }
            length = length + 1;

          }
        });

      });
      if (length == cart.length && Olength != 0) {
        this.cart_Cat = "BREAKFAST";
      }
      else if (Olength == 0 && length == cart.length) {
        this.cart_Cat = "";
      }
      else if (Olength == 0 && length != cart.length) {
        this.cart_Cat = "lunch";
      }

    }

    if (JSON.parse(localStorage.getItem("current_resturant_timings")) != null) {
      var current_period = new Date();
      if (this.cart_Cat == "BREAKFAST") {
        this.min_date = JSON.parse(localStorage.getItem("current_resturant_timings"))["startLocalTime"];// environment.timing.Bf_Mintime; 
        this.max_date = environment.timing.Bf_Maxtime;


      } else if (this.cart_Cat == "lunch") {
        this.min_date = environment.timing.Lunch_mintime;
        this.max_date = JSON.parse(localStorage.getItem("current_resturant_timings"))["endLocalTime"];// environment.timing.Lunch_maxtime; 
      } else {

        this.min_date = JSON.parse(localStorage.getItem("current_resturant_timings"))["startLocalTime"];
        this.max_date = JSON.parse(localStorage.getItem("current_resturant_timings"))["endLocalTime"];
      }
    } else {
      this.min_date = JSON.parse(localStorage.getItem("resturant_timings"))["startLocalTime"];
      this.max_date = JSON.parse(localStorage.getItem("resturant_timings"))["endLocalTime"];
    }
    if (JSON.parse(localStorage.getItem("Sat_resturant_timings")) != null)
    {
    var Sat_Mindate = JSON.parse(localStorage.getItem("Sat_resturant_timings"))["startLocalTime"];
    var Sat_Maxdate = JSON.parse(localStorage.getItem("Sat_resturant_timings"))["endLocalTime"];
    }
    if (JSON.parse(localStorage.getItem("SUN_resturant_timings")) != null)
    {
    var Sun_Mindate = JSON.parse(localStorage.getItem("SUN_resturant_timings"))["startLocalTime"];
    var Sun_Maxdate = JSON.parse(localStorage.getItem("SUN_resturant_timings"))["endLocalTime"];
    }
    var closed = JSON.parse(localStorage.getItem("resturant_timings"))["isClosed"];
    var Msg = JSON.parse(localStorage.getItem("resturant_timings"))["closedDesc"];
    var time24To12 = function (a) {
      //below date doesn't matter.
      return (new Date("1955-11-05T" + a + "Z")).toLocaleTimeString("bestfit", {
        timeZone: "UTC",
        hour12: !0,
        hour: "numeric",
        minute: "numeric"
      });
    };
    if(closed&& Msg!=null)
    {
      msgfor=Msg;
    }
    else{
    if (this.cart_Cat == "BREAKFAST")
     {
        
              msgfor = "Breakfast items are available for pick-up during Breakfast hours of Mon - Fri from " + time24To12(this.min_date) + " to " + time24To12(environment.timing.Bf_Maxtime) + ". All non-breakfast items are available for pick-up Mon - Fri from " + time24To12(environment.timing.Lunch_mintime) + " - " + time24To12(environment.timing.Lunch_maxtime) + ", and Sunday from " + time24To12(Sun_Mindate) + " to " + time24To12(Sun_Maxdate) +". Thank you for your order.";
            
      
      }
       else if (this.cart_Cat == "lunch") 
       {
            msgfor= "Delivery is available Mon-Fri from " + time24To12(this.min_date) + " - " + time24To12(this.max_date) + ". Please schedule your order during those hours. If you need a delivery time outside of those hours, please contact our catering manager at 832.640.5236. Thank you for your order. ";
             // msgfor = "These items are available Mon - Fri between " + time24To12(this.min_date) + " to " + time24To12(this.max_date) + ". Please schedule your order during those hours. Thank you for your order.";
        }
            
            else {
              msgfor= "Delivery is available Mon-Fri from " + time24To12(this.min_date) + " - " + time24To12(this.max_date) + ". Please schedule your order during those hours. If you need a delivery time outside of those hours, please contact our catering manager at 832.640.5236. Thank you for your order. ";
             
      //  msgfor = "Please schedule your order during business hours - Mon to Fri - " + time24To12(this.min_date) + " - " + time24To12(this.max_date) + ".";
     
    }
  }

    return msgfor;
  }

 

  
  async prepareFulfilment(value) {
    debugger
    const now = moment();
    const chosenDateTime = moment(this.date_entered);
    const diffHours = chosenDateTime.diff(now, 'hours');

    // this.checkOrderEligibility(chosenDateTime);
    var a = moment(chosenDateTime).format("MMMM DD, h:mm a");
    console.log(a,"chosen date")
  
   
    if (a.includes("November 21")) {
      
      const alert = await this.alertController.create({
        cssClass: "my-custom-class",
        header: "Call Us For Delivery",
        message: "We are unable to accept online orders during this time. Please call our catering managers directly at 832.640.5236.",
        buttons: [
          {
            text: "OK",
            role: "OK",
            cssClass: "secondary",
            handler: () => {
              
            },
          },
        ],
      });
      await alert.present();
      return false;
    }

    if (diffHours < 24) {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Checkout failed!!',
        message: 'For deliveries within 24 hours, please call our Catering Managers at 832.240.5236.',
        buttons: [
          {
            text: 'OK',
            role: 'OK',
            cssClass: 'secondary',
            handler: () => {},
          },
        ],
      });
      await alert.present();
      return false;
    }

    if (JSON.parse(localStorage.getItem("cart_response")) != null) {
      var Olength = 0;
      length = 0;
      var menu = JSON.parse(localStorage.getItem("menu"));
      var cart = JSON.parse(localStorage.getItem("cart_response"));
      cart.forEach(function (cart) {

        var a = menu.filter(x => x.itemName == cart.item_name)
        a.forEach(function (a) {
          if (a.categoryName == "BREAKFAST" || a.categoryName == "SNACKS" || a.categoryName == "DESSERTS" || a.categoryName == "BEVERAGES") {
            if (a.categoryName == "BREAKFAST") {
              Olength = Olength + 1;
            }
            length = length + 1;

          }
        });

      });
      if (length == cart.length && Olength != 0) {
        this.cart_Cat = "BREAKFAST";
      }
      else if (Olength == 0 && length == cart.length) {
        this.cart_Cat = "";
      }
      else if (Olength == 0 && length != cart.length) {
        this.cart_Cat = "lunch";
      }



    }

    if (JSON.parse(localStorage.getItem("current_resturant_timings")) != null) {
      if (this.cart_Cat == "BREAKFAST") {
        this.min_date = JSON.parse(localStorage.getItem("current_resturant_timings"))["startLocalTime"]; //environment.timing.Bf_Mintime;
        this.max_date = environment.timing.Bf_Maxtime;


      } else if (this.cart_Cat == "lunch") {
        this.min_date = environment.timing.Lunch_mintime;
        this.max_date =JSON.parse(localStorage.getItem("current_resturant_timings"))["endLocalTime"]; //environment.timing.Lunch_maxtime;
      } else {

        this.min_date = JSON.parse(localStorage.getItem("current_resturant_timings"))["startLocalTime"];
        this.max_date = JSON.parse(localStorage.getItem("current_resturant_timings"))["endLocalTime"];
      }
    } else {
      this.min_date = JSON.parse(localStorage.getItem("resturant_timings"))["startLocalTime"];
      this.max_date = JSON.parse(localStorage.getItem("resturant_timings"))["endLocalTime"];
    }



    var msgfor = "Sorry, we are currently closed. Please schedule your pick-up during business hours - M-F from " + this.min_date + " - " + this.max_date + ". Thank you for your order. "
    this.cart_response = localStorage.getItem("cart_response")
      ? JSON.parse(localStorage.getItem("cart_response"))
      : [];
    var data = localStorage.getItem("cart_subtotal")
      ? JSON.parse(localStorage.getItem("cart_subtotal"))
      : "";
    // if (data) {
    //   debugger;
    //   this.total_amount=data.subtotal.amount;
    //   this.cart_subtotal =
    //    parseFloat(data.subtotal.amount) + this.total_amount * (1 + 0.0825) * (1 + 0.10) - this.total_amount;
    // }
    if (data) {
      debugger;
      this.total_amount = parseFloat(data.subtotal.amount);
      
      // Calculate tax at 18.25% and round it to two decimal places
      const taxAmount = Math.round(this.total_amount * 0.1825 * 100) / 100;
    
      // Add tax to subtotal
      this.cart_subtotal = this.total_amount + taxAmount;
    }
    if (this.cart_subtotal<250)
    {
      const alert = await this.alertController.create({
        cssClass: "my-custom-class",
        header: "Checkout failed!!",
        message:
        "A minimum order of $250 is required for delivery. For pick-up, please call our catering managers directly at 832.640.5236.",
          // "We are open. Please visit our restaurant on the corner of Dryden and Main to place your order.",
        //"Please schedule pick-up during business hours - Mon to Fri - "+time24To12(this.min_date)+" - "+time24To12(this.max_date)+"",
        buttons: [
          {
            text: "OK",
            role: "OK",
            cssClass: "secondary",
            handler: (blah) => {
            },
          },
        ],
      });
      await alert.present();
          return false;
    }
   
    if (value == "asap") {
      
      this.time_chosen_for_pick_up = value;
      this.schedule_type = this.time_chosen_for_pick_up;
      localStorage.setItem("time_chosen_for_pick_up", this.time_chosen_for_pick_up);
      this.date_entered = new Date();
      this.date_entered.setMinutes(this.date_entered.getMinutes() + 15);
      this.schedule_time = this.date_entered;
      let latest_date = this.datepipe.transform(this.schedule_time, 'MMMM dd, h:mm a');
      this.schedule_time = latest_date;
      localStorage.setItem("schedule_time", this.schedule_time);
    }
    var vm = this;
    if (this.user_info) {

      ShippedAt:this.user_account.value.shipped_at
      

    }
    else {

      debugger
      await vm.guestUserInfo();
      vm.guest_info = localStorage.getItem("guest_user_form_value")
        ? JSON.parse(localStorage.getItem("guest_user_form_value"))
        : "";
    }
   this.isClosed=false;
    localStorage.setItem("date_entered", this.date_entered);
    this.schedule_time = moment(this.date_entered).format("MMMM DD, h:mm a");
    var time = this.datepipe.transform(this.date_entered, 'yyyy-MM-dd');
    var Day = this.datepipe.transform(this.date_entered, 'EEE').toUpperCase();
    var schedule_period = this.datepipe.transform(value == "asap" ? this.date_entered.setMinutes(this.date_entered.getMinutes() - 15) : this.date_entered, 'HH:mm');
    var day = JSON.parse(localStorage.getItem("current_resturant_timings"))["dayOfWeek"];
    var closed = JSON.parse(localStorage.getItem("current_resturant_timings"))["isClosed"];
    if(Day=="SAT")
    {
      this.isClosed= JSON.parse(localStorage.getItem("Sat_resturant_timings"))["isClosed"];
      this.min_date = JSON.parse(localStorage.getItem("Sat_resturant_timings"))["startLocalTime"];
      this.max_date = JSON.parse(localStorage.getItem("Sat_resturant_timings"))["endLocalTime"];
    }
    if(Day =="SUN")
    {
      
      this.isClosed= JSON.parse(localStorage.getItem("SUN_resturant_timings"))["isClosed"];
      this.min_date = JSON.parse(localStorage.getItem("SUN_resturant_timings"))["startLocalTime"];
      this.max_date = JSON.parse(localStorage.getItem("SUN_resturant_timings"))["endLocalTime"];
    }


    if ( Day!=day&& !this.isClosed ) {
      
        if (!(schedule_period > this.min_date && schedule_period < this.max_date)) {
          vm.move_to_payment = false;
          await this.presentAlertForPickup();
        }
        else {
          vm.move_to_payment = true;
        }
      

    }
    else if (!closed && Day==day)  {

      if (!(schedule_period > this.min_date && schedule_period < this.max_date)) {
        vm.move_to_payment = false;
        await this.presentAlertForPickup();
      }
      else {
        vm.move_to_payment = true;
      }
    }
    else{
      vm.move_to_payment = false;
      await this.presentAlertForPickup();
    }
  }
  async prepareFulfilment_ready_to_hit() {
    debugger
    var vm = this;
    await vm.util.presentLoading();
    var location_id = JSON.parse(
      localStorage.getItem("current_resturant_data")
    )["id"];
    var order_id = localStorage.getItem("order_id");
    if (this.date_entered) {
      if (order_id) {
        if (location_id) {
          var fulfilment_values: any;
          if ((this.time_chosen_for_pick_up == "schedule")) {
            fulfilment_values = {
              Type: 2,
              FulfillmentState: 0,
              ScheduleType: 1,
              ShipmentAt: moment(this.date_entered).format(
                "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
              ),

              RecipientName: this.user_info
                ? this.user_info.firstName
                : this.guest_user_account.value.first_name,
              Email: this.user_info
                ? this.user_info.email
                : this.guest_user_account.value.email,
                PhoneNumber:this.user_info?this.user_info.PhoneNumber :this.guest_info.PhoneNumber,
                Address:this.user_info?this.user_info.shipped_at :this.guest_info.shipped_at,
            };
          } else {
            fulfilment_values = {
              Type: 2,
              FulfillmentState: 0,
              ScheduleType: 1,
              ShipmentAt: moment(this.date_entered).format(
                "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
              ),
              RecipientName: this.user_info
                ? this.user_info.firstName
                : this.guest_user_account.value.first_name,
              Email: this.user_info
                ? this.user_info.email
                : this.guest_user_account.value.email,
                PhoneNumber:this.user_info?this.user_info.PhoneNumber :this.guest_info.PhoneNumber,
                Address:this.user_info?this.user_info.shipped_at :this.guest_info.shipped_at,
            };
          }

          var p = {
            OrderId: order_id,
            LocationId: location_id,
            Fulfillment: { ...fulfilment_values },
          };

          var guest_user_info = this.user_info
            ? ""
            : JSON.parse(localStorage.getItem("guest_info"));
          vm.resturant_service.createOrderWithoutUserInfo(p).subscribe(
            (data_2: any) => {
              vm.util.dismissLoader();
              vm.move_to_payment = true;
              vm.schedule_time = localStorage.getItem("date_entered");
              vm.cart_response = localStorage.getItem("cart_response")
                ? JSON.parse(localStorage.getItem("cart_response"))
                : [];
              var data = localStorage.getItem("cart_subtotal")
                ? JSON.parse(localStorage.getItem("cart_subtotal"))
                : 0;
              // if (data) {
              //   debugger;
              //   this.total_amount=data.subtotal.amount;
              //   this.cart_subtotal =
              //   parseFloat(data.subtotal.amount) + this.total_amount * (1 + 0.0825) * (1 + 0.10) - this.total_amount;
              // }
              if (data) {
                debugger;
                this.total_amount = parseFloat(data.subtotal.amount);
                
                // Calculate tax at 18.25% and round it to two decimal places
                const taxAmount = Math.round(this.total_amount * 0.1825 * 100) / 100;
              
                // Add tax to subtotal
                this.cart_subtotal = this.total_amount + taxAmount;
              }
              vm.guest_info = guest_user_info;
              localStorage.setItem("fulfilment_data", JSON.stringify(data_2));
            },
            (error) => {
              debugger;
              vm.util.dismissLoader();
              vm.util.showErrorMessage(error);
            }
          );
        }
      } else {
        vm.util.dismissLoader();
        vm.util.showErrorMessage("Your Basket is empty");
      }
    } else {
      vm.util.dismissLoader();
      vm.util.showErrorMessage("No date is selected to place the order");
    }
  }
  
  editUserInfo(user_type) {
    var vm = this;
    if (user_type == "guest_user") {
      this.move_to_payment = false;
      this.guest_info = "";
      localStorage.removeItem('guest_user_form_value')
      localStorage.removeItem('date_entered')
      localStorage.removeItem('guest_info')
      localStorage.removeItem('time_chosen_for_pick_up')
      
    }
    if (user_type == "register_user") {
      this.payment_status = "";
      this.move_to_payment = false;
    }
  }
  async guestUserInfo() {
    debugger
    var vm = this;
    return new Promise(async (resolve, reject) => {
      try {
        debugger
        var data = {
          FirstName: this.guest_user_account.value.first_name,
          LastName: this.guest_user_account.value.last_name,
          Email: this.guest_user_account.value.email,
          PhoneNumber: this.guest_user_account.value.phone_number,
          IsGuest: "true",
          ShippedAt:this.guest_user_account.value.shipped_at,
        
        

        };
        localStorage.setItem("guest_user_form_value", JSON.stringify(data));
        vm.guest_user_form_value = data;
        resolve("success")
      }
      catch (error) {
        reject("error")
      }
    });
  }
  openSignUpForm() {
    this.form_name = "register";
    this.open_thanks_for_signing = false;
    this.form_name_displayed = "Registration";
  }
  openLoginForm() {
    this.form_name = "login";
    this.open_thanks_for_signing = false;
  }
  openCheckOutForm() {
    debugger
    if (JSON.parse(localStorage.getItem("cart_response")) != null) {
      var Olength = 0;
      length = 0;
      var menu = JSON.parse(localStorage.getItem("menu"));
      var cart = JSON.parse(localStorage.getItem("cart_response"));
      cart.forEach(function (cart) {

        var a = menu.filter(x => x.itemName == cart.item_name)
        a.forEach(function (a) {
          if (a.categoryName == "BREAKFAST" || a.categoryName == "SNACKS" || a.categoryName == "DESSERTS" || a.categoryName == "BEVERAGES") {
        

            if (a.categoryName == "BREAKFAST") {
              Olength = Olength + 1;
            }
            length = length + 1;

          }
        });

      });
      if (length == cart.length && Olength != 0) {
        this.cart_Cat = "BREAKFAST";
      }
      else if (Olength == 0 && length == cart.length) {
        this.cart_Cat = "";
      }
      else if (Olength == 0 && length != cart.length) {
        this.cart_Cat = "lunch";
      }



    }
    if (JSON.parse(localStorage.getItem("current_resturant_timings")) != null) {
      var current_period = new Date();
      if (this.cart_Cat == "BREAKFAST") {
        this.min_date = JSON.parse(localStorage.getItem("current_resturant_timings"))["startLocalTime"]; //environment.timing.Bf_Mintime;
        this.max_date = environment.timing.Bf_Maxtime;


      } else if (this.cart_Cat == "lunch") {
        this.min_date = environment.timing.Lunch_mintime;
        this.max_date = JSON.parse(localStorage.getItem("current_resturant_timings"))["endLocalTime"]; //environment.timing.Lunch_maxtime; 
      } else {
        this.min_date = JSON.parse(localStorage.getItem("current_resturant_timings"))["startLocalTime"];
        this.max_date = JSON.parse(localStorage.getItem("current_resturant_timings"))["endLocalTime"];
      }
      var current_time = this.datepipe.transform(current_period, 'HH:mm');
      var time = this.datepipe.transform(current_period, 'yyyy-MM-dd');
           var closed = JSON.parse(localStorage.getItem("current_resturant_timings"))["isClosed"];


      if (!closed) 
      {
          
          if (current_time > this.min_date && current_time < this.max_date) {
            this.pickup_abled = true;
          }
          else {
            this.pickup_abled = false;
          }
        
        

      }
      else {
        this.pickup_abled = false;
      }

    }
    else {
      this.pickup_abled = false;
    }
  
    // if (JSON.parse(localStorage.getItem("current_resturant_timings")) != null) {
    //   var current_period = new Date();
    //   var min_date = JSON.parse(localStorage.getItem("current_resturant_timings"))["startLocalTime"];
    //   var max_date = JSON.parse(localStorage.getItem("current_resturant_timings"))["endLocalTime"];
    //   var current_time = this.datepipe.transform(current_period, 'HH:mm');
    //   if (current_time > min_date && current_time < max_date) {
    //     this.pickup_abled = true;
    //   }
    //   else {
    //     this.pickup_abled = false;

    //   }
    // }
    // else {
    //   this.pickup_abled = false;
    // }
    var cart_response = localStorage.getItem("cart_response")
      ? JSON.parse(localStorage.getItem("cart_response"))
      : [];
    if (cart_response && cart_response.length > 0) {
      // this.maxDate.setDate( new Date().getDate()+ 15)
      // this.minDate.setDate( new Date().getDate() )
      const now = new Date();
      
      const targetDate = new Date(2024, 10, 11);
    
    
      if (now < targetDate) {
  
        this.minDate = targetDate;
      } else {
      
        now.setDate(now.getDate() + 1);
     
        this.minDate= now;
  
      }
      
      
   
      // November is represented as 10 because months are zero-based
      this.maxDate = new Date(2024, 10, 26); 
      // this.minDate = new Date(now.setDate(now.getDate() + 1));
      // this.minDate = new Date(now.setDate(now.getDate() + 1));
      // this.maxDate = new Date(now.setDate(now.getDate() + 7));
      this.form_name = "check-out";
      this.form_name_displayed = "Checkout";
    }
    else {
      this.presentEmptyBasketAlert();
    }
  }
  async presentEmptyBasketAlert() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "Checkout failed!!",
      message:
        "Your basket is empty.",
      buttons: [
        {
          text: "OK",
          role: "OK",
          cssClass: "secondary",
          handler: (blah) => {
          },
        },
      ],
    });

    await alert.present();
  }
  openForgotPassword() {
    this.form_name = "forgot_password";
    this.form_name_displayed = "Forgot Password";
  }
  getLoyaltyAccount(customer_id)
  {
     this.resturant_service.getLoyaltyAccount(customer_id).subscribe(
       (data)=>{
         if(data)
         {
         this.account_info=data;
         }
       }
     )
  }
  callPrograms()
  {
    var vm = this;
    vm.util.presentLoading();
    this.resturant_service.getLoyaltyPrograms().subscribe(
      (data)=>{
        if(data.length == 0)
        {
          vm.rewards= [];
          vm.rewards_available=false;
          vm.util.dismissLoader(true);
        }
        else{
          vm.rewards = [];
          vm.rewards_available=false;
          if(data.length > 0)
          {
            vm.rewards.push(...data);
            this.skip=true;
            vm.util.dismissLoader(true);
          }
        }
      },
      (error)=>{
        vm.rewards_error = true;
        vm.rewards_available=false;
        vm.snackBar.open("Error while retrieving rewards", "Done", {
          duration: 2000,
        });
        vm.util.dismissLoader(true);
      }
    )
  }
  callRecentOrders() {
    var vm = this;
    vm.util.presentLoading();
    this.resturant_service.getRecentOrders().subscribe(
      (data) => {
        if (data.length == 0) {
          vm.recent_orders = [];
          vm.recent_order_available = false;
          vm.util.dismissLoader(true);
        } else {
          vm.recent_orders = [];

          if (data.length > 0) {
            vm.recent_orders.push(...data);
            this.skip=true;
            vm.recent_order_available = false;
            vm.util.dismissLoader(true);
          }
        }
      },
      (error) => {
        vm.recent_order_error = true;
        vm.recent_order_available = false;
        vm.snackBar.open("Error while retrieving recent orders", "Done", {
          duration: 2000,
        });
        vm.util.dismissLoader(true);
      }
    );

  }

  ngOnInit() {
   debugger
    var vm = this;
  
   // var Sun_Maxdate = JSON.parse(localStorage.getItem("SUN_resturant_timings"))["endLocalTime"]
    var location = JSON.parse(localStorage.getItem("current_resturant_data"))["id"];
     vm.resturant_service
        .getCompleteMenuByLocation(location)
        .subscribe(
          async (resturant_menu: any = []) => {
            

            // vm.subject_service.getLocationItems(resturant_menu);
            vm.complete_menu = [...resturant_menu];
           
          },
          (error) => {
            reject(error);
          }
        );
    this.guest_info = localStorage.getItem("guest_info")
      ? JSON.parse(localStorage.getItem("guest_info"))
      : "";
    if (this.guest_info) {
      this.move_to_payment = true;
    }
    else {
      this.move_to_payment = false;
    }
    this.aFormGroup = this.formBuilder.group({
      recaptcha: ["", Validators.required],
    });
    
    // const now_date = new Date();
     const now = new Date();
    
     const targetDate = new Date(2024, 10, 11);
    
    
     if (now < targetDate) {
       this.date_entered = targetDate;
       this.minDate = targetDate;
     } else {
     
       now.setDate(now.getDate() + 1);
       this.date_entered = now;
       this.minDate= now;
 
     }
    // this.date_entered = new Date(now_date.setDate(now.getDate() + 1));
    //this.date_entered = new Date(2023, 10, 6);
    //this.minDate =new Date(now_date.setDate(now.getDate() + 1)); // November is represented as 10 because months are zero-based
    this.maxDate = new Date(2024, 10, 27); 




   

    
    










    // this.minDate = new Date(now.setDate(now.getDate() + 11));
    // debugger;
    // this.maxDate = new Date(now.setDate(now.getDate() + 16));
  }

 

  ngAfterViewInit() {
    this.guest_info = localStorage.getItem("guest_info")
      ? JSON.parse(localStorage.getItem("guest_info"))
      : "";
    if (this.guest_info) {
      this.move_to_payment = true;
    } else {
      this.move_to_payment = false;
    }
    setTimeout(() => {
      this.form_name == "register"
        ? (this.form_name_displayed = "Registration")
        : (this.form_name_displayed = "Login");
    }, 200);
  }
  handleError() {

  }
  handleSuccess(ev) {
    this.token_value = ev;
  }
  dimissFormPage() {
    if (this.check_out_page_calling == "true") {
    } else {
      this.modalCtrl.dismiss();
    }
  }
  ngOnChanges() {
    this.skip=false;
    var vm = this;
    if (this.user_info && this.form_name == 'orders') {

      this.callRecentOrders();
    }
    if (this.user_info && this.form_name == 'rewards') {

  
      this.callPrograms();
      var customer_id=this.user_info.squareCustomerid;
    
      this.getLoyaltyAccount(customer_id);

    }
    this.warning_presence = false;
    this.login_warning = false;
    if (!this.user_info) {
      this.move_to_payment = false;
      this.guest_info = null;
      this.date_entered = null;
      this.guest_user_account.reset();
    }
    else {
      this.payment_status = null;
      this.move_to_payment = false;
    }
    this.open_thanks_for_signing = this.thanks_giving_form_display;
    this.forgot_password.reset();
    this.login_form.reset();
    this.register_form.reset();
    this.schedule_type = localStorage.getItem("time_chosen_for_pick_up");
    if (this.schedule_type == "asap") {
      this.schedule_time = localStorage.getItem("schedule_time");
    }
    else {
      this.schedule_time = moment(this.schedule_time).format(
        "MMMM DD, h:mm:ss a");
      this.schedule_time = localStorage.getItem("date_entered");
    }

    this.guest_user_form_value = localStorage.getItem("guest_user_form_value")
      ? JSON.parse(localStorage.getItem("guest_user_form_value"))
      : "";
  }

  async loginPageCalled() {
    if (this.check_out_page_calling == "true") {
      this.form_name = "login";
    } else {
      this.dimissFormPage();
      const modal = await this.modalCtrl.create({
        component: RegistrationFormComponent,
        cssClass: "my-modal",
        componentProps: {
          form_name: "login",
        },
      });
      return await modal.present();
    }
  }
  removeByAttr(arr, attr, value) {
    var i = arr.length;
    while (i--) {
      if (
        arr[i] &&
        arr[i].hasOwnProperty(attr) &&
        arguments.length > 2 &&
        arr[i][attr] === value
      ) {
        arr.splice(i, 1);
      }
    }
    return arr;
  }
  async presentAlert() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "CheckOut failed",
      message: "You have to login prior to adding items to cart",
      buttons: [
        "OK",
        {
          text: "Login",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            this.loginOrRegistrationButtonClicked("login");
          },
        },
      ],
    });
    await alert.present();
  }
  clean(obj) {
    var vm = this;
    for (var propName in obj) {
      if (
        obj[propName] == null ||
        obj[propName] == "" ||
        obj[propName] == undefined
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }
  afterGettingResponseFromOrderApiWithoutUser(data) {

    return new Promise((resolve, reject) => {
      try {
        let vm = this;
        let data_got: any = [];
        let subtotal: any = null;
        if (data) {
          debugger;
          if (data.subTotal) {
            subtotal = {
              subtotal: {
                amount: data.subTotal,
                currency: "USD",
              },
              tax: {
                amount: data.totalTax*(0.19075/0.1825),
                currency: "USD",
              },
            };
          }

          if (data.orderDetails) {
            data.orderDetails.map((e) => {
              let p: any = [];
              let taxes: any = [];
              var total_money: any = 0;
              if (
                e.orderDetailCustomizations &&
                e.orderDetailCustomizations.length > 0
              ) {
                e.orderDetailCustomizations.map((f) => {
                  p.push({
                    name: f.customizationOptionName,
                    catalog_id: f.itemCustomizationOptionId,
                    customizationOptionPrice: f.customizationOptionPrice
                      ? f.customizationOptionPrice
                      : null,
                    customizationOptionVariationName: f.customizationOptionVariationName
                      ? f.customizationOptionVariationName
                      : null,
                    uid: f.uid,
                    amount: f.customizationOptionPrice,
                  });
                  total_money =
                    total_money + parseFloat(f.customizationOptionPrice);
                });
              }
              if (e.orderDetailTaxes && e.orderDetailTaxes.length > 0) {
                e.orderDetailTaxes.map((g) => {
                  taxes.push({
                    OrderDetailTaxId: g.orderDetailTaxId,
                  });
                });
              }
              data_got.push({
                variation_price: e.variationPrice,
                variation_currency: "USD",
                variation_name: e.variationName,
                uid: e.uid,
                catalog_id: e.variationId,
                item_name: e.itemName,
                quantity: e.quantity,
                amount: JSON.stringify(
                  JSON.parse(e.quantity) *
                  (JSON.parse(e.variationPrice) + total_money)
                ),
                currency: "USD",
                customisation: p,
                tax_id: taxes,
              });
            });
          }
        }
        localStorage.setItem("order_id", data["orderId"]);
        vm.subject_service.getcartReponse(data_got);
        localStorage.setItem("cart_response", JSON.stringify(data_got));
        localStorage.setItem("cart_subtotal", JSON.stringify(subtotal));//subtotal
        vm.subject_service.getSubtotalReponse(subtotal);
        vm.subject_service.getWaitingForSubtotal(false);
        vm.subject_service.getWaitingForCart(false);
        resolve("success");
      } catch (error) {
        reject("error");
      }
    });
  }
  async replaceOrder(data_related_to_order_selescted) {
    debugger
    var vm = this;
    this.time_chosen_for_pick_up = "asap";
    
    var menu=vm.complete_menu;
    var details = data_related_to_order_selescted.orderDetails;
    var not_sold : any=[];
       details.forEach(function (detail) {
         
       var a = menu.filter(x => x.itemName == detail.itemName);
      if(a[0].isSold==false )
      {
        not_sold.push(detail);
      }
    });
   if(not_sold.length==0)
   {
this.alertMessage("Sold out!!","Item is sold out.",0);
   }
   else{
    var vm = this;
    vm.util.presentLoading();
    var order_detail: any = [];
    var all_taxes: any = [];
    order_detail = await not_sold.map(
      (e) => {
        var ids: any = [];
        var taxes: any = [];
        if (
          e.orderDetailCustomizations &&
          e.orderDetailCustomizations.length > 0
        ) {
          for (let y = 0; y < e.orderDetailCustomizations.length; y++) {
            ids.push({
              ItemCustomizationOptionId:
                e.orderDetailCustomizations[y].itemCustomizationOptionId,
            });
          }
        }
        if (e.orderDetailTaxes && e.orderDetailTaxes.length > 0) {
          for (let j = 0; j < e.orderDetailTaxes.length; j++) {
            taxes.push({
              OrderDetailTaxId: e.orderDetailTaxes[j].orderDetailTaxId,
            });
          }
        }
        var data = {
          VariationId: e.variationId,
          Quantity: e.quantity,
          OrderDetailTaxes: [...taxes],
          OrderDetailCustomizations: [...ids],
        };
        return data;
      }
    );
    order_detail.map((f) => {
      f.OrderDetailTaxes.map((g) => {
        all_taxes.push({ OrderDetailTaxId: g.OrderDetailTaxId });
      });
    });
    var result = all_taxes.reduce((unique, o) => {
      if (!unique.some((obj) => obj.OrderDetailTaxId == o.OrderDetailTaxId)) {
        unique.push(o);
      }
      return unique;
    }, []);
    var all_taxes_filtered: any;
    all_taxes_filtered = result.map((e) => {
      return { TaxId: e.OrderDetailTaxId };
    });
    this.router
      .navigate([`/ordering-system`])
      .then(async () => {
        vm.subject_service.getWaitingForCart(true);
        vm.subject_service.getWaitingForSubtotal(true);
        vm.menu.open("cart");
        if (localStorage.getItem("user_info")) {
          let order_id = localStorage.getItem("order_id");
          if (order_id) {
            if (localStorage.getItem("cart_response")) {
              let get_local_storage_items = JSON.parse(localStorage.getItem("cart_response"));
              let item_id: any = [];
              get_local_storage_items.map((e) => {
                let val = "line_items[" + e.uid + "]";
                item_id.push(val);
              });
              var remove_data = {
                OrderId: order_id,
                LocationId: JSON.parse(
                  localStorage.getItem("current_resturant_data")
                )["id"],
                FieldsToClear: [...item_id],
              };
            }
            vm.resturant_service.createOrderWithoutUserInfo(remove_data).subscribe(
              async (data) => {
                localStorage.removeItem("order_id");
                if (localStorage.getItem("fulfilment")) {
                  localStorage.removeItem("fulfilment");
                  vm.subject_service.getFulfilment(false);
                }
                var item_add_to_cart = {
                  LocationId: data_related_to_order_selescted.locationId,
                  OrderDetails: [...order_detail],
                  Taxes: [...all_taxes_filtered],
                };
                var p = await vm.clean(item_add_to_cart);
                p.OrderDetails = p.OrderDetails.map((e) => {
                  return vm.clean(e);
                });
                if (p.Taxes) {
                  p.Taxes.TaxId = await vm.removeByAttr(p.Taxes, "TaxId", "");
                }
                vm.resturant_service.createOrderWithoutUserInfo(p).subscribe(
                  (data: any) => {
                    vm.util.dismissLoader();
                    vm.afterGettingResponseFromOrderApiWithoutUser(data);
                  },
                  (error) => {
                    vm.util.dismissLoader();
                    vm.snackBar.open(error, "Done", {
                      duration: 2000,
                    });
                    vm.subject_service.getWaitingForCart(false);
                    vm.subject_service.getWaitingForSubtotal(false);
                  }
                );
              },
              (error) => {
                vm.util.dismissLoader();
                vm.snackBar.open(error, "Done", {
                  duration: 2000,
                });
                vm.subject_service.getWaitingForCart(false);
                vm.subject_service.getWaitingForSubtotal(false);
              }
            );
          } else {
            var item_add_to_cart = {
              LocationId: data_related_to_order_selescted.locationId,
              OrderDetails: [...order_detail],
              Taxes: [...all_taxes_filtered],
            };
            var p = await vm.clean(item_add_to_cart);
            p.OrderDetails = p.OrderDetails.map((e) => {
              return vm.clean(e);
            });
            if (p.Taxes) {
              p.Taxes.TaxId = await vm.removeByAttr(p.Taxes, "TaxId", "");
            }
            vm.resturant_service.createOrderWithoutUserInfo(p).subscribe(
              (data: any) => {
                vm.util.dismissLoader();
                vm.afterGettingResponseFromOrderApiWithoutUser(data);
              },
              (error) => {
                vm.util.dismissLoader();
                vm.snackBar.open(error, "Done", {
                  duration: 2000,
                });
                vm.subject_service.getWaitingForCart(false);
                vm.subject_service.getWaitingForSubtotal(false);
              }
            );
          }
        } else {
          vm.presentAlert();
        }
      });
      if(not_sold.length!=details.length)
      {
      this.alertMessage("Sold out!!","One or more items are sold out which have not been added in the cart.",3500);
      }
    }
  }
}
function reject(error: any) {
  throw new Error("Function not implemented.");
}

