import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';
import { DomController } from '@ionic/angular';

@Directive({
	// tslint:disable-next-line: indent
	selector: '[appParallexHeader]'
})
export class ParallexHeaderDirective {

	@Input('parallaxHeader') imagePath: string;
	// tslint:disable-next-line: indent
	// tslint:disable-next-line: no-input-rename
	@Input('parallaxHeight') parallaxHeight: number;

	private headerHeight: number;
	// tslint:disable-next-line: indent
	private header: HTMLDivElement;
	// tslint:disable-next-line: indent
	private mainContent: HTMLDivElement;

	constructor(private element: ElementRef, private renderer: Renderer2, private domCtrl: DomController) {
		console.log("parallexheader entered")
	}

	ngAfterViewInit() {

		this.headerHeight = this.parallaxHeight;
		this.mainContent = this.element.nativeElement.querySelector('.main-content');

		this.domCtrl.write(() => {

			this.header = this.renderer.createElement('div');

			this.renderer.insertBefore(this.element.nativeElement, this.header, this.element.nativeElement.firstChild);

			this.renderer.setStyle(this.header, 'background-image', 'url(' + this.imagePath + ')');
			this.renderer.setStyle(this.header, 'height', this.headerHeight + 'px');
			this.renderer.setStyle(this.header, 'background-size', 'contain');

		});

	}

	onContentScroll(ev) {
this.domCtrl.read(() => { let translateAmt, scaleAmt;

			// Already scrolled past the point at which the header image is visible
			if (ev.detail.scrollTop > this.parallaxHeight) {
				return;
			}

			if (ev.detail.scrollTop >= 0) {
				translateAmt = -(ev.detail.scrollTop / 2);
				scaleAmt = 1;
			} else {
				translateAmt = 0;
				scaleAmt = -ev.detail.scrollTop / this.headerHeight + 1;
			}

			this.domCtrl.write(() => {
				this.renderer.setStyle(this.header, 'transform', 'translate3d(0,' + translateAmt + 'px,0) scale(' + scaleAmt + ',' + scaleAmt + ')');
				this.renderer.setStyle(this.mainContent, 'transform', 'translate3d(0, ' + (-ev.detail.scrollTop) + 'px, 0');
			});

	// tslint:disable-next-line: indent
	
		});



	}
}
