import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  forwardRef,
} from "@angular/core";
import { FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { pipe } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { OptionsService } from "src/app/service/options.service";

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DynamicFormComponent),
  multi: true,
};
@Component({
  selector: "app-dynamic-form",
  templateUrl: "./dynamic-form.component.html",
  styleUrls: ["./dynamic-form.component.scss"],
})
export class DynamicFormComponent implements OnInit {
  // @Output() formStatusChanged = new EventEmitter<boolean>();
  @Input() checkedCust: any;
  @Input() custName: any;
  @Input() questions?: any = [];
  @Input() options?: any = [];
  @Input() present_as? :any =[];
  @Input() selectionType?: any;
  @Input() id_of_item: any;
  @Input() selected_complete_customisation?: any = [];
  @Input() selection_count: any;
  @Input() selectedOption: any;
  @Input() calculate_warning?: any;
  @Input() form_name: any = FormGroup;
  @Input() options_already_selected?: any = [];
  @Output() disable_add_to_cart= new EventEmitter();
  @Output() form_values = new EventEmitter();
  @Output() customise_variation_name = new EventEmitter();
  @Output() optionSelectedMain = new EventEmitter<boolean>();
  @Output() optionSelectedSide = new EventEmitter<boolean>();
  public form: FormGroup;
  public warning_message: any;
  public less_than_required :any;
  public radio_button_selected: any;
  public payLoad = "";
  public initialized: any;
  public form_value_for_color_selection: any;
  constructor(public qcs: OptionsService) {
    this.initialized = false;
  }
  valueSelectedByRadioButton(ev) {
    
  }
  ngOnInit() {
   this.load();
  }
  onProfileChange(ev?) {
    if (ev) {
      const selectedItemId = ev.target.id;
      var keys: any = [];
      keys = Object.keys(this.form.value);
      var obj: any = {};
      keys.map((e) => {
        if (e == ev["target"]["value"]) {
          obj[e] = "true";
        } else {
          obj[e] = false;
        }
      });
      this.valueChanged();
      this.form.setValue(obj);
      if (selectedItemId === "Pumpkin Pie" || selectedItemId === "Apple Pie") {
        const tempStoredItem = localStorage.getItem("selectedItem");
        if (!tempStoredItem) {
            // Store initially selected item in local storage
            localStorage.setItem("selectedItem", selectedItemId);
            this.optionSelectedMain.emit(true); // Enable "ADD TO CART" for main
        } else {
            // If already stored, emit false to prevent toggling
            this.optionSelectedMain.emit(false);
        }
    } else {
        // For any other item, reset and disable both buttons
        //localStorage.removeItem("selectedItem");
        this.optionSelectedMain.emit(false);
        this.optionSelectedSide.emit(false);
    }
      this.payLoad = JSON.stringify(this.form.getRawValue());
      this.form_value_for_color_selection = this.form.getRawValue();
    } else {
      var keys: any = [];
      keys = Object.keys(this.form.value);
      var obj: any = {};
      keys.map((e) => {
        obj[e] = false;
      });
      this.form.setValue(obj);
      this.payLoad = JSON.stringify(this.form.getRawValue());
      this.form_value_for_color_selection = this.form.getRawValue();
    }
  }
  valueChanged()
  {
    var vm=this;
    this.form.valueChanges
     .subscribe((data:any)=>{
        var t = [];
        for (var propName in data) {
          if (data.hasOwnProperty(propName)) {
            var propValue = data[propName];
            if (propValue == true || propValue == "true") {
              t.push(propName);
            }
          }
        }
        if(vm.selection_count && t.length < vm.selection_count ){
          vm.less_than_required=true;
          vm.warning_message = true;
          vm.disable_add_to_cart.emit(true)
        }
        if (
          (t.length > vm.selection_count) &&
          vm.selection_count
        ) {
          vm.warning_message = true;
          vm.less_than_required=false;
          vm.disable_add_to_cart.emit(true)
        } 
        if (
           (t.length == vm.selection_count) &&
          vm.selection_count
        ) {
          vm.warning_message = false;
          vm.less_than_required=false;
          vm.disable_add_to_cart.emit(false)

        }
        if (
          (t.length==0)  &&
          vm.selection_count
        ) {
          vm.warning_message = false;
          vm.less_than_required=false;
          vm.disable_add_to_cart.emit(true)
        }
        this.form_values.emit(data);
      })
  }
   load(){
    var vm = this;
    this.initialized = true;
    this.questions = [];
    this.options = this.options.map((r) => {
      return {
        ...r,
      };
    });
    this.questions = this.options.map((e) => {
      return {
        key: e.name,
        amount: e.amount,
        label: "check_bopx_selction",
        value: false,
        controlType: e.selectionType == "SINGLE" ? "radio_box" : "check_box",
        // selected:true,
        selected:
          this.options_already_selected.length > 0 &&
          this.options_already_selected.includes(e.id) == true
            ? true
            : this.options_already_selected.length > 0 &&
              this.options_already_selected.includes(e.id) == false
            ? false
            : false,
        id: e.id,
        selectionType: e.selectionType,
        customizationOptionVariations: e.customizationOptionVariations,
        soldout:e.soldOut,
      };
    });
    this.form = this.qcs.toFormGroup(this.questions);
    if (this.selectionType == "SINGLE") {
      this.questions.map(async (t) => {
        if (this.options_already_selected.length == 0) {
          await vm.onProfileChange();
        } else {
          if (t.selected == true) {
            var obj = { value: t.key };
            await vm.onProfileChange(obj);
          }
        }
      });
    }
    this.form_values.emit(this.form.value);
    this.form.valueChanges
     .subscribe((data:any)=>{
        var t = [];
        for (var propName in data) {
          if (data.hasOwnProperty(propName)) {
            var propValue = data[propName];
            if (propValue == true || propValue == "true") {
              t.push(propName);
            }
          }
        }
      //   //test
      //   const optionsToEnable = ["Oven Roasted Turkey Breast", "Smoked Turkey Breast", "Spiral Sliced Ham"];
      //   const shouldEnableButton = t.some(value => optionsToEnable.includes(value));

      // // Emit the button status to parent
      // this.formStatusChanged.emit(shouldEnableButton);

        if(vm.selection_count && t.length < vm.selection_count ){
          vm.less_than_required=true;
          vm.warning_message = true;
          vm.disable_add_to_cart.emit(true)
        }
        if (
          (t.length > vm.selection_count) &&
          vm.selection_count
        ) {
          vm.warning_message = true;
          vm.less_than_required=false;
          vm.disable_add_to_cart.emit(true)
        } 
        if (
           (t.length == vm.selection_count) &&
          vm.selection_count
        ) {
          vm.warning_message = false;
          vm.less_than_required=false;
          vm.disable_add_to_cart.emit(false)

        }
        if (
          (t.length==0)  &&
          vm.selection_count
        ) {
          vm.warning_message = false;
          vm.less_than_required=false;
          vm.disable_add_to_cart.emit(true)
        }
        this.form_values.emit(data);
      })}
  v(id) {
    for (var i = 0; i < this.selected_complete_customisation.length; i++) {
      if (this.selected_complete_customisation[i].catalog_id == id) {
      return (
          this.selected_complete_customisation[i]["variation_name"] +
          "-" +
          this.selected_complete_customisation[i]["amount"]
        );
      }
    }
  }
  onSubmit() {
    this.payLoad = JSON.stringify(this.form.getRawValue());
    this.form_value_for_color_selection = this.form.getRawValue();
  }
  ngOnChanges() {
    this.initialized=true
   this.load()
    var vm = this;
    if (this.initialized) {
      if (this.calculate_warning == true) {
        var data = this.form.value;
        var t = [];
        for (var propName in data) {
          if (data.hasOwnProperty(propName)) {
            var propValue = data[propName];
            if (propValue == true || propValue == "true") {
              t.push(propName);
            }
          }
        }
        return;
      }
      this.form = this.qcs.toFormGroup(this.questions);
      setTimeout(() => {
        this.payLoad = JSON.stringify(this.form.getRawValue());
        this.form_value_for_color_selection = this.form.getRawValue();
        this.form_name = this.qcs.toFormGroup(this.questions);
      }, 300);
      this.initialized=false;
    }
  }
  radioDefaultSelected(val) {
    if (val) {
      var keys: any = [];
      keys = Object.keys(this.form.value);
      var obj: any = {};
      keys.map((e) => {
        if (e == val) {
          obj[e] = "true";
        } else {
          obj[e] = false;
        }
      });
      this.form.setValue(obj);
      this.payLoad = JSON.stringify(this.form.getRawValue());
      this.form_value_for_color_selection = this.form.getRawValue();
    }
    return true;
  }
  multipleSelect(ev) {
    this.customise_variation_name.emit({
      value_inside_selection: ev.value_inside_selection,
      id_value: ev.id_value,
    });
  }
  async selectDefaultValue() {
    for (var i = 0; i < this.options.length; i++) {
      if (this.options[i]["selectionType"] == "SINGLE") {
        if (
          this.options[i] &&
          this.options[i].customizationOptionVariations &&
          this.options[i].customizationOptionVariations.length > 0
        ) {
          var val =
            this.options[i].customizationOptionVariations[0].name +
            -+this.options[0].customizationOptionVariations[0].amount;
          this.customise_variation_name.emit({
            index_value: i,
            value_inside_selection: val.split("-"),
            id_value: this.options[i].id,
          });
        }
      }
    }
  }
  dropDownValueSelected(event, index, question) {
    this.customise_variation_name.emit({
      index_value: index,
      value_inside_selection: event.target.value.split("-"),
      id_value: question.id,
    });
  }
  ngAfterViewInit() {
    for (var i = 0; i < this.selected_complete_customisation.length; i++) {
      for (var j = 0; j < this.questions.length; j++) {
        if (
          this.selected_complete_customisation[i].catalog_id ==
          this.questions[j].id
        ) {
          if (this.selected_complete_customisation[i]["variation_name"]) {
            this.customise_variation_name.emit({
              value_inside_selection: [
                this.selected_complete_customisation[i]["variation_name"],
                this.selected_complete_customisation[i]["amount"],
              ],
              id_value: this.questions[j].id,
            });
          }
      return (
            this.selected_complete_customisation[i]["variation_name"] +
            "-" +
            this.selected_complete_customisation[i]["amount"]
          );
        }
      }
    }
    this.warning_message = true;
    this.less_than_required=true;
    if (this.selectionType == "SINGLE") {
    
    } else {

    }
    this.selectDefaultValue();
    this.load()
  }
}
